<template>
    <div>
        <!-- <Spin size="large" fix v-if="spinShow"></Spin> -->
        <!-- <div style="background:#eee; padding: 0px">
            <Card :bordered="false" :padding="0" :dis-hover="true">
                <Row style="margin:0px 0px 20px 0px">
                    <Col span="8" offset="8" class-name="match-assist-text">
                        <span class="text-assist">赛事名称和比赛时间</span>
                    </Col>
                </Row>
                <Row type='flex' align="middle" justify='center'>
                    <Col span="8" >
                        <span >
                            <Row type='flex' justify='center'>
                                <img class="team-logo" :src="home_logo" />
                            </Row>
                            <Row type='flex' justify='center'>
                                <span class="title-sub">{{data.home.name}}</span>
                            </Row>
                        </span>
                    </Col>
                    <Col span="2" class="title-main" style="text-align: center;">
                        <span>31</span>
                    </Col>
                    <Col span="4">
                        <Row type='flex' justify='center'>
                            <span class="text-assist" style="color:#F5222D">状态名称</span>
                        </Row>
                    </Col>
                    <Col span="2" class="title-main" style="text-align: center;">
                        <span>10</span>
                    </Col>
                    <Col span="8">
                        <Row type='flex'  justify='center'>
                            <img class="team-logo" :src="away_logo" />
                        </Row>
                        <Row type='flex'  justify='center'>
                            <span class="title-sub">{{data.away.name}}</span>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </div> -->
        <Tabs value="1">
            <TabPane label="比分" name="1">
                <detailScore ref="Score" :sport_id="sport_id" :match_id="match_id" @onChange="setChangeLog"></detailScore>
            </TabPane>
            <TabPane label="统计" name="2">
                <detailStat ref="Stat" :sport_id="sport_id" :match_id="match_id" @onChange="setChangeLog"></detailStat>
            </TabPane>
            <TabPane label="事件" name="3">
                <detailEvent ref="Event" :sport_id="sport_id" :match_id="match_id" @onChange="setChangeLog"></detailEvent>
            </TabPane>
            <TabPane label="阵容" name="4">
                <detailLineup  ref="Lineup" :sport_id="sport_id" :match_id="match_id" @onChange="setChangeLog"></detailLineup>
            </TabPane>
        </Tabs>
        <!-- <Collapse :value="['1', ]" >
            <Panel name="1" class="collapse-panel">
                <span class="title-sub-white">比分</span>
                <p slot="content">
                    <detailScore ref="Score" :sport_id="sport_id" :match_id="match_id" @onChange="setChangeLog" ></detailScore>
                </p>
            </Panel>
            <Panel name="2" class="collapse-panel" v-if="[3, 8, 10, 11, 24].indexOf(sport_id) > -1">
                <span class="title-sub-white">统计</span>
                <p slot="content">
                    <detailStat ref="Stat" :sport_id="sport_id" :match_id="match_id" @onChange="setChangeLog" ></detailStat>
                </p>
            </Panel>
            <Panel name="3" class="collapse-panel">
                <span class="title-sub-white">事件</span>
                <p slot="content">
                    <detailEvent ref="Event" :sport_id="sport_id" :match_id="match_id" @onChange="setChangeLog" ></detailEvent>
                </p>
            </Panel>
            <Panel name="4" class="collapse-panel">
                <span class="title-sub-white">阵容</span>
                <p slot="content">
                    <detailLineup ref="Lineup" :sport_id="sport_id" :match_id="match_id" @onChange="setChangeLog" ></detailLineup>
                </p>
            </Panel>
        </Collapse> -->
    </div>
</template>
<script>

// import { getMatch } from '@/api/ballsports/base/match';
// import moment from 'moment';
import detailScore from './detail_score.vue';
import detailStat from './detail_stat.vue';
import detailEvent from './detail_event.vue';
import detailLineup from './detail_lineup.vue';
import Client from '@/utils/mqtt/mqtt-pro-new';

export default {
    name: "match-relation",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
        detailScore,
        detailStat,
        detailEvent,
        detailLineup,
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            data: {
                home: {},
                away: {},
            },
            mqtt_client: false,  // 长连接客户端
            mqtt_topic : '',  // 长连接主题
            changed: false,     // 数据是否有变化
        }
    },
    methods: {
        // 获取比赛的基础数据
        getData () {
            let self = this;
            // this.spinShow = true;
            // // 搜索日期
            // var params = {
            //     sport_id: this.sport_id,
            //     match_id: this.match_id,
            // }
            // getMatch( self.sport_id, params).then(response => {
            //     // console.log(response)
            //     if (response.data.code == 0) {
            //         if (response.data.data.list && response.data.data.list.length > 0){
            //             this.data = response.data.data.list[0];
            //         }else{
            //             this.data = {}
            //         }
            //         // self.r_matches = response.data.data.matches;
            //         // self.r_odds = response.data.data.odds;
            //         // self.r_matches.forEach(element => {
            //         //     element["match_time_str"] = moment.unix(element.match_time).format('YYYY-MM-DD HH:mm');
            //         // });
            //         // self.r_odds.forEach(element => {
            //         //     element["match_time_str"] = moment.unix(element.match_time).format('YYYY-MM-DD HH:mm');
            //         // });
            //         self.spinShow = false
            //     } else {
            //         self.$Message.error(response.data.msg)
            //     }
            // })
        },
        // end 
        // 创建长连接的方法
        initMqttLive(){
            let self = this;
            let topic = "namicms/ballsports/match/" + self.sport_id + "_" + self.match_id ;
            // console.log(" TOPIC: ",self.match_id, topic, self.topic)
            if ( self.mqtt_client ){
                if (self.topic){
                    self.mqtt_client.unsubscribe(self.topic);
                }
                self.mqtt_client.subscribe(topic);
                self.topic = topic;
            }else{
                self.topic = topic;
                self.mqtt_client = new Client({
                    topic: `${topic}`,
                    message (t, msg) {
                        // console.log(msg);
                        // console.log("demo: ", self.$refs);
                        if (msg.type ===1 ){
                            let msgData = msg.data;
                            // console.log(1, msgData);
                            // 比分
                            if (self.$refs.Score)
                                self.$refs.Score.feed( msgData );
                        }else if (msg.type===2){
                            let msgData = msg.data;
                            // console.log(2, msgData);
                            // 统计
                            if (self.$refs.Stat)
                                self.$refs.Stat.feed( msgData );
                        }else if (msg.type === 3){
                            // 事件
                            let msgData = msg.data;
                            // console.log(3, msgData);
                            if (self.$refs.Event)
                                self.$refs.Event.feed( msgData );
                        }else if (msg.type === 4){
                            // 阵容
                            let msgData = msg.data;
                            // console.log(4, msgData);
                            if (self.$refs.Lineup)
                                self.$refs.Lineup.feed( msgData );
                        };
                    },
                    connect(){
                        // 建立连接成功之后执行
                        // console.log("MQTT CONNECTION OK: ", self.topic)
                    },
                })
            }
        },
        // end 
        setChangeLog( sub_type ){
            // alert(sub_type);
            this.changed = true;
        },
        // end 
    },
    mounted () {
    //     window.addEventListener('beforeunload', e => {
    //         alert(1);
    //     });
    },
    watch: {
        // match_id(value){
        //     alert(1);
        //     let self = this;
        //     if (value){
        //         // console.log("Detail Get Data", value);
        //         self.getData();
        //         // 建立长连接和主题
        //         self.initMqttLive();
        //     };
        // },
        'match_id': {
            handler(value){
                let self = this;
                if (value){
                    // console.log("Detail Get Data", value);
                    self.getData();
                    // 建立长连接和主题
                    self.initMqttLive();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
        // home_logo: {
        //     get: function(){
        //         return this.data.home.logo ? this.$conf.ballsports.img_prefix + '/v2/team/' + this.data.home.logo: ''
        //     },
        //     set: function(){
        //     }
        // },
        // away_logo:{
        //     get: function(){
        //         return this.data.away.logo ? this.$conf.ballsports.img_prefix + '/v2/team/' + this.data.away.logo: ''
        //     },
        //     set: function(){
        //     }
        // },
    }
}
</script>

<style scoped>
    @import "detail.css"
</style>