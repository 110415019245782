import http from '@/utils/http';

export const getMatchSingleList = (parameter) => {
  return http.request({
    url: '/v1/games/match_single/list',
    method: 'get',
    params: parameter
  })
}

export const editMatchSingle = (parameter) => {
  return http.request({
    url: '/v1/games/match_single/edit',
    method: 'post',
    data: parameter
  })
}

export const delActualData = (parameter) => {
    return http.request({
        url: '/v1/games/match_single/del_actual',
        method: 'post',
        data: parameter
    })
  }