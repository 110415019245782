import { render, staticRenderFns } from "./player_stat.vue?vue&type=template&id=6583885c&scoped=true&"
import script from "./player_stat.vue?vue&type=script&lang=js&"
export * from "./player_stat.vue?vue&type=script&lang=js&"
import style0 from "./player_stat.vue?vue&type=style&index=0&id=6583885c&scoped=true&lang=css&"
import style1 from "./player_stat.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6583885c",
  null
  
)

export default component.exports