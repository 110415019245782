
<template>
  <div style="width:100%">

      <Modal v-model="model_replace" title="替换文本" @on-ok="apiReplaceText">
          <Form  :label-width="100" style="margin-top:1px;" @submit.native.prevent>
            <FormItem label="查找内容">
                <Input type='text'  v-model="replaced_log.raw_zh"></Input>
            </FormItem>
            <FormItem label="替换(中)" >
                <Input type='text' v-model="replaced_log.zh"></Input>
            </FormItem>
            <FormItem label="替换(英)" >
                <Input type='text' v-model="replaced_log.en"></Input>
            </FormItem>
          </Form>
      </Modal>

      <!-- <Button type="info" @click="modal_add=true" long>新增情报</Button> -->

      <Modal v-model="model_add_tab" title="新增小节" @on-ok="apiAddLogTab"
      >
        <Form  :label-width="100" style="margin-top:1px;" @submit.native.prevent>
          <FormItem label="说明:">
              <span >5-加时1;  6-加时2;   7-加时3; ... 依此递增</span>
          </FormItem>
          <FormItem label="小节id:">
              <Input type='text'  v-model="new_tab_box_num"></Input>
          </FormItem>
          <FormItem label="名称:" >
              <span >{{new_tab_label}}</span>
          </FormItem>
        </Form>
      </Modal>

      <Row :gutter="10" style="margin:3px 0 3px 0;text-align: center;" >
          <Col span="8"  >
              <template >
                  <div style="background:#eee; padding: 5px">
                      <Card :bordered="false">
                          <Row style="margin:0px 0px 20px 0px">
                              <Col span='4' >
                                  <span class='text-main'> {{data.competition.short_name_zh}} </span>
                              </Col>

                              <Col span='9'  push="11">
                                  <span class='text-main'> {{match_time}} </span>
                              </Col>
                          </Row>
                          <Row>
                            <Col span=6 >
                                <span @click="teamClickd(1)">
                                    <Row justify='center'>
                                      <Avatar shape="square" icon="ios-person" :src="data.home.logo"/>
                                    </Row>
                                    <Row justify='center'>
                                      <span  class="text-main">{{data.home.short_name_zh}}</span>
                                    </Row>
                                </span>
                            </Col>
                            <Col span=2 style="align:center">
                                <span class="title-main">{{data.home_score}}</span>
                            </Col>
                            <Col span=8>
                                <Row justify='center'><span class="text-main">{{data.status_name}}</span></Row>
                                <Row justify='center'><CountDown v-model="data"></CountDown></Row>
                                <Row justify='center'><span class='text-assist'>ID:{{data.id}}</span></Row>
                            </Col>
                            <Col span=2>
                                <span class="title-main">{{data.away_score}}</span>
                            </Col>
                            <Col span=6>
                              <span @click="teamClickd(2)">
                                <Row justify='center'>
                                  <Avatar shape="square" icon="ios-person" :src="data.away.logo"/>
                                </Row>
                                <Row justify='center'>
                                  <span class="text-main">{{data.away.short_name_zh}}</span>
                                </Row>
                              </span>
                            </Col>
                          </Row>
                      </Card>
                  </div>
                  <div >
                    <Row>
                        <Col span="12" >
                            <Table  :row-class-name="rowPlayerClassName" :columns="home_columns" @on-row-click="playerRowClicked" :data="data.home_players" height='1000'></Table>
                        </Col>
                        <Col span="12" >
                            <Table  :row-class-name="rowPlayerClassName" :columns="away_columns" @on-row-click="playerRowClicked" :data="data.away_players" height='1000'></Table>
                        </Col>
                    </Row>
                  </div>
              </template>
          </Col>

          <Col span="16" >
            <Card :bordered="false"  >
                <p slot="title">
                    <span class='title-main'>键位提醒</span>
                </p>
                <div href="#" slot="extra" >
                    <Select v-model="source_id" style="width:150px" placeholder="默认源" @on-change	="changeSourceId">
                        <Option v-for="item in sources" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                    <RadioGroup v-model="data.textlive_status" @on-change="changeStatus" v-if="data.competition.id!=3">
                        <Radio label='1'>手动</Radio>
                        <Radio label='0'>自动</Radio>
                    </RadioGroup>
                    <span v-else>
                        <span v-if="data.textlive_status==='1'">手动</span>
                        <span v-else-if="data.textlive_status==='0'">自动</span>
                    </span>
                </div>
                <!-- <Button type="primary" @click="add_a_word_to_log($event)" data-shortkey="a0" >跳跃</Button> -->

                <Row type="flex" style="text-align: left; " >
                    <Col v-for="col in 7" style="float:left; width: auto; margin-right:auto;" :key="col" >
                        <Row>
                          <ButtonGroup vertical >
                              <Button  size="small" v-for="(item, index) in short_keys" :key="item.s" v-if=" (!item.parent) && ((item.order % 7===col) || (item.order%7===0 && col===7))"
                                @click="add_a_word_to_log($event)"
                                :data-shortkey="item.s"  style="margin-top: 3px;"
                              >
                                <span class='text-main'>{{item.s}} {{ item.zh || item.desc }}</span>
                              </Button>
                          </ButtonGroup>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <Collapse v-model="panel_add_log" accordion class='bg-green-500 '>
                <Panel name="1">
                    <span class='text-white'>
                      <span v-if="new_log.source==0 ">新增</span>
                      <span v-else-if=" new_log.source==1 ">并入</span>
                      文字直播/第{{box_num}}节
                    </span>
                    <div slot="content"  class="space-y-1 ">
                        <Row class="flex flex-row gap-x-1  ">
                            <Col span=2  class="text-main ">时间:</Col>
                            <Col >
                              <Duration  v-model="new_log.time_left" ></Duration>
                            </Col>
                            <Col class="text-main">比分:</Col>
                            <Col span=4 class='flex flex-nowrap bg-gray-100'>
                                <Input v-model="new_log.home_score" />
                                <span v-if="new_log.team===1 && new_log.log_score>0" class="log_score">+{{new_log.log_score}}</span>
                                <Input v-model="new_log.away_score" />
                                <span v-if="new_log.team===2 && new_log.log_score>0" class="log_score">+{{new_log.log_score}}</span>
                            </Col>
                            <Col class="text-main">战队:</Col>
                            <Col >
                                  <Select v-model="new_log.team" >
                                      <Option v-for="item in teamList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                  </Select>
                            </Col>
                            <Col class='flex flex-nowrap '>
                                <Button type="success" @click="apiAddLog()" icon="md-return-left"></Button>
                                <Button type="warning" @click="cleanLogData()" icon="md-refresh"></Button>
                            </Col>
                        </Row>
                        <Row class='flex flex-row flex-nowrap gap-x-1 justify-start'>
                              <Col span=2 class="text-main ">快捷键:</Col>
                              <Input type='text' v-model="log"  ref="inputBox"  @keyup.native="keyEvent" clearable @on-blur="recordMouseIndex"></Input>
                        </Row>
                        <Row class='flex flex-row flex-nowrap gap-x-1 justify-start'>
                              <Col span=2 class="text-main">输出(中):</Col>
                              <Input v-model="log_desc_zh" type='text'  />
                        </Row>
                        <Row class='flex flex-row flex-nowrap gap-x-1 justify-start'>
                              <Col span=2 class="text-main">输出(英):</Col>
                              <Input v-model="log_desc_en" type='text' />
                        </Row>
                        <Spin size="large" fix v-if="spinShow"></Spin>
                    </div>
                </Panel>
            </Collapse>
            <Tabs v-model="box_num" type="card" closable @on-tab-remove="handleLogTabRemove" :before-remove="beforeLogTabRemove">
              <TabPane v-for="(item, index) in tab_box_nums" :key="item.name" :label="item.label" :name="item.name" >
                  <Row>
                      <Col span="16"  >
                          <Row>
                              <Button  @click="showAddModel()">新增</Button>
                              <Button type="info"  @click="model_replace=true">替换</Button>
                              <Poptip
                                  confirm
                                  title="你确定要删除本页选中的数据?"
                                  @on-ok="deleteLogs"
                                  transfer
                                  >
                                  <Button type='error'>删除</Button>
                              </Poptip>
                              <Button @click="openDemoPage">赛中模拟</Button>
                              <Button @click="openLinePage">赛后编辑</Button>
                              <Poptip
                                  confirm
                                  title="确定使用源数据替换当前局?"
                                  @on-ok="transferSource"
                                  transfer
                                  >
                                  <Button type='info'>源数据同步</Button>
                              </Poptip>
                          </Row>
                          <Table
                            @on-selection-change="apiSelectChange"
                            :row-class-name="rowClassName"
                            border ref="selection"
                            :columns="columns_log"
                            :data="text_lives[item.name]"
                            :draggable="true"
                            @on-drag-drop="dragLogs"
                            context-menu
                            show-context-menu
                            @on-contextmenu="handleContextMenu"
                            height='600'
                            >
                              <template slot="contextMenu">
                                  <DropdownItem @click.native="handleContextMenuInsertUp">插入到本条上行</DropdownItem>
                                  <DropdownItem @click.native="handleContextMenuInsertDown" style="color: #ed4014">插入到本条下行</DropdownItem>
                              </template>
                          </Table>
                      </Col>
                      <Col span="8" >
                          <span class="font-bold">源数据:</span>
                          <Scroll height='600' style="background:#eee;">
                            <Card v-for="(item, index) in nami_text_lives[item.name]" :key="index" :bordered="true" >
                                <Row type='flex' justify='start' style='font-size:10px;'>
                                  <Col span='16' style="text-align: left;">
                                    <span class='text-main' v-if="item.box_num<5">第{{item.box_num}}节</span>
                                    <span class='text-main' v-else-if="item.box_num==5">加时</span>
                                    <span class='text-main' v-else> {{item.box_num}} </span>
                                    <span class='text-main' >/ {{item.time_left_str}}</span>
                                    <span class='text-main' >/ {{item.home_score}}-{{item.away_score}}</span>
                                  </Col>
                                  <Col span='8' style="text-align: right;">
                                    <Button type="info" size="small" @click="bingTextLive(item)" >并入</Button>
                                  </Col>
                                </Row>
                                <Row  > <span class='text-main bg-green-50 text-left'>{{item.desc_zh}}</span></Row>
                            </Card>
                          </Scroll>
                      </Col>
                  </Row>
              </TabPane>
              <Button @click="handleLogTabsAdd" slot="extra"><span class='text-main'>增加小节</span></Button>
            </Tabs>
          </Col>
      </Row>
  </div>

</template>

<script>

import { MatchDetail,AddTextlive,GetTextlive, TransferTextlive,
    UpdateTextlive, DeleteTextlive, DragTextlive, ReplaceTextlive,
  GetTabsList, DeleteLogTab, MatchSources, updateMatchSource} from '@/api/sports/match';
import moment from 'moment';
import CountDown from '@/components/basic/count_down.vue';
import Duration from '@/components/basic/duration.vue';
import Client from '@/utils/mqtt/mqtt-pro-new';

export default {
  data () {
    let self = (window.x = this)
    return {
      sources: [
        // {
        //   "id": 73,
        //   "name": '(默认)源73',
        // }
      ],   // 源列表
      source_id: 0,  // 使用的文字直播源id
      spinShow: false,
      model_add_tab: false,
      new_tab_box_num : 5,
      new_tab_label: '加时1',
      mouseStartPos: null,
      teamList: [
          {
              value: 0,
              label: '中立'
          },
          {
              value: 1,
              label: '主队'
          },
          {
              value: 2,
              label: '客队'
          },
      ],
      new_log: {
          "key": "",
          "time_left": 0,
          "home_score": 0,
          "away_score": 0,
          "source": 0, // 0新增 1并入
          "team": 0,  // 0中立 1主队 2客队
          "home_log_score": 0,
          "away_log_score": 0,
          log_score: 0,
      },
      log: "",
      log_desc_zh:'',
      log_desc_en:'',
      log_map: {},
      tab_box_nums :[{
        "name": "1",
        "label": "第1节",
        show: true,
      },{
        "name": "2",
        "label": "第2节",
        show: true,
      },{
        "name": "3",
        "label": "第3节",
        show: true,
      },{
        "name": "4",
        "label": "第4节",
        show: true,
      },],

      short_keys: [], // 键盘快捷键 - 每场比赛重新生成
      box_num: '1',
      model_replace: false,
      model_add_del:false,
      panel_add_log: '1',
      sport_id: 0,
      match_id: 0,
      home_columns:[
          {
              title: 'Q',
              width:'65px',
              key: 'shirt_number',
          },
          {
              title: "-",
              width:'65px',
              key: 'shou_ti',
              render: function (h, params) {
                let currentRow = params.row;
                var is_shou = currentRow.is_shou;
                var is_shou_text = is_shou===0?'首':(is_shou===1?'补': ""  );
                var class_name = is_shou===0?'player_shou':(is_shou===1?'player_bu': "" );
                return h(
                  'div', [
                    h('Badge', {
                      attrs: {
                        text: is_shou_text,
                        // color: is_shou_color,
                        'class-name': class_name,
                      },
                    },),
                  ]
                )
              }
          },
          {
              title: "主队",
              key: 'short_name_zh',
              render: function (h, params) {
                let currentRow = params.row;
                return h(
                  'div', [
                    // h('Avatar', { attrs: { src: currentRow.logo, shape: 'square', size: 'big' } }),
                    h('span', {
                      attrs: {
                        class: 'player_name'
                      },
                    },currentRow.short_name_zh),
                  ]
                )
              }
          },
      ],
      away_columns:[
          {
              title: "客队",
              key: 'short_name_zh',
              render: function (h, params) {
                let currentRow = params.row;
                // var is_shou_color =  is_shou_text ==='替补'?"#2db7f5": "red";
                return h(
                  'div', [
                    // h('Avatar', { attrs: { src: currentRow.logo, shape: 'square', size: 'big' } }),
                    h('span', {
                      attrs: {
                        class: 'player_name'
                      }, }, currentRow.short_name_zh),

                  ]
                )
              }
          },
          {
              title: "-",
              width:'65px',
              key: 'shou_ti',
              render: function (h, params) {
                let currentRow = params.row;
                var is_shou = currentRow.is_shou;
                var is_shou_text = is_shou===0?'首':(is_shou===1?'补': ""  );
                var class_name = is_shou===0?'player_shou':(is_shou===1?'player_bu': "" );
                return h(
                  'div', [
                    h('Badge', {
                      attrs: {
                        text: is_shou_text,
                        'class-name': class_name,
                      },
                    },),
                  ]
                )
              }
          },
          {
              title: 'W',
              width:'65px',
              key: 'shirt_number',
          },

      ],
      home: '',
      away: '',
      loading: true,
      data: {
        home: {
          short_name_zh: '',
        },
        away: {
          short_name_zh: '',
        },
        away_players: [],
        home_players: [],
        short_keys:{},
        textlive_status: "0",
        bing_translates: [],
        competition: {
          short_name_zh: '',
        },
      },
      short_keys_s: [],

      // 文字直播
      text_lives: {
        "1": [],"2": [],"3": [],"4": [],"5": [],
      },
      nami_text_lives: {
        "1": [],"2": [],"3": [],"4": [],"5": [],
      },
      columns_log: [
              {
                  type: 'selection',
                  width: 60,
                  align: 'center'
              },
              {
                  title: '时间与比分',
                  width: 180,
                  key: 'score_infos',
                  render: function (h, params) {
                      let currentRow = params.row
                      var desc_zh = currentRow.desc_zh
                      var desc_en = currentRow.desc_en
                      return  [
                            h('Input', {
                                  props: {
                                      type: 'text',
                                      value: currentRow.time_left_str,
                                  },
                                  on: {
                                      'on-blur': (event) => {
                                          currentRow.time_left_str = event.target.value;
                                          var params = {
                                              sport_id: self.sport_id,
                                              match_id: self.match_id,
                                              key: currentRow.key,
                                              params: {time_left_str: currentRow.time_left_str},
                                          }
                                          UpdateTextlive(params).then(response => {
                                            if (response.data.code == 0) {
                                              // 重新获取文字直播
                                              if (response.data.data.box_num){
                                                self.apiGetLog(0, response.data.data.box_num);
                                              }
                                              self.$Message.success('更新成功');
                                            } else {
                                              self.$Message.error(response.data.msg)
                                            }
                                          })

                                      },
                                  },
                            }),
                            // 比分
                            h('Input', {
                                    style:{
                                        'width':'48%',
                                    },
                                    props: {
                                        type: 'text',
                                        value: currentRow.home_score,
                                    },
                                    on: {
                                        'on-blur': (event) => {
                                            currentRow.home_score = event.target.value;
                                            var params = {
                                              sport_id: self.sport_id,
                                              match_id: self.match_id,
                                              key: currentRow.key,
                                              params: {home_score: currentRow.home_score},
                                            }
                                            UpdateTextlive(params).then(response => {
                                              if (response.data.code == 0) {
                                                // 重新获取文字直播
                                                if (response.data.data.box_num){
                                                  self.apiGetLog(0, response.data.data.box_num);
                                                }
                                                self.$Message.success('更新成功');
                                              } else {
                                                self.$Message.error(response.data.msg)
                                              }
                                            })
                                        },
                                    },
                              }),
                              h('span', {
                                  style:{
                                      // 'width':'10%',
                                  },
                                }, ':'),
                              h('Input', {
                                      style:{
                                          'width':'48%',
                                      },
                                      props: {
                                          type: 'text',
                                          value: currentRow.away_score,
                                      },
                                      on: {
                                          'on-blur': (event) => {
                                              currentRow.away_score = event.target.value;
                                              var params = {
                                                sport_id: self.sport_id,
                                                match_id: self.match_id,
                                                key: currentRow.key,
                                                params: {away_score: currentRow.away_score},
                                              }
                                              UpdateTextlive(params).then(response => {
                                                if (response.data.code == 0) {
                                                  // 重新获取文字直播
                                                  if (response.data.data.box_num){
                                                    self.apiGetLog(0, response.data.data.box_num);
                                                  }
                                                  self.$Message.success('更新成功');
                                                } else {
                                                  self.$Message.error(response.data.msg)
                                                }
                                              })
                                          },
                                      },
                                }),
                        ]
                  }
              },
              {
                  title: '描述',
                  key: 'desc_zh',
                  render: function (h, params) {
                      let currentRow = params.row
                      return [
                          h('Input', {
                                  props: {
                                      type: 'text',
                                      value: currentRow.desc_zh,
                                  },
                                  on: {
                                      'on-blur': (event) => {
                                          currentRow.desc_zh = event.target.value;
                                          var params = {
                                            sport_id: self.sport_id,
                                            match_id: self.match_id,
                                            key: currentRow.key,
                                            params: {desc_zh: currentRow.desc_zh},
                                          }
                                          UpdateTextlive(params).then(response => {
                                            if (response.data.code == 0) {
                                              // 重新获取文字直播
                                              if (response.data.data.box_num){
                                                self.apiGetLog(0, response.data.data.box_num);
                                              }
                                              self.$Message.success('更新成功');
                                            } else {
                                              self.$Message.error(response.data.msg)
                                            }
                                          })
                                      },
                                  },
                            }),
                            h('Input', {
                                    props: {
                                        type: 'text',
                                        value: currentRow.desc_en,
                                    },
                                    on: {
                                        'on-blur': (event) => {
                                            currentRow.desc_en = event.target.value;
                                            var params = {
                                              sport_id: self.sport_id,
                                              match_id: self.match_id,
                                              key: currentRow.key,
                                              params: {desc_en: currentRow.desc_en},
                                            }
                                            UpdateTextlive(params).then(response => {
                                              if (response.data.code == 0) {
                                                // 重新获取文字直播
                                                if (response.data.data.box_num){
                                                  self.apiGetLog(0, response.data.data.box_num);
                                                }
                                                self.$Message.success('更新成功');
                                              } else {
                                                self.$Message.error(response.data.msg)
                                              }
                                            })

                                        },
                                    },
                              }),
                        ]
                  }
              },
              {
                  title: '操作',
                  width: 85,
                  render: function (h, params) {
                      let currentRow = params.row
                      return  [
                          h('Button', {
                                  props: {
                                      type: 'primary',
                                      size: 'small'
                                  },
                                  on: {
                                      click: () => {
                                          self.new_log.key = currentRow.key;
                                          self.new_log.home_score = currentRow.home_score;
                                          self.new_log.away_score = currentRow.away_score;
                                          self.new_log.time_left = currentRow.time_left;
                                          self.new_log.team = currentRow.team;
                                          if (currentRow.raw){
                                            self.new_log.raw = currentRow.raw
                                          }
                                          // console.log(self.new_log.raw)
                                          self.log = currentRow.log;
                                          self.log_desc_zh = currentRow.desc_zh;
                                          self.log_desc_en = currentRow.desc_en;
                                          self.panel_add_log = '1';
                                      },
                                  },
                            }, "编辑"),
                        ]
                  }
              },
      ],
      choosed_logs: {},  // 选中的文字直播
      replaced_log:{  // 替换文本
        raw_zh: '',
        raw_en: '',
        zh:'',
        en: '',
      },
      get_data_count:0,
      short_keys_chain: {},
      chinese_names_chain: {},

    }
  },
  components: {
    CountDown,
    Duration,
  },
  methods: {
    addPlayersToShortkeys(){
        let self  = this;
        let home_players = self.data.home_players;
        let away_players = self.data.away_players;
        for (var i = 0; i<home_players.length; i++){
            var player = home_players[i];
            var shirt_number = player.shirt_number;
            var s = 'q'+shirt_number
            var _key = {
              s: s,
              zh: player.short_name_zh,
              en: " "+ player.short_name_en+" ",
              type:1,
              team: player.team,
            }

            if (self.short_keys_s.indexOf( s ) == -1){
              self.short_keys.push(_key);
              self.short_keys_s.push(s);
            }
            // 选手id对应的名称
            var s2 = player.s;
            var _key2 = {
              s: player.s,
              zh: player.short_name_zh,
              en: " "+ player.short_name_en+" ",
              type:2,
              team: player.team,
            }
            if (self.short_keys_s.indexOf( s2 ) == -1){
              self.short_keys.push(_key2);
              self.short_keys_s.push(s2);
            }
        }
        for (var i = 0; i<away_players.length; i++){
            var player = away_players[i];
            var shirt_number = player.shirt_number;
            var s = 'w'+shirt_number;
            var _key = {
              s: s,
              zh: player.short_name_zh,
              en: " "+ player.short_name_en+" ",
              type:1,
              team: player.team,
            }
            if (self.short_keys_s.indexOf( s ) == -1){
              self.short_keys.push(_key);
              self.short_keys_s.push(s);
            }
            // 选手id对应的名称
            var s2 = player.s;
            var _key2 = {
              s: s2,
              zh: player.short_name_zh,
              en: " "+ player.short_name_en+" ",
              type:2,
              team: player.team,
            }
            if (self.short_keys_s.indexOf( s2 ) == -1){
              self.short_keys.push(_key2);
              self.short_keys_s.push(s2);
            }
        }
        // 排序 根据快捷键位的长度排序
        // self.short_keys.sort((a,b) =>
        //   b.s.length - a.s.length
        // )
        // dfa词
        self.dfaCreateShortkeysChain();
        self.dfaCreateChineseNamesChain();
    },
    // end
    dfaCreateShortkeysChain(){
        let self = this;
        for (var i=0; i< self.short_keys.length; i++){
            var item = self.short_keys[i];
            let _key = item.s;
            var _map = self.short_keys_chain;
            if (_key){
              for (var j=0; j<_key.length; j++ ){
                  let chr = _key[j];
                  if (! _map[chr] ){
                      if (j == _key.length-1){
                        _map[chr] =  {"\x00": item };
                      }else{
                        _map[chr] = {};
                      }
                      _map = _map[chr];
                  }else{
                      if (j == _key.length-1){
                        _map[chr]["\x00"] = item ;
                      }
                      _map = _map[chr];
                  }
              }
            }
        }
    },

    //
    add_a_word_to_chain(chain, _key, item){
      var _map = chain;
      if (_key && _key.length > 0){
        for (var j=0; j<_key.length; j++ ){
            let chr = _key[j];
            if (! _map[chr] ){
                if (j == _key.length-1){
                  _map[chr] =  {"\x00": item };
                }else{
                  _map[chr] = {};
                }
                _map = _map[chr];
            }else{
                if (j == _key.length-1){
                  _map[chr]["\x00"] = item ;
                }
                _map = _map[chr];
            }
        }
      }
    },

    // end
    dfaCreateChineseNamesChain(){
        let self = this;
        let home_team = self.data.home;
        let away_team = self.data.away;
        let home_players = self.data.home_players;
        let away_players = self.data.away_players;

        let home_team_data = {
            name_zh: home_team.name_zh,
            name_en: " "+home_team.name_en+" ",
            short_name_en: " "+home_team.short_name_en+" ",
            short_name_zh: home_team.short_name_zh,
        }
        self.add_a_word_to_chain(self.chinese_names_chain, home_team.name_zh, home_team_data  );
        self.add_a_word_to_chain(self.chinese_names_chain, home_team.short_name_zh, home_team_data  );
        let away_team_data = {
            name_zh: away_team.name_zh,
            name_en: " "+away_team.name_en+" ",
            short_name_en: " "+away_team.short_name_en+" ",
            short_name_zh: away_team.short_name_zh,
        }
        self.add_a_word_to_chain(self.chinese_names_chain, away_team.name_zh, away_team_data  );
        self.add_a_word_to_chain(self.chinese_names_chain, away_team.short_name_zh, away_team_data  );
        for (var i=0; i< home_players.length; i++){
            let player = home_players[i];
            var player_data = {
                name_zh: player.name_zh,
                name_en: " "+player.name_en+" ",
                short_name_en: " "+player.short_name_en+" ",
                short_name_zh: player.short_name_zh,
            }
            self.add_a_word_to_chain(self.chinese_names_chain, player.name_zh, player_data  );
            self.add_a_word_to_chain(self.chinese_names_chain, player.short_name_zh, player_data  );
        }
        for (var i=0; i< away_players.length; i++){
            let player = away_players[i];
            var player_data = {
                name_zh: player.name_zh,
                name_en: " "+player.name_en+" ",
                short_name_en: " "+player.short_name_en+" ",
                short_name_zh: player.short_name_zh,
            }
            self.add_a_word_to_chain(self.chinese_names_chain, player.name_zh, player_data  );
            self.add_a_word_to_chain(self.chinese_names_chain, player.short_name_zh, player_data  );
        }
        // 快捷键
        for (var i=0; i< self.short_keys.length; i++){
            var item = self.short_keys[i];
            // let _key = item.s;
            var zh = item.zh;
            var en = item.en;
            var data = {
              name_zh: zh,
              name_en: en,
              short_name_en: en,
              short_name_zh: zh,
            }
            self.add_a_word_to_chain(self.chinese_names_chain, zh, data );
        }

        // 将接口返回的数据 额外新增到dfa上
        if (self.data.bing_translates){
            for (var _index in self.data.bing_translates){
                // console.log(self.data.bing_translates[_index]);
                var data = {
                  name_zh: self.data.bing_translates[_index].zh,
                  name_en: self.data.bing_translates[_index].en,
                  short_name_en: self.data.bing_translates[_index].en,
                  short_name_zh: self.data.bing_translates[_index].zh,
                }
                // console.log(data);
                self.add_a_word_to_chain(self.chinese_names_chain,
                  self.data.bing_translates[_index].zh, data );
            }
        }
    },
    //end
    dfaGetShortKeys( msg, _type ){
        let self = this;

        _type = _type || 0
        var words = [];
        var all_words = [];

        for (var i=0; i<msg.length; i++){
            let _map = {};
            if (_type === 0) {
              _map = self.short_keys_chain;
            }else{
              _map = self.chinese_names_chain;
            }
            let sens_word = "";

            var new_task = {};
            for (var j=i; j<msg.length; j++ ){
              let chr = msg[j];
              if ( _map[chr] ){
                sens_word += chr;
                if ( _map[chr].hasOwnProperty( "\x00" ) ){
                    new_task = {}
                    new_task[sens_word] = _map[chr]["\x00"];
                    _map = _map[chr]
                    i=j;
                    continue
                    // words[sens_word] = _map[chr]["\x00"];
                    // break;
                }else{
                    _map = _map[chr];
                }
              }else{
                  if ( Object.keys(new_task).length >0 ){
                      // i = i - 1;
                      for (var _k in new_task){
                          words.push(new_task[_k])
                      }
                      new_task = {};
                  }
                  break;
              }
            }
            if ( Object.keys(new_task).length >0 ){
                for (var _k in new_task){
                    words.push( new_task[_k])
                }
            }
        }

        if (words.length>0){
            let new_msg = msg;
            var split_sym = '[***********]';
            for (var i=0; i< words.length; i++){
                let word = words[i]

                var splited = new_msg.replace( word.s, split_sym );
                var splited_list = splited.split(split_sym);
                if (splited_list[0].length>0){
                  all_words.push( { text:splited_list[0], type:0 } )
                }
                all_words.push( { text:word.s, type:1 } );
                new_msg = splited_list.slice(1).join( split_sym );
            }
            if (new_msg.length > 0){
                all_words.push( { text: new_msg, type:0 });
            }
        }else{
            all_words.push( { text:msg , type:0 });
        }
        // console.log(words);
        return {
          words:words,
          all_words: all_words,
        }
    },
    // end
    // 获取源
    getSourcesData(){
      let self = this;
      var params = {
        sport_id: self.sport_id,
        match_id: self.match_id,
      }
      MatchSources(params).then(response => {
        if (response.data.code == 0) {
          self.source_id = response.data.data.source_id;
          self.sources = response.data.data.sources;
        } else {
          this.$Message.error(response.data.msg)
        }
      })
    },

    changeSourceId(value){
      // console.log("changeSourceId: ", value)
      let self = this;
      var params = {
        sport_id: self.sport_id,
        match_id: self.match_id,
        source_id: value,
      }
      updateMatchSource(params).then(response => {
        // 重新获取当前的文字直播列表
        // self.$set(self.nami_text_lives, {} );
        self.nami_text_lives = {};
        self.apiGetLog(1);
        if (response.data.code == 0) {
          this.$Message.success('更新成功');
        } else {
          this.$Message.error(response.data.msg)
        }
      })
      
    },
    // end
    getData () {
      let self = this;
      var params = {
        sport_id: self.sport_id,
        match_id: self.match_id,
      }
      MatchDetail(params).then(response => {
        if (response.data.code == 0) {
          self.data = response.data.data;
          this.loading = false;
          // 生成小节数
          if ( self.get_data_count === 0 ){
              self.box_num = self.data.box_num || '1';
              self.get_data_count += 1;
              self.get_data_count = Math.min(10, self.get_data_count);
              self.cleanLogData();
          }
          // 生成快捷键
          for (var short_key in self.data.short_keys){
              if (self.short_keys_s.indexOf( short_key ) == -1){
                self.short_keys.push(self.data.short_keys[short_key]);
                self.short_keys_s.push(short_key);
              }
          }
          // 战队 + 选手衬衫号生成快捷键
          self.addPlayersToShortkeys();
        } else {
          this.$Message.error(response.data.msg)
        }
      })
    },
    // end
    getTabsData () {
      let self = this;
      var params = {
        sport_id: self.sport_id,
        match_id: self.match_id,
      }
      GetTabsList(params).then(response => {
        if (response.data.code == 0) {
          var data = response.data.data;
          this.loading = false;
          // 生成小节数
          var old_box_nums = []
          for ( var _index in self.tab_box_nums){
              // console.log(tab)
              old_box_nums.push( self.tab_box_nums[_index].name );
          }
          var need_update_tab = false;
          for (var _index in data.list){
              if (! old_box_nums.includes( data.list[_index].name )  ){
                need_update_tab = true;
                break
              }
          }
          if (need_update_tab){
            self.tab_box_nums = data.list;
          }
        } else {
          self.$Message.error(response.data.msg)
        }
      })
    },

    // 新增
    add_a_word_to_log(value){
      let self = this;
      var btn = value.currentTarget;
      // let short_key = btn.shortkey;
      let short_key = btn.getAttribute('data-shortkey');
      if (short_key && self.panel_add_log == '1'){
          // self.log += short_key;
          // self.$nextTick(()=>{
          //   // this.$refs['input0'][0].$refs.input.focus();
          //   self.$refs['inputBox'].focus();
          // })
          self.addTxtToLog(short_key);
      }
    },

    // 批量替换
    hookReplaceAll(){
      String.prototype.replaceAll = function(s1,s2){
        return this.replace(new RegExp(s1,"gm"),s2);
      }
    },

    // 获取文字直播
    apiGetLog(_type, box_num){
        let self = this;
        var params = {
            sport_id:self.sport_id,
            match_id: self.match_id,
            box_num: box_num,
            type: _type,
        }
        GetTextlive( params ).then(response => {
          if (response.data.code == 0) {
            // 重新获取文字直播
            if (_type == 0){
              // self.text_lives = response.data.data;
              for (var val in response.data.data){
                  self.$set(self.text_lives, val, response.data.data[val] );
                  // 当前局的直播列表 - 解决最后一条删除之后 数据依然存在的bug
              }
              self.updateNewlogScore();

            }else{
              // self.nami_text_lives = response.data.data;
              for (var val in response.data.data){
                  self.$set(self.nami_text_lives, val, response.data.data[val] );
              }
            }
          } else {
            this.$Message.error(response.data.msg)
          }
        })
    },
    //end
    cleanLogData(){
        // 清空日志
        let self = this;
        self.log = '';
        self.new_log.key = '';
        self.new_log.source = 0;
        // 新增
        // self.new_log.home_score = self.data.home_score || 0;
        // self.new_log.away_score = self.data.away_score || 0;
        self.updateNewlogScore();
        self.new_log.time_left = self.data.time_left || 0;
        if (self.new_log.time_left < 0){
          self.new_log.time_left = 0;
        }
        self.log_desc_zh = '';
        self.log_desc_en = '';
        self.$nextTick(()=>{
            // 新增
            // console.log('1'+ self.$refs['inputBox']);
            if (self.$refs['inputBox']){
              self.$refs['inputBox'].focus();
            }
        });
    },
    //end
    keyEvent( value ){
      let self= this;
      // console.log(value);
      if ( value.key ==="Control" || value.key ==="Enter" || value.key==='Alt'){
          window.event.preventDefault() //关闭浏览器快捷键
          self.apiAddLog();
      }
    },
    // 新增文字直播
    apiAddLog(neighborkey, insert){
        let self = this;
        if (self.spinShow){
            if (self.panel_add_log === '1'){
                self.$Message.error('处理中 请稍后..');
            }
            return
        }
        // console.log(JSON.stringify(self.new_log));
        if (self.new_log.key === ''){
            self.spinShow = true;
            var params = {
                desc_zh: self.log_desc_zh,
                desc_en: self.log_desc_en,
                log: self.log,
                time_left: self.new_log.time_left,
                home_score: self.new_log.home_score,
                away_score: self.new_log.away_score,
                sport_id:self.sport_id,
                match_id: self.match_id,
                box_num: self.box_num,
                team: self.new_log.team,
                source: self.new_log.source,
                neighborkey: neighborkey || '',
                insert: insert || 0,
                log_score: self.new_log.log_score,
                raw: self.new_log.raw,
            }
            self.panel_add_log = '0';
            // 上传到数据库
            AddTextlive( params ).then(response => {
              if (response.data.code === 0) {
                  // 重新获取文字直播
                  var home_score = parseInt( params.home_score );
                  var away_score = parseInt(params.away_score);
                  var log_score =parseInt( params.log_score);
                  var team = parseInt(params.team);
                  // console.log(JSON.stringify(self.new_log))
                  if ( log_score > 0){
                    if ( team === 1){
                        home_score +=  log_score;
                    }else if ( team === 2){
                        away_score +=  log_score;
                    }
                    var new_scores = {
                        "home_score": home_score,
                        "away_score": away_score,
                    }
                    self.$set(self.text_lives, "score_"+String(self.box_num), new_scores)
                }
                self.panel_add_log = '0';
                self.apiGetLog(0, self.box_num);
                self.cleanLogData();
                self.spinShow = false;
              } else {
                this.$Message.error(response.data.msg)
              }
            })
        }else{
            // 更新到key
            var params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                key: self.new_log.key,
                params: {
                    desc_zh: self.log_desc_zh,
                    desc_en: self.log_desc_en,
                    log: self.log,
                    time_left: self.new_log.time_left,
                    home_score: self.new_log.home_score,
                    away_score: self.new_log.away_score,
                    team: self.new_log.team,
                    // raw: self.new_log.raw,  // 更新不需要更新此字段 不上传会一直保留着旧数据 
                },
            }
            UpdateTextlive(params).then(response => {
              if (response.data.code == 0) {
                // 重新获取文字直播
                  self.$Message.success('更新成功');
                  self.apiGetLog(0, self.box_num);
                  self.panel_add_log = '0';
                  self.cleanLogData();
              } else {
                self.$Message.error(response.data.msg)
              }
            })
        }

    },
    // end

    addTxtToLog(new_txt){
      let self = this;
      if ( self.mouseStartPos === null){
        self.log += new_txt;
      }else{
        self.log = self.log.slice(0,self.mouseStartPos)+ new_txt +self.log.slice(self.mouseStartPos);
        var logInput = self.$refs['inputBox'];
        var input = logInput.$refs.input;
        self.$nextTick(()=>{
            self.$refs['inputBox'].focus();
            input.selectionStart = self.mouseStartPos + new_txt.length;//获取鼠标位置
            input.selectionEnd = self.mouseStartPos + new_txt.length;//获取鼠标位置
        })
      }
    },
    //
    playerClicked(row, column, data, event){
        // 表格点击事件
        let self = this;
        if ( self.panel_add_log=='1' ){
          // 填充到日志编辑里
           self.new_log.team = row.team;
           self.addTxtToLog(row.s);
        }
    },
    // end
    playerRowClicked(row){
        // let self = this;
        // console.log(1);
        // console.log(row);
        let self = this;
        if ( self.panel_add_log=='1' ){
          // 填充到日志编辑里
           self.new_log.team = row.team;
           self.addTxtToLog(row.s);
        }
    },
    //end
    changeStatus(value){
      let self = this;
      var params = {
        sport_id: self.sport_id,
        match_id: self.match_id,
        key: "STATUS",
        params: {value: value},
      }
      UpdateTextlive(params).then(response => {
        if (response.data.code == 0) {
          // 重新获取文字直播
          self.$Message.success('更新成功');
        } else {
          self.$Message.error(response.data.msg)
        }
      })
    },
    // 长连接
    socketConnetc (topic) {
      let self = this
      self.client = new Client({
        topic: `${topic}`,
        message (t, msg) {
          if (msg.type ===1 ){
              let msgData = msg.data;
              // 判断box-num是否需要更新
              if (self.box_num == self.data.box_num){
                 var update_box_num = true;
              } else{
                 var update_box_num = false;
              }

              for (var val in msgData) {
                  // alert(val + " " + myJson[val]);//输出如:name
                  self.data[val] = msgData[val];
              }
              if (update_box_num && self.panel_add_log == '0'){
                self.box_num = self.data.box_num || '1';
              }
          }else if (msg.type===2){
              let msgData = msg.data;
              // console.log(msgData);
              // 选手
              var new_home_players = msgData.home_players || [];
              var new_away_players = msgData.away_players || [];

              var home_players = self.data.home_players;
              var away_players = self.data.away_players;

              var new_home_player_ids = [];
              var new_away_player_ids = [];

              for (var index in new_home_players){
                  // 长连接中的数据 - 出厂置为1
                  new_home_players[index].gray = 0;
                  new_home_player_ids.push(new_home_players[index].id);
              }
              for (var index in new_away_players){
                  new_away_players[index].gray = 0;
                  new_away_player_ids.push(new_away_players[index].id);
              }
              // 设置新的选手
              // 首发与替补
              for (var _index in home_players ){
                if ( ! new_home_player_ids.includes( home_players[_index].id )){
                    // 新增选手
                    home_players[_index].gray = 1;  // 置灰
                    new_home_players.push( home_players[_index] );
                }
              }
              for (var _index in away_players ){
                if ( ! new_away_player_ids.includes( away_players[_index].id )){
                    // 新增选手
                    away_players[_index].gray = 1;  // 置灰
                    new_away_players.push( away_players[_index] );
                }
              }
              new_home_players.sort( function(a,b) {
                  if (a.gray !== b.gray ){
                      return a.gray - b.gray;
                  }else
                  if (a.is_show !== b.is_show ){
                      return a.is_show - b.is_show;
                  }
                  else if (a.is_shou !== b.is_shou){
                      return a.is_shou - b.is_shou;
                  }else{
                      return
                  }
              })
              new_away_players.sort( function(a,b) {
                  if (a.gray !== b.gray ){
                      return a.gray - b.gray;
                  }else
                  if (a.is_show !== b.is_show ){
                      return a.is_show - b.is_show;
                  }else if (a.is_shou !== b.is_shou){
                      return a.is_shou - b.is_shou;
                  }else{
                      return
                  }
              })
              self.data['home_players'] = new_home_players;
              self.data['away_players'] = new_away_players;

          }else if (msg.type === 3){
              // 纳米数据文字直播 长连接
              let msgData = msg.data;
              // // console.log(msgData);

              // 获取所有的  目前的box_nums
              var old_box_nums = []
              for ( var _index in self.tab_box_nums){
                  // console.log(tab)
                  old_box_nums.push( self.tab_box_nums[_index].name );
              }
              var need_update_tab = false;
              
              // 旧版本
              // for (var val in msgData) {
              //     // alert(val + " " + myJson[val]);//输出如:name
              //     // self.nami_text_lives[val] = msgData[val];
              //     if (! old_box_nums.includes( val )  ){
              //         need_update_tab = true;
              //     }
              //     if ( !self.nami_text_lives.hasOwnProperty( val )){
              //         // self.nami_text_lives[val] = [];
              //         var raw_tlives = [];
              //     }else{
              //         var raw_tlives = self.nami_text_lives[val];
              //     }
              //     var new_tlives = msgData[val];
              //     for (var i=0; i<new_tlives.length; i++ ){
              //         raw_tlives.unshift(  new_tlives[i] );
              //         self.$set(self.nami_text_lives, val, raw_tlives );
              //     }
              // }
              // if( need_update_tab ){
              //     // console.log("GET ALL TABS and DATAS");
              //     self.getTabsData(); // 获取所有tabs
              //     self.apiGetLog(0);
              // }
              // 新版本
              var source_box_nums = msgData.source_box_nums
              if (source_box_nums){
                // console.log(old_box_nums, source_box_nums)
                source_box_nums.forEach(_b => {
                if (! old_box_nums.includes( String(_b) )  ){
                    need_update_tab = true;
                  }
                })
              }
              // console.log(33333, old_box_nums, source_box_nums, need_update_tab , msg.data,)
              if( need_update_tab ){
                  // console.log("GET ALL TABS and DATAS");
                  self.getTabsData(); // 重新获取所有tabs以及线上数据
                  self.apiGetLog(0);
                  self.apiGetLog(1);
              }else{
                  // 仅仅更新源数据 
                  self.apiGetLog(1);
              }
          }else if (msg.type === 4){
              // console.log(msg)
              let msgData = msg.data;
              var box_num = msgData.box_num || 0;
              var kwargs = msgData.data;
              if ( kwargs ){
                  var auto_bing = kwargs.auto_bing;
                  if (auto_bing){
                      // 存在自动并入的数据 - 刷新表格
                      self.apiGetLog(0, box_num);
                  }
              }
          }
          if ( msg.type === 2){
              self.addPlayersToShortkeys();
          }
        },
        connect(){
          // if (self.get_data_count > 1){
              self.getData();
              self.apiGetLog(1);
          // }
        },
      })
    },
    // end
    bingTextLive(item){
        // 并入
        let self = this;

        // 对比比分与时间 - 提示错误信息 - 与当前局最后一个log数据对比
        let text_lives = self.text_lives[self.box_num];
        if (text_lives){
            var last_log = text_lives[0];
            if (last_log){
                if ( last_log.home_score > item.home_score ){
                    self.$Message.warning("并入数据 主队比分小于最后一条记录")
                }
                if ( last_log.away_score > item.away_score ){
                    self.$Message.warning("并入数据 客队比分小于最后一条记录")
                }
                if ( last_log.time_left < item.time_left ){
                    self.$Message.warning("并入数据 时间戳大于最后一条记录")
                }
            }
        }

        self.log_desc_zh = item.desc_zh;
        // 转化为对应的英文
        if (item.desc_en && item.desc_en.length>0){
            var desc_en = item.desc_en;
        }
        else {
            var desc_en = item.desc_zh  // .replaceAll('', '');
        }

        var words = self.dfaGetShortKeys( desc_en , 1);
        var word_list = words.words;

        for (var i in word_list ){
           let word = word_list[i];
           // var s = word.;
           var s_en =  word.short_name_en || word.name_en
           desc_en = desc_en.replaceAll( word.name_zh, s_en).replaceAll(word.short_name_zh, s_en);
        }
        desc_en = desc_en.replaceAll("  ", ' ').trim();

        self.log_desc_en = desc_en;
        self.log = '';
        self.new_log.time_left = item.time_left;
        self.new_log.home_score = item.home_score;
        self.new_log.away_score = item.away_score;
        self.new_log.source = 1;  // 并入
        self.new_log.team = item.team;
        if (item.raw){
          self.new_log.raw = item.raw
        }
        // console.log(self.new_log)
        self.panel_add_log = '1';
    },
    //end
    rowClassName (row, index) {
        let self = this;
        if (row.source === 0) {
            return 'demo-table-add-row';
        } else if (row.source === 1) {
            return 'demo-table-bing-row';
        }
        return '';
    },
    //end
    rowPlayerClassName (row, index) {
        let self = this;
        if (row.is_show === 0) {
            return 'player-table-show-row';
        } else  {
            return 'player-table-hide-row';
        }
    },
    //end
    deleteLogs(){
        let self = this;
        var del_keys = self.choosed_logs[self.box_num];
        if (del_keys){
            var params = {
              sport_id: self.sport_id,
              match_id: self.match_id,
              _keys: del_keys,
            }
            DeleteTextlive(params).then(response =>{
                if (response.data.code == 0) {
                  // 重新获取文字直播
                  self.$Message.success('删除成功');
                  self.apiGetLog(0, self.box_num);
                } else {
                  self.$Message.error(response.data.msg)
                }
            })
        }else{
            self.$Message.error("未选中数据 无法删除");
        }
    },
    //end
    transferSource(){
        let self = this;
        var params = {
          sport_id: self.sport_id,
          match_id: self.match_id,
          box_num: self.box_num,
          source_id: self.source_id,
        }
        TransferTextlive(params).then(response =>{
            if (response.data.code == 0) {
              // 重新获取文字直播
              self.apiGetLog(0, self.box_num);
              self.$Message.success('同步成功');
            } else {
              self.$Message.error(response.data.msg)
            }
        })
    },
    // end 
    apiSelectChange(rows){
      let self = this;
      self.choosed_logs = {}
      for (var i =0; i< rows.length; i++){
          var row = rows[i]
          var _key = row.key;
          var box_num = String( row.box_num );
          if ( !self.choosed_logs.hasOwnProperty( box_num )){
            self.choosed_logs[box_num] = []
          }
          // 写入到记录之中
          self.choosed_logs[box_num].push( _key );
      }
    },
    // End
    dragLogs(index1, index2){
        let self = this;
        if (index1 != index2){
            var data_list = self.text_lives[self.box_num];
            var ldata_1 = data_list[index1];
            var ldata_2 = data_list[index2];
            // 交换1和2的顺序
            var params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                key1: ldata_1.key,
                key2: ldata_2.key,
                box_num: self.box_num,
            }
            // 交换 排序
            DragTextlive(params).then( response =>{
                if (response.data.code == 0) {
                  // 重新获取文字直播
                  // self.$Message.success('删除成功');
                  self.apiGetLog(0, self.box_num);
                } else {
                  self.$Message.error(response.data.msg)
                }
            } )
        }
    },
    // end
    apiReplaceText(){
        let self = this;
        var params = {
          sport_id: self.sport_id,
          match_id: self.match_id,
          box_num: self.box_num,
          params: self.replaced_log,
        }
        ReplaceTextlive(params).then( response =>{
            if (response.data.code == 0) {
              // 重新获取文字直播
              // self.$Message.success('删除成功');
              self.apiGetLog(0, self.box_num);
            } else {
              self.$Message.error(response.data.msg)
            }
        } )
    },
    //end
    showAddModel(){
        let self = this;
        self.panel_add_log = '1';

        self.log = '';
        self.log_desc_en = '';
        self.log_desc_zh = '';
        self.new_log.source = 0;
        self.new_log.team = 0;
        // self.new_log.home_score = self.data.home_score || 0;
        // self.new_log.away_score = self.data.away_score || 0;
        self.updateNewlogScore();
        self.new_log.time_left = self.data.time_left || 0;
        if (self.new_log.time_left < 0){
          self.new_log.time_left = 0;
        }
        self.$nextTick(()=>{
            // 新增
            if (self.$refs['inputBox']){
              self.$refs['inputBox'].focus();
            }
        });
    },
    //  end
    addLogMap(s, _index){
        let self = this;
        if (! self.log_map[s] ){
            self.log_map[s] = []
        }
        self.log_map[s].push(_index);
    },
    // end
    teamClickd(value){
        let self = this;
        if ( self.panel_add_log=='1' ){
          // 填充到日志编辑里
            // 获取光标位置
            if (value==1){
              var new_value = 'qq';
              self.new_log.team = value;
            }else if (value==2){
              var new_value = 'ww';
              self.new_log.team = value;
            }
            // self.log += 'qq';
            self.addTxtToLog(new_value);
            // self.$nextTick(()=>{
            //   self.$refs['inputBox'].focus();
            // })
        }
    },
    //end
    handleContextMenu (row) {
        this.table_edit_row = row;
    },
    handleContextMenuInsertUp(row){
        let self = this;
        self.apiAddLog(self.table_edit_row.key, 1);
    },
    //end
    handleContextMenuInsertDown(row){
        let self = this;
        self.apiAddLog(self.table_edit_row.key, 2);
    },
    //end
    openDemoPage(){
        let self=this;
        let query = { match_id: self.match_id, sport_id: self.sport_id, box_num: self.box_num}
        // vm.$router.push({path: '/numer', query: query});
        let resolve_data = self.$router.resolve({ path: '/ballsports/'+ 'basketball' +'/match/text_live/demo', query: query })
        window.open(resolve_data.href, '_blank')
    },
    // end 
    openLinePage(){
        let self=this;
        let query = { match_id: self.match_id, sport_id: self.sport_id}
        // vm.$router.push({path: '/numer', query: query});
        let resolve_data = self.$router.resolve({ path: '/ballsports/'+ 'basketball' +'/match/text_live/line', query: query })
        window.open(resolve_data.href, '_blank')
    },
    // end
    beforeLogTabRemove(index){
      let self = this;
      return new Promise(function (resolve, reject) {
          if (index <= 3){
            self.$Message.warning('不可删除！');
            reject();
          }else{
            // 确认框
            self.$Modal.confirm({
                title: '确认删除',
                content: '<p>当前tab下内容奖杯删除 且不可恢复</p><p>是否确认删除?</p>',
                onOk: () => {
                    // this.$Message.info('Clicked ok');
                    resolve();
                },
                onCancel: () => {
                    // this.$Message.info('Clicked cancel');
                    reject();
                }
            });

          }
      })
    },
    handleLogTabRemove( name ){
      let self = this;
      // console.log(name);
      // console.log(self.tab_box_nums);
      var item_index = -1;
      for (var _index in self.tab_box_nums){
          if ( self.tab_box_nums[_index].name === name ){
              item_index = _index;
              break
          }
      }
      //
      if (item_index > -1) {
        // 上传到服务器
        DeleteLogTab({
          sport_id: self.sport_id,
          match_id: self.match_id,
          box_num: name,
        }).then(response => {
            // console.log(response.data);
            if (response.data.code === 0){
              self.tab_box_nums.splice( item_index, 1);
              self.getTabsData();
              self.apiGetLog(0);
              self.apiGetLog(1);
              self.text_lives[name] = [];
            }else{
              self.$Message.error( response.data.msg );
            }
        })
      }
    },
    // end
    handleLogTabsAdd(){
        // 新增tab
        let self = this;
        self.model_add_tab = true;
    },
    recordMouseIndex(){
      let self = this;
      var logInput = self.$refs['inputBox'];
      const input = logInput.$refs.input;
      var startPos = input.selectionStart;//获取鼠标位置
      var endPos = input.selectionEnd;//获取鼠标位置
      self.mouseStartPos = startPos;
      // console.log(self.mouseStartPos);
    },
    //end
    apiAddLogTab(){
        let self = this;
        // Todo 1 将新box_num上传到服务器
        // 2 本地的tab新增一个新的小节数
        var item_index = -1;
        // console.log( self.tab_box_nums );
        for (var _index in self.tab_box_nums){
            if ( self.tab_box_nums[_index].name === String(self.new_tab_box_num ) ){
                item_index = _index;
                break
            }
        }
        if (item_index > -1){
            self.$Message.error(self.new_tab_label + "已经存在");
        }else{
            self.tab_box_nums.push({
              "name": String(self.new_tab_box_num ),
              "label": self.new_tab_label,
            })
            self.box_num = String(self.new_tab_box_num);
        }
    },
    // end
    //更新new_log的score
    updateNewlogScore(box_num){
        let self = this;
        if (!box_num){
          box_num = self.box_num;
        }
        if (self.text_lives["score_"+String(box_num)]){
          self.new_log.home_score = self.text_lives["score_"+String(box_num)].home_score || 0;
          self.new_log.away_score = self.text_lives["score_"+String(box_num)].away_score || 0;
        }else{
          self.new_log.home_score = 0;
          self.new_log.away_score = 0;
        }

    },
    //end
  },
  mounted () {
    let self = this;
    this.sport_id = this.$route.query.sport_id
    this.match_id = this.$route.query.match_id
    this.getSourcesData();
    this.getData();
    this.getTabsData();
    this.hookReplaceAll();
    // var a = msgpack.serialize({1:1});
    document.onkeydown = function(e) {
        let key = window.event.keyCode;
        if (key == 9){
            // Tab
            window.event.preventDefault() //关闭浏览器快捷键
            // tab
            if (self.panel_add_log == '0'){
              self.panel_add_log = '1';
            }else{
              self.panel_add_log = '0';
            }
        }else{

        }
    };

    self.apiGetLog(0);
    self.apiGetLog(1);
  },
  created () {
    this.sport_id = this.$route.query.sport_id
    this.match_id = this.$route.query.match_id
    var topic = "namicms/match_detail/"+ this.sport_id + "_" + this.match_id;
    this.socketConnetc(topic); //
  },
  watch: {
    //end
    log( value, oldValue ){
        let self = this;
        var new_words_dic = self.dfaGetShortKeys( value );
        var new_words = new_words_dic.words;
        var new_all_words = new_words_dic.all_words;

        if (value.length>0 && value.length < oldValue.length){
          // 删除操作
          var old_words_dic = self.dfaGetShortKeys( oldValue );
          var old_words = old_words_dic.words;
          var old_all_words = old_words_dic.all_words;

          var target_word = {};  // log中即将被删除掉的值
          // console.log(old_words);
          // console.log(new_words);
          if (new_words.length <= old_words.length){
              //
              for (var i=0; i<new_words.length; i++){
                  var newword = new_words[i];
                  var oldword = old_words[i];
                  if (newword != oldword){
                      target_word = oldword;
                      break;
                  }
              }
              if (new_words.length < old_words.length && Object.keys(target_word).length === 0 ){
                  target_word = old_words[ old_words.length - 1 ];
              }
              var new_index = 0;
              var lock_new_index = false;
              if ( Object.keys(target_word).length > 0 ){
                  var new_log_text = '';
                  for (var j=0; j<old_all_words.length; j++){
                      var item = old_all_words[j];
                      if ( item.text === target_word.s ){
                            if ( new_all_words[j] && new_all_words[j].text === target_word.s ){
                            }else{
                                lock_new_index = true;
                                continue
                            }
                      }
                      if ( !lock_new_index){
                        new_index +=  item.text.length;
                      }
                      new_log_text += item.text;
                  }
                  // 设置光标位置
                  var logInput = self.$refs['inputBox'];
                  const input = logInput.$refs.input;
                  var startPos = input.selectionStart;//获取鼠标位置
                  var endPos = input.selectionEnd;//获取鼠标位置
                  self.log = new_log_text;
                  self.$nextTick(()=>{
                      self.$refs['inputBox'].focus();
                      input.selectionStart = new_index;
                      input.selectionEnd = new_index;
                  })
                  return
              }
          }
        }
        if (value.length > 0){
            self.log_desc_zh = self.log;
            self.log_desc_en = self.log;
            var new_words_copy = new_words.slice(0);
            new_words_copy.sort( (a,b) =>
              b.s.length - a.s.length
            )

            // console.log(new_words);
            // var home_log_score = 0;
            // var away_log_score = 0;
            var log_score = 0;
            self.new_log.team = 0;
            for (var i=0; i<new_words_copy.length; i++){
                // var s = new_words[i]; // 快捷键
                var item = new_words_copy[i];
                var s = item.s;
                var desc_zh = item.zh;
                var desc_en = item.en;
                if ( self.log.indexOf( s ) > -1 ){
                  if (desc_zh) self.log_desc_zh = self.log_desc_zh.replaceAll( s, desc_zh );
                  if (desc_en) self.log_desc_en = self.log_desc_en.replaceAll( s, desc_en );
                  if (item.team){
                      self.new_log.team = item.team;
                  }
                  self.addLogMap(s, self.log.indexOf( s ) );
                }
                // 获取得分
                if (item.score && typeof item.score === "number"){
                    log_score += item.score;
                }
            }
            // 获取得分所在的战队
            if ( (self.new_log.key==='' || (!self.new_log.key)) && self.new_log.team >0  && log_score>0){
                self.new_log.log_score = log_score;
            }else{
                self.new_log.log_score = 0;
            }
            // console.log(self.new_log);

            // 处理一些需要及时获取信息的键 - 第几节开始与结束
            let box_dic = {
              "zh": {'1': '一', '2': '二', '3': '三', '4': '四'},
              "en": {'1': ' 1st ', '2': ' 2nd', '3': ' 3rd ', '4': '4th'},
            }
            if (self.log.indexOf( '`1' ) > -1 ){
                var desc_zh = null;
                var desc_en = null;
                if ( parseInt( self.box_num) < 5){
                  var box_str_zh = box_dic.zh[self.box_num]
                  var box_str_en = box_dic.en[self.box_num]
                  if (box_str_zh && box_str_en){
                       desc_zh = '第' + box_str_zh + '节开始';
                       desc_en = 'Start of the '+ box_str_en +' Quarter ';
                  }
                }else if ( parseInt( self.box_num) >= 5){
                   desc_zh = '加时'+ String( parseInt( self.box_num)-4) +'开始';
                   desc_en = 'Start of Overtime '+ String(parseInt( self.box_num)-4);
                }
                if (desc_zh && desc_en){
                    self.log_desc_zh = self.log_desc_zh.replaceAll( '`1', desc_zh );
                    self.log_desc_en = self.log_desc_en.replaceAll( '`1', desc_en );
                }else{
                    self.$Message.error("无法识别当前的小局")
                }
                self.addLogMap('`1', self.log.indexOf( '`1' ) );
            }
            if (self.log.indexOf( '`2' ) > -1 ){
                var desc_zh = null;
                var desc_en = null;
                if (parseInt( self.box_num) < 5){
                  var box_str_zh = box_dic.zh[self.box_num]
                  var box_str_en = box_dic.en[self.box_num]
                  if (box_str_zh && box_str_en){
                       desc_zh = '第' + box_str_zh + '节结束';
                       desc_en = 'End of the '+ box_str_en +' Quarter ';
                  }
                }else if (parseInt( self.box_num) >=5){
                   desc_zh = '加时'+ String(parseInt( self.box_num)-4) +'结束';
                   desc_en = 'End of Overtime '+ String(parseInt( self.box_num)-4);
                }
                if (desc_zh && desc_en){
                    self.log_desc_zh = self.log_desc_zh.replaceAll( '`2', desc_zh );
                    self.log_desc_en = self.log_desc_en.replaceAll( '`2', desc_en );
                }else{
                    self.$Message.error("无法识别当前的小局")
                }
                self.addLogMap('`2', self.log.indexOf( '`2' ) );
            }

            // 正则匹配 鼠标点击
            // let mouse_players = value.match(/(?<={player:+).*?(?=})/gs) ;
            // const regix = new RegExp('/(?<={player:+).*?(?=})/gs');
            // let mouse_players = value.match(regix) ;
            // self.log_desc_zh = self.log_desc_zh.replaceAll( '  ', ' ' );
            self.log_desc_en = self.log_desc_en.replaceAll( '  ', ' ' );

        } else {
            self.log_desc_zh = '';
            self.log_desc_en = '';
            self.new_log.team = 0;
            self.new_log.log_score = 0;
        }
    },
    // end
    panel_add_log(value){
        let self=this;
        if (value=='1'){
            self.$nextTick(()=>{
              // this.$refs['input0'][0].$refs.input.focus();
                self.$refs['inputBox'].focus();
                if (self.new_log.source == 0 && (self.new_log.key==='') ){
                    // 新增
                    // self.new_log.home_score = self.data.home_score || 0;
                    // self.new_log.away_score = self.data.away_score || 0;
                    self.updateNewlogScore();
                    self.new_log.time_left = self.data.time_left || 0;
                    if (self.new_log.time_left < 0){
                        self.new_log.time_left = 0;
                    }
                }else{
                  // 并入
                  // self.$refs['inputBox'].focus();
                }
            })
        }else{
            self.log = '';
            self.log_desc_en = '';
            self.log_desc_zh = '';
            self.new_log.source = 0;
            self.new_log.key = '';
        }
    },
    // end
    new_tab_box_num(value){
      let self = this;
      if (value - 4 > 0){
          self.new_tab_label = '加时'+(value-4);
      }else{
          self.new_tab_label = '无效的加时小节';
      }
    },
    // end
    box_num(value){
      let self = this;
      if (!self.text_lives[String(value)] || self.text_lives[String(value)].length ===0 ){
        self.apiGetLog(0, value);
      }
      self.updateNewlogScore(value);
    },
    //end
    // end 
  },
  computed: {
    match_time: function(){
      let self = this;
      if (self.data){
        return moment.unix(self.data.match_time).format('YYYY-MM-DD HH:mm')
      }
    }
  }
}
</script>

<style >

  .ivu-table .demo-table-bing-row td:first-child{
      background-color: #2db7f5;
      color: #fff;
  }
  .player_shou{
      background: red !important;
  }

  .player_bu{
      background: #2db7f5 !important;
  }

  .ivu-table .player-table-hide-row td{
      background-color:  #EEE;
  }

  .log_score {
    font-size:10px;
    color:red;
  }
</style>

<style scoped>
  /* .ivu-form-item{
    margin-bottom: 0px;
  } */
</style>