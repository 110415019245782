
<template>
  <div>
    <Row style="margin-bottom: 10px">
      <Col span="12" style="text-align: left">
        <Input
          search
          suffix="ios-search"
          placeholder="搜索"
          style="width: auto"
          @on-search="onSearch"
        />
        <Button @click="showModal()" type="success" style="margin-left: 20px"
          >添加</Button
        >
      </Col>
    </Row>
    <Modal v-model="modal" title="添加" @on-ok="add">
      <Form :label-width="100" style="margin-top: 1px" @submit.native.prevent>
        <FormItem label="ID" v-if="edit_word.id">
          <Input v-model="edit_word.id" placeholder="" disabled />
        </FormItem>
        <FormItem label="中文">
          <Input v-model="edit_word.name_zh" placeholder="" />
        </FormItem>
        <FormItem label="英文">
          <Input v-model="edit_word.name_en" placeholder="" />
        </FormItem>
        <FormItem label="中文简称">
          <Input v-model="edit_word.short_name_zh" placeholder="" />
        </FormItem>
        <FormItem label="英文简称">
          <Input v-model="edit_word.short_name_en" placeholder="" />
        </FormItem>
      </Form>
    </Modal>
    <fitTable :columns="columns" :data="data" :loading="loading"></fitTable>
    <Page
      :total="total_page"
      :current="current_page"
      :page-size="page_size"
      @on-change="onPageChange"
      show-total
      show-elevator
    ></Page>
  </div>
</template>

<script>
import { GetRound, DelRound, UpdateRound, AddRound } from "@/api/games/round";
import fitTable from "../../basic/table.vue";

const delButton = (vm, h, params) => {
  return h(
    "Poptip",
    {
      props: {
        confirm: true,
        title: "您确定要删除这条数据吗?",
        transfer: true,
      },
      on: {
        "on-ok": () => {
          const data = {
            game_id: vm.game_id,
            id: params.row.id,
          };
          DelRound(data).then((response) => {
            if (response.data.code == 0) {
              vm.data.splice(params.index, 1);
            } else {
              vm.$Message.error(response.data.msg);
            }
          });
        },
      },
    },
    [
      h(
        "Button",
        {
          style: {
            // margin: "0 5px"
          },
          props: {
            type: "warning",
            // size: "small"
          },
        },
        "删除"
      ),
    ]
  );
};
const editButton = (vm, h, params) => {
  return h(
    "Button",
    {
      props: {
        type: "success",
        // size: 'small'
      },
      on: {
        click: () => {
          vm.edit_word = {
            id: params.row.id,
            name_zh: params.row.name_zh,
            name_en: params.row.name_en,
            short_name_zh: params.row.short_name_zh,
            short_name_en: params.row.short_name_en,
          };
          vm.modal = true;
        },
      },
    },
    "编辑"
  );
};

export default {
  props: {
    game_id: Number, // 运动id
  },
  data() {
    let self = (window.x = this);
    return {
      modal: false,
      edit_word: {
        id: 0,
        name_zh: "",
        name_en: "",
        short_name_zh: "",
        short_name_en: "",
      },
      type: "0",
      search_key: "",
      loading: true,
      total_page: 0,
      page_size: 0,
      current_page: 1,
      columns: [
        {
          title: "id",
          key: "id",
        },
        {
          title: "中文",
          key: "name_zh",
        },
        {
          title: "英文",
          key: "name_en",
        },
        {
          title: "中文简称",
          key: "short_name_zh",
        },
        {
          title: "英文简称",
          key: "short_name_en",
        },
        {
          title: "操作",
          key: "deleted",
          align: "center",
          render(h, params) {
            return [delButton(self, h, params), editButton(self, h, params)];
          },
        },
      ],
      data: [],
    };
  },
  components: {
    fitTable,
  },
  methods: {
    onPageChange(page) {
      this.current_page = page;
      this.getData();
    },
    onSearch(search_key) {
      this.current_page = 1;
      this.search_key = search_key;
      this.getData();
    },
    showModal() {
      this.modal = true;
      this.edit_word = {
        id: 0,
        name_zh: "",
        name_en: "",
        short_name_zh: "",
        short_name_en: "",
      };
    },
    add() {
      let self = this;
      const row_id = self.edit_word.id;
      if (!row_id) {
        // 新增
        const params = {
          game_id: self.game_id,
          name_zh: self.edit_word.name_zh,
          name_en: self.edit_word.name_en,
          short_name_zh: self.edit_word.short_name_zh,
          short_name_en: self.edit_word.short_name_en,
        };
        AddRound(params).then((response) => {
          if (response.data.code == 0) {
            this.modal = false;
            this.getData();
          } else {
            this.$Message.error(response.data.msg);
          }
        });
      } else {
        // 更新
        const params = {
          id: row_id,
          game_id: self.game_id,
          kvs: {
            name_zh: self.edit_word.name_zh,
            name_en: self.edit_word.name_en,
            short_name_zh: self.edit_word.short_name_zh,
            short_name_en: self.edit_word.short_name_en,
          },
        };
        UpdateRound(params).then((response) => {
          if (response.data.code == 0) {
            this.modal = false;
            this.getData();
          } else {
            this.$Message.error(response.data.msg);
          }
        });
      }
    },
    getData() {
      let self = this;
      this.loading = true;
      const params = {
        game_id: self.game_id,
        page: self.current_page,
        search_key: self.search_key,
      };
      GetRound(params).then((response) => {
        if (response.data.code == 0) {
          this.data = response.data.data.list;
          this.total_page = response.data.data.total_page;
          this.page_size = response.data.data.page_size;
          this.current_page = response.data.data.current_page;
          this.loading = false;
        } else {
          this.$Message.error(response.data.msg);
        }
      });
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    game_id(value) {
      let self = this;
      self.getData();
    },
  },
  computed: {},
};
</script>

<style>
</style>
