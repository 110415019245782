<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Row :gutter="16" type='flex' align='top'>
            <!-- 状态 -->
            <Col span=6 class='box-border'>
                <Row justify='center' class="text-main"><span style="text-overflow:ellipsis; white-space:nowrap; overflow:hidden;">比赛状态</span></Row>
                <Row type='flex' justify='center'>
                    <Col span=22>
                        <Select v-model="status_id" :class="locks[String(type)+'-1'] === 1? 'locked-select': '' ">
                            <Option v-for="item in $conf['ballsports']['status'][sport_id]['map']" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </Col>
                    <Col span=2>
                        <Lock v-model="locks[String(type)+'-1']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key=""></Lock>
                    </Col>
                </Row>
            </Col>
            <!-- 新增按钮 -->
            <Col span=12 class='box-border'>
                <Row justify='center' class="text-main"><span style="text-overflow:ellipsis; white-space:nowrap; overflow:hidden;">新增盘\比分</span></Row>
                <Row type='flex' justify='center'>
                    <Col span=6>
                        <Select v-model="new_single.sub_type" >
                            <Option v-for="item in score_boxes" :value="item.type" :key="item.type">{{item.desc}}</Option> 
                        </Select>
                    </Col>
                    <Col span=3>
                        <Input v-model="new_single.box_num" placeholder="盘数" :disabled="[1,2].indexOf(new_single.sub_type)===-1"></Input>
                    </Col>
                    <Col span=4>
                        <Input v-model="new_single.score[0]" number placeholder="主分"></Input>
                    </Col>
                    <Col span=4>
                        <Input v-model="new_single.score[1]" number placeholder="客分"></Input>
                    </Col>
                    <Col span=6>
                        <Button long type="primary" size="small" @click="modal_add_on_ok()">新增盘\比分</Button>
                    </Col>
                </Row>
            </Col>
            <!-- 切换源 -->
            <Col span=6 class='box-border' >
                <Row justify='center' class="text-main"><span style="text-overflow:ellipsis; white-space:nowrap; overflow:hidden;">第三方源切换</span></Row>
                <Row>
                    <Col span=12>
                        <Select v-model="current_source_id" @on-change="on_source_id_change">
                            <!-- <Option value="0" key="0">自动</Option> -->
                            <Option value="1" key="1">手动</Option>
                            <Option v-for="item in third_score_dic" :value="item.source_id" :key="item.source_id">{{ item.source_name }}</Option>
                        </Select>
                    </Col>
                    <Col span=12>
                        <Button type="primary" long @click="api_save">保存</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        <br />
        <Row :gutter="16" type='flex' align='middle'>
            <!-- 盘 -->
            <Col v-for="(item, index) in data.singles" :key="index" span=2 class='box-border'>
                <Row justify='center' class="text-main">
                    <span style="text-overflow:ellipsis; white-space:nowrap; overflow:hidden;">
                        <Tooltip placement="top" :content="item.desc" >
                            {{ item.desc }}
                        </Tooltip>
                    </span> 
                </Row>
                <Row type='flex' justify='center'><Input style="width: 60px" v-model="item.score[0]" placeholder="主" :class="locks[String(type)+'-2'+ '-' + item.key] === 1? 'locked-input': '' "></Input></Row>
                <Row type='flex' justify='center'><Input style="width: 60px" v-model="item.score[1]" placeholder="客" :class="locks[String(type)+'-2'+ '-' + item.key] === 1? 'locked-input': '' "></Input></Row>
                <Row type='flex' justify='center'>
                    <Col span=10>
                        <Lock v-model="locks[String(type)+'-2'+ '-' + item.key]" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="2" :_key="item.key"></Lock>
                    </Col>
                    <Col span=10>
                        <Icon @click.native="rmSingle( item, index)" type="ios-close-circle" />
                    </Col>
                </Row>
            </Col>
        </Row>
        <br />
        <!-- 第三方关联的源的比分 -->
        <Table 
            :columns="third_columns" 
            :data="third_score_list" 
        >
        </Table>
    </div>
</template>
<script>

import { getMatchScore, updateMatchScore } from '@/api/ballsports/base/match';
import Lock from '../lock.vue';
// import moment from 'moment';

export default {
    name: "detail-score",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
        Lock,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type   : 1, // 比赛
            type       : 1, // 比分
            spinShow: false,
            current_source_id: '',
            data: {
                status_id: 0,   // 状态id
                singles: {},
            },
            locks: {},
            new_single: {
                sub_type: 1,  // 
                box_num : 0,
                score  : [],   // 比分
            },
            // 第三方源比分展示
            third_columns: [],  // 列
            third_score_list: [], // 比分
            third_columns_dic: {}, // 列对应的唯一标识
            third_score_dic : {}, // 第三方源及其比分数据

            // 修改变动历史
            counter : 0, 
            raw_copy: {}, // 原始数据的备份
            score_boxes: {},
        }
    },
    methods: {
        // 
        _init_score_boxes(){
            // 游戏列表
            // 5: {"name_en": "cricket", "name_zh": "板球", icon: '', logo: 'cricket.png', },
            // 6: {"name_en": "baseball", "name_zh": "棒球", icon: '', logo: 'baseball.png', },
            // 7: {"name_en": "handball", "name_zh": "手球", icon: 'ios-hand-outline', logo: '', },
            // 8: {"name_en": "ice_hockey", "name_zh": "冰球", icon: '', logo: 'ice_hockey.png', },
            // 10: {"name_en": "volleyball", "name_zh": "排球", icon: '', logo: 'volleyball.png', },
            // 11: {"name_en": "table_tennis", "name_zh": "乒乓球", icon: '', logo: 'table_tennis.png', },
            // 17: {"name_en": "american_football", "name_zh": "美式橄榄球", icon: '', logo: 'american_football.png', },
            // 19: {"name_en": "snooker", "name_zh": "斯诺克", icon: '', logo: 'snooker.png', },
            // 22: {"name_en": "waterpolo", "name_zh": "水球", icon: 'ios-water-outline', logo: '', },
            // 24: {"name_en": "badminton", "name_zh": "羽毛球", icon: '', logo: 'badminton.png', },
            // 盘信息
            // 1: {"key": 'p', "desc": "盘", "type": 1,},
            // 2: {"key": 'x', "desc": "抢七", "type": 2,},
            // 3: {"key": 'ft', "desc": "总比分", "type": 3,},
            // 4: {"key": 'pt', "desc": "实时局比分", "type": 4,},
            // 5: {"key": 'ot', "desc": "加时赛比分", "type": 5,},
            // 6: {"key": 'ap', "desc": "点球比分", "type": 6,},
            // 7: {"key": 'h', "desc": "安打计数总和", "type": 7,},
            // 8: {"key": 'e', "desc": "整队在防守时的失误次数", "type": 8,},
            let self = this;
            var confs = {
                3 : [1,2,3,4],   // 网球
                5 : [1,3], // 板球
                6 : [1,3,5,7,8], //棒球
                7 : [1,3,5,6],//手球
                8 : [1,3,5,6],//冰球
                10: [1,3],//排球
                11: [1,3],//乒乓球
                17: [1,3,5],//美式橄榄球
                19: [3],//斯诺克
                22: [1,3,5,6],//水球
                24: [1,3],//羽毛球
            }
            let type_ids = confs[self.sport_id] || [];
            if (type_ids && type_ids.length > 0){
                type_ids.forEach(_type => {
                    if (self.$conf.ballsports.score_boxes[_type]  ){
                        self.score_boxes[_type] = self.$conf.ballsports.score_boxes[_type];
                    }
                });
            }
        },
        // end 
        // 解析第三方的数据 成表格的样式
        _init_third_score(data_dic){
            let self = this;
            self.third_columns = []  // 列
            self.third_score_list = [] // 比分
            self.third_columns_dic = {} // 列对应的唯一标识
            if ( ! (self.third_columns_dic["source_name"]) ){
                self.third_columns.push({
                    title: '源名称',
                    align: 'center',
                    key: "source_name",
                    minWidth: 80,
                    fixed: "left",
                })
                self.third_columns_dic["source_name"] = true;
            }
            if ( ! self.third_columns_dic["status_id"] ){
                self.third_columns.push({
                    title: '状态',
                    align: 'center',
                    key: "status_id",
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var score = currentRow.score;
                        var status_name = ''
                        if (score && score.status_id){
                            var status = self.$conf['ballsports']['status'][self.sport_id]['map'][score.status_id] ;
                            status_name = status? status.name : "未知id: " +  score.status_id;
                        }
                        return h('span', status_name)
                    }
                })
                self.third_columns_dic["status_id"] = true;
            }
            for (var source_id in data_dic){
                var element = data_dic[source_id];
                if ( element && element.score ){
                    var singles = element.score.singles || {};
                    for (var pan_key in singles){
                        var single = singles[pan_key];
                        if ( ! self.third_columns_dic[pan_key] ){
                            self.third_columns.push({
                                title: single.desc,
                                align: 'center',
                                key  : pan_key,
                                minWidth: 80,
                                render: function (h, params) {
                                    let currentRow = params.row;
                                    var score = currentRow.score;
                                    var _singles = score.singles || {}; 
                                    var _single = _singles[params.column.key];
                                    var scores = _single? _single.score.join(' - ') : ''
                                    return h('span', scores)
                                }
                            })
                            self.third_columns_dic[pan_key] = true;
                        }
                    }
                }
                self.third_score_list.push(element)
            }
        },
        // end

        // 获取比分数据
        getData () {
            let self = this;
            self.spinShow = true;
            // 搜索日期
            var params = {
                sport_id: this.sport_id,
                match_id: this.match_id,
            }
            getMatchScore( params).then(response => {
                if (response.data.code == 0) {
                    self.current_source_id = response.data.data.current_source_id;
                    self.data  = response.data.data.score;
                    self.locks = response.data.data.locks;
                    self.third_score_dic = response.data.data.third_score;      // 更新比分的时候使用
                    self._init_third_score( self.third_score_dic );
                    self.spinShow = false;
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        // end 
        rmSingle( item, index){
            let self = this;
            var _key = item.key;
            // delete self.data.singles[_key];
            if (self.data.singles){
                self.$delete(self.data.singles, _key);
            }
        },
        // end 
        modal_add_on_ok(){
            let self = this;
            if (self.new_single){
                if (self.new_single.sub_type === 1 || self.new_single.sub_type === 2){
                    // 盘和抢七盘
                    if (self.new_single.box_num > 0){
                        if (self.new_single.sub_type == 1){
                            var _key = "p" + self.new_single.box_num;
                            var desc = "盘";
                        }else if (self.new_single.sub_type == 2){
                            var _key = "x" + self.new_single.box_num;
                            var desc = "抢7盘";
                        }else{
                            self.$Message.error("类型错误:请选择'盘'或者'抢七'");
                            return
                        }
                        desc += self.new_single.box_num;
                    }else{
                        self.$Message.error("无效的'盘数'")
                        return
                    }
                }else{
                    //     <Option :value="3" :key="3">总比分</Option>
                    //     <Option :value="4" :key="4">实时局比分</Option>
                    //     <Option :value="5" :key="5">加时赛比分</Option>
                    //     <Option :value="6" :key="6">点球比分</Option>
                    //     <Option :value="7" :key="7">安打计数总和(棒球)</Option>
                    //     <Option :value="8" :key="8">整队在防守时的失误次数(棒球)</Option>
                    var option = self.$conf.ballsports.score_boxes[self.new_single.sub_type];
                    if (option){
                        var _key = option["key"];
                        var desc = option["desc"];
                    }else{
                        self.$Message.error("不支持的添加类型 请重新编辑")
                        return
                    }
                }
                self.new_single["key"] = _key;
                // console.log(self.new_single, self.data.singles);
                if (! self.data.singles ){
                    self.data.singles = {};
                }
                self.$set(self.data.singles, _key, JSON.parse( JSON.stringify( {
                    "key": _key,  // 全局比分
                    "desc": desc,
                    "score": self.new_single.score,
                })));
            }
            self.new_single = {
                sub_type: 1,    // 
                box_num : 0,    
                score  : [],    // 比分
            };
        },
        // end
        api_save(){
            let self = this;
            // 将比分 - 保存到数据库
            var params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                score: self.data,
                current_source_id: self.current_source_id,  // 当前的比分源id
            }
            if (! self.third_columns_dic['p3'] ){
                self.third_columns.push({
                    title: "盘3",
                    align: 'center',
                    key  : "p3",
                    render: function (h, params) {
                        let currentRow = params.row;
                        var score = currentRow.score;
                        var _singles = score.singles || {}; 
                        var _single = _singles[params.column.key];
                        var scores = _single? _single.score.join(' - ') : ''
                        return h('span', scores)
                    }
                })
                self.third_columns_dic['p3']  = true;
            }
            self.$set(self.third_score_dic['adasdasd1'].score.singles, 'p3', {key: "p3", desc: "盘3", score: [6, 1]})

            // updateMatchScore( params).then(response => {
            //     if (response.data.code == 0) {
            //         self.$Message.success( "保存成功!" )
            //     } else {
            //         self.$Message.error(response.data.msg)
            //     }
            // })
        },
        // end 
        on_source_id_change(value){
            // 替换比分
            let self = this;
            if (value === '0'){
            }else if (value === '1'){
            }else{
                // 使用第三方源的数据直接替换
                var source_data = self.third_score_dic[value] || {};
                self._update_data( source_data.score );
            }
        },
        // end
        _update_data( new_data ){
            // 使用结构相同的新数据更新旧数据
            // 待更新的数据如下:
            // status_id    :       String(type)+'-1' = "1-1"
            // singles._key :       String(type)+'-2'+ '-' + item.key = "1-2-{_key}"
            // 判断self.locks中是否存在锁 如果存在 则保留之前的数据 否则更新
            let self = this;
            if (! new_data){
                new_data = {}
            }
            // 1 status_id
            if (self.locks[String(self.type)+'-1'] === 1){
                // 保留之前的数据
            }else if (self.data.status_id !== new_data.status_id){
                self.$set(self.data, "status_id", new_data.status_id);
            }
            // 2 singles
            var new_singles = JSON.parse(JSON.stringify( new_data.singles || {} )) ;  // 创建新的对象
            var data_singles = JSON.parse(JSON.stringify(self.data.singles || {} )) ;
            for (var _key in data_singles){
                var item = data_singles[_key];
                if ( self.locks[String(self.type)+'-2'+ '-' + _key] === 1 ){
                    // 锁住
                }else if ( new_singles[_key] ){
                    if (item.score !== new_singles[_key].score ){
                        self.$set( self.data.singles, _key, new_singles[_key] )
                    }
                }else{
                    self.$delete( self.data.singles, _key );
                }
                delete new_singles[_key];
            }
            // 有可能新增 - 键
            if (! self.data.singles ){
                self.data.singles = {};
            }
            for ( var new_key in new_singles ){
                self.$set(self.data.singles, new_key, new_singles[new_key]);
            }
        },
        // end
        feed( data ){
            // 处理实时数据
            // console.log("LIVE IN SCORE: ", data)
            let self = this;
            // self.data  = data;
            self._update_data(data);
        },
        // end
    },
    mounted () {
    },
    watch: {
        'sport_id': {
            handler(value){
                let self = this;
                self._init_score_boxes();
            },
	        immediate: true,  // 深度触发
        },
        // end 
        'match_id': {
            handler(value){
                let self = this;
                if (value){
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        },
        // end 
        'data': {
            handler(value){
                let self = this;
                self.counter += 1;
                if (self.counter > 1){
                    // console.log("changed: ", value)
                    self.counter = 2;  // 最大为2
                    // this.$emit('onChange', self.type);
                }else if (self.counter === 1){
                    self.raw_copy = JSON.parse( JSON.stringify( self.data ));
                }
            },
            deep: true,
        },
        // end 
    },
    computed: {
        status_id: {
            get: function(){
                return this.data.status_id ? this.data.status_id: 0;
            },
            set: function(val){
                this.data.status_id = val;
            }
        },
    }
}
</script>
<style scoped>
    @import "detail.css"
</style>