import http from '@/utils/http';

// 获取比赛源列表
export const getSourceList = (params) => {
    return http.request({
        url: "/v1/ballsports/base/source/list",
        method: 'get',
        params: params,
    })
}

// 获取第三方源的比赛列表 - 搜索赛程
export const getSourceMatch = (params) => {
    return http.request({
        url: "/v1/ballsports/base/source/match",
        method: 'get',
        params: params,
    })
}