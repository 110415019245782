<template>
    <div @click="add" @contextmenu.prevent="dec()" @dblclick="input">
        <Input ref='number' v-model='number' :readonly='disabled' @on-blur='blur' @on-enter='enter'></Input>
    </div>
</template>

<script>
export default {
    name: 'NumerEditor',
    props: {
        value: {
            default: 0,
        }
    },
    data() {
        return {
            number: 0,
            clickTimeId: null,
            disabled: true,
        }
    },
    watch: {
        value(val) {
            // 监听别人修改的数据
            // console.log('watch value: ', val)
            this.number = val;
        },
        // 子组件 
        // number(val, old) {
        //     // console.log(val, old)
        //     // 首次加载不执行
        //     if (this.is_first){
        //         this.is_first = false
        //         return
        //     }
        //     // this.$emit("input", val);
        //     this.$emit("onChange", val);
        // },
        // end 
    },
    methods: {
        emit(val) {
            val = parseInt(val) || 0
            this.$emit("onChange", parseInt(val));
        },
        // end 
        add() {
            let self = this;
            if (self.disabled) {
                clearTimeout(this.clickTimeId);
                //执行延时
                this.clickTimeId = setTimeout(function () {
                    //此处为单击事件要执行的代码
                    self.number = parseInt(self.number) + 1;
                    self.emit(self.number)
                }, 250);
            } else {
                // 处于可编辑的状态 点击直接+1
                self.number = parseInt(self.number) + 1;
                self.emit(self.number)
            }
        },
        dec() {
            let self = this;
            let num = parseInt(self.number)
            if (num > 0) {
                this.number = num - 1;
                self.emit(self.number)
            }
        },
        input() {
            let self = this;
            if (self.disabled) {
                clearTimeout(this.clickTimeId);
                this.disabled = false;
                self.$nextTick(() => {
                    // 新增
                    if (self.$refs.number) {
                        self.$refs.number.focus();
                    }
                });
            }
        },
        enter() {
            this.disabled = true;
        },
        blur() {
            let self = this;
            // console.log('blur');
            this.disabled = true;
            self.emit(self.number)
        },
    },
    mounted() {
        let self = this;
        self.number = parseInt(this.value);
    }
}
</script>
