<template>
    <div>
        <Table :columns="column" :data="data" :loading="loading" ></Table>
    </div>
</template>
<script>


import { teamLineup } from '@/api/sports/team';
import {updatePlayerV2, deletePlayer} from '@/api/sports/player_stat';

export default {
    name: "team-lineup",
    props: {
        sport_id: Number,   // 运动id
        team_id: Number,   // 战队id
        season_id: Number,   // 赛季
        match_id: Number,   // 赛季
        team: Number, // 1主 2客
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            loading: false,
            data: [],
            column: [
                {
                    title: 'id',
                    align: 'center',
                    width: 80,
                    key: 'id',
                },
                {
                    title: '球员',
                    align: 'start',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return [
                            h('Avatar', { 
                                attrs: { 
                                    src: currentRow.logo, 
                                    size: 'small'
                                },
                                class: {'team-logo': true} }
                            ),
                            h('span', currentRow.name),
                        ]
                    }
                },
                {
                    title: '出场',
                    key: 'is_present',
                    'width': 60,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        // checkbox
                        return h('Checkbox', {
                            props: {
                                value: currentRow.is_present == 1
                            },
                            on: {
                                'on-change' (value) {
                                    // 0出场 1未出场
                                    let is_present = value ? 1 : 0
                                    if (currentRow.deleted == 0){  // 没有删除的数据 可以更新
                                        self.apiUpdatePlayerV2(currentRow.id, {"is_present": is_present}, currentRow)
                                    }else{
                                        currentRow.is_present = is_present
                                    }
                                }
                            }
                        })
                    }
                },
                {
                    title: '首发',
                    key: 'first',
                    'width': 60,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        // checkbox
                        return h('Checkbox', {
                            props: {
                                value: currentRow.first == 0    // 0首发 1替补
                            },
                            on: {
                                'on-change' (value) {
                                    // 0在场 1不在场
                                    console.log("first on change", value)
                                    let first = value ? 0 : 1
                                    if (currentRow.deleted == 0){  // 没有删除的数据 可以更新
                                        self.apiUpdatePlayerV2(currentRow.id, {"first": first}, currentRow)
                                    }else{
                                        currentRow.first = first
                                    }
                                }
                            }
                        })
                    }
                },
                {
                    title: '操作',
                    key: 'opeerations',
                    align: 'center',
                    'width': 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        // return h("span", currentRow.deleted)
                        if (currentRow.deleted == 0){
                            // 存在线上 - 删除
                            return h('Button', {
                                props: {
                                    type: "error",
                                    ghost: true,
                                },
                                on: {
                                    'click': () => {
                                        if (currentRow.deleted == 0){  // 没有删除的数据 可以更新
                                            self.apiDeletePlayer(currentRow.id)
                                            // 更新当前字段
                                            currentRow.deleted = 1
                                        }
                                    }
                                },
                                
                            }, "删除")
                        }else{
                            return h('Button', {
                                props: {
                                    type: "primary",
                                    ghost: true,
                                },
                                on: {
                                    'click': () => {
                                        if (currentRow.deleted == 1){  // 没有删除的数据 可以更新
                                            self.apiUpdatePlayerV2(currentRow.id, {"is_present": currentRow.is_present, "first": currentRow.first})
                                            // 更新当前字段
                                            currentRow.deleted = 0
                                        }
                                    }
                                },
                                
                            }, "添加")
                        }
                    }
                },
            ]
        }
    },
    methods: {
        sortCols(datas){
            datas.sort( function(a,b) {
                if (a.deleted !== b.deleted ){
                    // 升序
                    return a.deleted - b.deleted;
                }
                else if (a.is_show !== b.is_show ){
                    // 升序
                    return a.is_show - b.is_show;
                } else if (a.first !== b.first){
                    // 升序
                    return a.first - b.first;
                }else if (a.is_present !== b.is_present){
                    return a.is_present - b.is_present;
                }else{
                    return
                }
            })
                
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.loading = true;
            // 搜索日期
            
            if (self.team_id > 0){
                var params = {
                    sport_id: self.sport_id,
                    team_id: self.team_id,
                    season_id: self.season_id,
                    match_id: self.match_id,
                    team: self.team,
                }
                teamLineup( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list;
                        }else{
                            this.data = []
                        }
                        self.loading = false;
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                    // 排序
                    self.sortCols(self.data)
                })
            }else{
            }
        },
        // end 
        apiUpdatePlayerV2(player_id, data_dic, currentRow){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                team: self.team,
                player_id,
                "params": data_dic,
            }
            updatePlayerV2(params).then(response => {
                if (response.data.code == 0) {
                    self.$Message.success('成功');
                    if (currentRow){
                        for (var _key in data_dic){
                            // 更新
                            currentRow[_key] = data_dic[_key]
                        }
                    }
                } else {
                    self.$Message.error(response.data.msg);
                }
            });
        },
        apiDeletePlayer(player_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                team: self.team,
                player_id,
            }
            deletePlayer(params).then(response => {
                if (response.data.code == 0) {
                    self.$Message.success('成功');
                } else {
                    self.$Message.error(response.data.msg);
                }
            });
        },


        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;
        },
    },
    mounted () {
    },
    watch: {
        'team_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>