<template>
    <div>
        <Modal
            v-model="lineup_modal"
            title="编辑阵容"
        >
            <div class="modal-div-containter">
                <TeamLineup :sport_id="sport_id" :team_id="lineup_team_id" :season_id="season_id" :match_id="match_id"
                    :team="lineup_team_side"
                ></TeamLineup>
            </div>

            <template #footer>
                <span></span>
            </template>
        </Modal>

        <div class="flex flex-wrap bg-blue-100 space-x-8 relative h-8 text-xs">
            <div class="space-x-4 h-full flex flex-wrap content-center">
                <span class="flex flex-wrap content-center ">比赛ID: <span class="text-blue-500 ">{{match_id}}</span></span>
                <span class="flex flex-wrap content-center ">比赛时间: {{ $unit.tsp2str( match.match_time ) }}</span>
                <span class=" gap-x-2 flex flex-wrap content-center  ">
                    比赛状态: <span class='text-blue-500' > {{  match.status_name }}</span>  
                    <CountDown class='text-blue-500' v-model="match"></CountDown>
                </span>
            </div>
            <div class="h-full flex flex-wrap content-center" v-show="env=='form'" >
                <span class="flex flex-wrap content-center ">数据模式[{{source_id}}] : </span>
                <Select v-model="source_id" style="width:150px" placeholder="源" @on-change	="changeSourceId">
                    <Option v-for="item in sources" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
                <Select v-model="mode" @on-change="onModeChange" style="width:100px">
                    <Option :value="MODE_AUTO" :key="MODE_AUTO" v-if="match.competition_id != 3">自动</Option>
                    <Option :value="MODE_HALF" :key="MODE_HALF">半自动</Option>
                    <Option :value="MODE_HAND" :key="MODE_HAND">全手动</Option>
                </Select>
                <Button @click="fixTimeBtn">时间修复</Button>
                <Poptip
                    confirm
                    :title="'确定使用源'+source_id+'的数据替换到线上'"
                    @on-ok="transferSourceToLine"
                >
                    <Button>源{{source_id}}->线上</Button>
                </Poptip>
            </div>
            <div class="absolute right-0 space-x-2 h-full flex flex-wrap content-center" >
                <Input placeholder="搜索选手" style="width: auto" v-model="search_key" @on-change='filterName' clearable>
                    <Icon type="ios-search" slot="prefix" />
                </Input>
                <RadioGroup v-model="team" type="button">
                    <Radio :label="0">
                        <Icon type="md-menu" />
                    </Radio>
                    <Radio :label="1">主</Radio>
                    <Radio :label="2">客</Radio>
                </RadioGroup>
            </div>
        </div>
        <!-- <Divider /> -->
        <i-table :height='team===0? 400 : null' v-show="[0, 1].indexOf(team) > -1" row-key="row_id" 
            :indent-size="0"  size='small' border 
            :columns="home_columns" :data="tables.home_datas" :loading="loading" 
            @on-expand-tree="onChildExpand" @on-sort-change="onHomeSortChange"></i-table>
        <i-table :height='team===0? 400 : null' v-show="[0, 2].indexOf(team) > -1" row-key="row_id" 
            :indent-size="0"  size='small' border 
            :columns="away_columns" :data="tables.away_datas" :loading="loading"
            @on-expand-tree="onChildExpand" @on-sort-change="onAwaySortChange"></i-table>
    </div>
</template>

<script>

import { getMatch, getPlayers, source2Line, updatePlayerV2, updatePlayerStatMode, 
    getPlayerStatSources, updateMatchSource, addFixMatch } from '@/api/sports/player_stat';
import CountDown from '@/components/basic/count_down.vue';
import NumberEditor from '@/components/basic/number_editor.vue';
import Client from '@/utils/mqtt/mqtt-pro-new';
import { json } from 'body-parser';
import TeamLineup from "./team_lineup.vue";

export default {
    data () {
        let self = (window.x = this)
        return {
            MODE_AUTO: 0,
            MODE_HALF: 2,
            MODE_HAND: 1,
            env: 'form',
            sport_id: 0,
            match_id: 0,
            season_id: 0,
            mode: 0,  // 0自动 1手动 半自动是2
            team: 0,  // 0全部 1主 2客
            search_key: '',
            match: {},
            data: {
                home: {},
                away: {},
            },
            tables: {
                home_datas: [],
                away_datas: [],
            },
            loading: true,
            source_id: 0,
            sources: [],
            home_columns: [],
            away_columns: [],
            // columns: []
            show_children: {},    // 记录展开或者关闭记录
            home_time_sort : 0, // 0不按照时间排序 1按照时间倒序 2按照时间正序
            away_time_sort : 0, // 0不按照时间排序 1按照时间倒序 2按照时间正序

            // 战队阵容
            lineup_modal: false,
            lineup_team_id: 0,
            lineup_team_side: 0,    // 1主 2客

            // 源数据表格的样式
            source_col_classnames : {},
            //
            mqtt_client: null,
        }
    },
    components: {
        CountDown,
        NumberEditor,
        TeamLineup,
    },
    methods: {
        // 生成主客队的头部
        createColumnHeader(team, team_side){
            let self = this;
            return [{
                key: 'ID',
                width: 200,
                align: 'left',
                tree: true,
                fixed: 'left',
                renderHeader: (h, params) => {
                    var header_team_infos = [
                        // h('span', {class: 'text-blue-500'}, (team? team.id: '') || ''),
                        h('Avatar', {
                            props: {
                                src: team ? self.$unit.getImgUrl(self.sport_id, 'team', team.logo): '',
                            }
                        }),
                        h('span', team ? team.name: ''),
                    ]
                    if (self.mode == self.MODE_HAND || self.mode == self.MODE_HALF){
                        // 新增一个编辑的图标
                        header_team_infos.push(
                            h('Button', {
                                props: {
                                    type: "error",
                                    ghost: true,
                                    icon: "md-create",
                                },
                                on: {
                                    'click': () => {
                                        // 展示球队所有选手阵容
                                        // console.log(team, "clicked")
                                        if (team && team.id){
                                            self.lineup_team_id = team.id;
                                            self.lineup_team_side = team_side;  // 1主 2客
                                            self.lineup_modal = true;
                                        }else{
                                            self.$Message.error("战队为空")
                                        }
                                    }
                                }
                            }),
                        )
                        header_team_infos.push(
                            h("span", {
                                class: "absolute right-1",
                            },
                            "首发")
                        )
                    }
                    return  h('div', 
                        {
                            class: "flex items-center gap-x-1",
                        },
                        header_team_infos
                    )
                },
                render: function (h, params) {
                    let currentRow = params.row;
                    let first = currentRow.first;
                    let first_text = first===0?'首':(first===1?'补': ""  );
                    let class_name = first===0?'bg-red-500':(first===1?'bg-green-500': "" );
                    // 选手名字 截取
                    let player = currentRow.player;
                    let player_name = player ? player.name_zh: '';
                    if (player_name.length > 9){
                        player_name = player_name.substr(0, 9) + '..'
                    }
                    if (currentRow.category != 1){
                        if (currentRow.class_type){
                            // 来自于源的数据
                            return h('span', {class: 'flex-nowrap truncate '}, 
                                [
                                    h('span', {class: 'flex-nowrap mr-1'}, player_name),
                                    h('span', {class: 'flex-nowrap absolute right-0 mr-3'}, first_text),
                                ]
                            )
                        }else{
                            // 线上数据 ID 名称 头像
                            let header_params = [
                                h('span', {class: 'text-blue-500'}, (player? player.shirt_number: '-') || '-'),
                                h('Avatar', {
                                    props: {
                                        src: currentRow.player ? self.$unit.getImgUrl(self.sport_id, 'player', currentRow.player.logo): '',
                                    }
                                }),
                                h('span', player_name),
                                // 
                            ]
                            if (self.mode == self.MODE_HAND){
                                header_params.push(
                                    h('Checkbox', {
                                        class: "absolute right-0",
                                        props: {
                                            value: currentRow.first == 0   // 0首发 1替补
                                        },
                                        on: {
                                            'on-change' (value) {
                                                // 0首发 1替补
                                                let first = value ? 0 : 1
                                                self.apiUpdatePlayerV2(team_side, currentRow.id, {"first": first})
                                            }
                                        }
                                    })
                                )
                            }else{
                                header_params.push(
                                    h('Badge', {
                                        class: "absolute right-0",
                                        attrs: {
                                            text: first_text,
                                            'class-name': class_name,
                                        },
                                    },)
                                )
                            }
                            return header_params
                        }
                    }else{
                        // 战队
                        var header_team_infos = [
                            h('Avatar', {
                                props: {
                                    src: team ? self.$unit.getImgUrl(self.sport_id, 'team', team.logo): '',
                                }
                            }),
                            // h('span', {class: 'text-blue-500'}, '队伍'),
                            h('span', {class: 'text-blue-500'}, team ? team.name: ''),
                        ]
                        return header_team_infos
                    }
                },
                filterRemote: (value, row) => {
                    self.filterName()
                }
            }] 
        },

        createColumnBody(team_side){
            let self = this;
            return [
                {
                    title: '场上',
                    key: 'is_show',
                    'width': 60,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        if (currentRow.category != 1){
                            // 选手
                            if (currentRow.class_type === 1 ||  self.mode === self.MODE_AUTO){
                                return h('span', currentRow.is_show === 0? '在': '')
                            }else {
                                // checkbox
                                return h('Checkbox', {
                                    props: {
                                        value: currentRow.is_show == 0
                                    },
                                    on: {
                                        'on-change' (value) {
                                            // 0在场 1不在场
                                            let is_show = value ? 0 : 1
                                            self.apiUpdatePlayerV2(team_side, currentRow.id, {"is_show": is_show})
                                        }
                                    }
                                })
                            }
                        }
                    }
                },
                {
                    title: '出场',
                    key: 'is_present',
                    'width': 60,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        if (currentRow.category != 1){
                            // 选手
                            if (currentRow.class_type === 1 ||  self.mode === self.MODE_AUTO){
                                return h('span', currentRow.is_present === 1? '是': '')
                            }else {
                                // checkbox
                                if (self.mode==self.MODE_HAND){
                                    return h('Checkbox', {
                                        props: {
                                            value: currentRow.is_present == 1
                                        },
                                        on: {
                                            'on-change' (value) {
                                                // 1出场 0未出场
                                                let is_present = value ? 1 : 0
                                                self.apiUpdatePlayerV2(team_side, currentRow.id, {"is_present": is_present})
                                            }
                                        }
                                    })
                                }else{
                                    return h('span', currentRow.is_present === 1? '是': '')
                                }
                            }
                        }
                    }
                },
                {
                    title: '时间',
                    key: 'minutes_played',
                    'width': 100,
                    align: 'center',
                    sortable: 'custom',
                    render: function (h, params) {
                        let currentRow = params.row;
                        if (currentRow.category != 1){
                            // 选手
                            if (currentRow.class_type === 1){
                                return h('span', currentRow.minutes_played)
                            }else {
                                // input number
                                if (self.mode==self.MODE_HAND){
                                    return h('div', 
                                        {
                                            class: "flex items-start gap-x-1",
                                        },
                                        [
                                            // h('span', { class: 'whitespace-nowrap  w-18', }, currentRow.minutes_played || 0),
                                            h('InputNumber', 
                                                {
                                                    props: {
                                                        'value': currentRow.minutes_played ,
                                                        "active-change": false,
                                                    },
                                                    on: {
                                                        'on-change' (value) {
                                                            // console.log(value)
                                                            self.apiUpdatePlayerV2(team_side, currentRow.id, {"minutes_played": value})
                                                        }
                                                    }
                                                }
                                            )
                                        ])
                                }else{
                                    return h('span', currentRow.minutes_played)
                                }
                            }
                        }
                    }
                },
                {
                    title: '得分',
                    key: 'points',
                    'width': 60,
                    align: 'center',
                },
                {
                    title: '投篮',
                    key: 'field_goals',
                    'width': 70,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h('span', (currentRow.field_goals_scored || 0) +'/'+ (currentRow.field_goals_total || 0) )
                    }
                },
                {
                    title: '两分(中/未)',
                    key: 'two_points',
                    'minWidth': 120,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var editable = true
                        if (currentRow.class_type === 1 ||  self.mode === self.MODE_AUTO){
                            editable = false
                        }else if (currentRow.category === 1 && self.mode === self.MODE_HALF){
                            editable = false
                        }
                        if (! editable){
                            return [
                                h('span', currentRow.two_points_total || 0 ),
                                h('Divider', {props: {type:"vertical"}}),
                                h('span', currentRow.two_points_scored || 0),
                                h('span', '/'),
                                h('span', currentRow.two_points_unscored || 0),
                            ]
                        }else {
                            return h('div', 
                            {
                                class: "flex items-center gap-x-1",
                            },
                            [
                                h('span', { class: 'text-center whitespace-nowrap', }, currentRow.two_points_total || 0),
                                h(NumberEditor, {
                                        props: {
                                            'value': currentRow.two_points_scored,
                                        },
                                        on: {
                                            'onChange': (val) => {
                                                self.apiUpdatePlayerV2(team_side, currentRow.id, {'two_points_scored': val}, currentRow)
                                            }
                                        }
                                    }),
                                h(NumberEditor, {
                                        props: {
                                            'value': currentRow.two_points_unscored,
                                        },
                                        on: {
                                            'onChange': (val) => {
                                                self.apiUpdatePlayerV2(team_side, currentRow.id, {'two_points_unscored': val}, currentRow)
                                            }
                                        }
                                    }),
                            ])
                        }
                        
                    }
                },
                {
                    title: '三分(中/未)',
                    key: 'three_points',
                    'minWidth': 120,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        // let scored = currentRow.three_points_scored || 0;
                        // let total = currentRow.three_points_total || 0;
                        // let unscored = currentRow.three_points_unscored || 0;
                        // let score_rate = total > 0 ? parseInt((scored/total) * 100) + '%' : '' 
                        var editable = true
                        if (currentRow.class_type === 1 || self.mode === self.MODE_AUTO){
                            editable = false
                        }else if (currentRow.category === 1 && self.mode === self.MODE_HALF){
                            // 战队特殊处理
                            editable = false
                        }
                        if (! editable){
                            return [
                                // h('span', score_rate? total + " ("+ score_rate +")": total),
                                h('span', currentRow.three_points_total || 0 ),
                                h('Divider', {props: {type:"vertical"}}),
                                h('span', currentRow.three_points_scored || 0),
                                h('span', '/'),
                                h('span', currentRow.three_points_unscored || 0),
                            ]
                        }else{
                            return h('div', 
                                {
                                    class: "flex items-center gap-x-1",
                                },
                                [
                                    h('span', { class: 'whitespace-nowrap  w-18', }, currentRow.three_points_total || 0),
                                    h(NumberEditor, {
                                            props: {
                                                'value': currentRow.three_points_scored,
                                            },
                                            on: {
                                                'onChange': (val) => {
                                                    self.apiUpdatePlayerV2(team_side, currentRow.id, {'three_points_scored': val}, currentRow)
                                                }
                                            }
                                        }),
                                    // h('span', '/'),
                                    // h('span', unscored),
                                    h(NumberEditor, {
                                        props: {
                                            'value': currentRow.three_points_unscored,
                                        },
                                        on: {
                                            'onChange': (val) => {
                                                self.apiUpdatePlayerV2(team_side, currentRow.id, {'three_points_unscored': val}, currentRow)
                                            }
                                        }
                                    }),
                            ])
                        }
                    }
                },
                {
                    title: '罚球(中/未)',
                    key: 'free_throws',
                    'minWidth': 120,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        // let scored = currentRow.free_throws_scored || 0;
                        // let total = currentRow.free_throws_total || 0;
                        // let unscored = currentRow.free_throws_unscored || 0;
                        // let score_rate = total > 0 ? parseInt((scored/total) * 100) + '%' : '' 
                        var editable = true
                        if (currentRow.class_type === 1 || self.mode === self.MODE_AUTO){
                            editable = false
                        }else if (currentRow.category === 1 && self.mode === self.MODE_HALF){
                            editable = false
                        }
                        if (! editable){
                            return [
                                // h('span', score_rate? total + " ("+ score_rate +")": total),
                                h('span', currentRow.free_throws_total || 0 ),
                                h('Divider', {props: {type:"vertical"}}),
                                h('span', currentRow.free_throws_scored || 0),
                                h('span', '/'),
                                h('span', currentRow.free_throws_unscored || 0),
                            ]
                        }else{
                            return h('div', 
                                {
                                    class: "flex items-center gap-x-1",
                                },
                                [
                                    h('span', { class: 'whitespace-nowrap  w-18', }, currentRow.free_throws_total || 0),
                                    h(NumberEditor, {
                                            props: {
                                                'value': currentRow.free_throws_scored,
                                            },
                                            on: {
                                                'onChange': (val) => {
                                                    self.apiUpdatePlayerV2(team_side, currentRow.id, {'free_throws_scored': val}, currentRow)
                                                }
                                            }
                                        }),
                                    // h('span', '/'),
                                    // h('span', unscored),
                                    h(NumberEditor, {
                                        props: {
                                            'value': currentRow.free_throws_unscored,
                                        },
                                        on: {
                                            'onChange': (val) => {
                                                self.apiUpdatePlayerV2(team_side, currentRow.id, {'free_throws_unscored': val}, currentRow)
                                            }
                                        }
                                    }),
                            ])
                        }
                    }
                },
                {
                    title: '前篮板/后篮板',
                    key: 'rebounds',
                    'minWidth': 120,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var editable = true
                        if (currentRow.class_type === 1 || self.mode === self.MODE_AUTO){
                            editable = false
                        }else if (currentRow.category === 1 && self.mode === self.MODE_HALF){
                            editable = false
                        }
                        if (! editable){
                            return [
                                h('span', currentRow.rebounds),
                                h('Divider', {props: {type:"vertical"}}),
                                h('span', currentRow.offensive_rebounds),
                                h('span', '/'),
                                h('span', currentRow.defensive_rebounds),
                            ]
                        }else{
                            return h('div', 
                                {
                                    class: "flex items-center gap-x-1",
                                },
                                [
                                    h('span', { class: 'whitespace-nowrap  w-18', }, currentRow.rebounds),
                                    h(NumberEditor, {
                                            props: {
                                                'value': currentRow.offensive_rebounds,
                                            },
                                            on: {
                                                'onChange': (val) => {
                                                    self.apiUpdatePlayerV2(team_side, currentRow.id, {'offensive_rebounds': val}, currentRow)
                                                }
                                            }
                                        }),
                                    // h('span', '/'),
                                    // h('span', unscored),
                                    h(NumberEditor, {
                                        props: {
                                            'value': currentRow.defensive_rebounds,
                                        },
                                        on: {
                                            'onChange': (val) => {
                                                self.apiUpdatePlayerV2(team_side, currentRow.id, {'defensive_rebounds': val}, currentRow)
                                            }
                                        }
                                    }),
                            ])
                        }
                    }
                },
                {
                    title: '助攻',
                    key: 'assists',
                    'width': 90,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var editable = true
                        if (currentRow.class_type === 1 || self.mode === self.MODE_AUTO){
                            editable = false
                        }else if (currentRow.category === 1 && self.mode === self.MODE_HALF){
                            editable = false
                        }
                        if (! editable){
                            return h('span', currentRow.assists)
                        }else{
                            return [h(NumberEditor, {
                                props: {
                                    'value': currentRow.assists,
                                },
                                on: {
                                    'onChange': (val) => {
                                        // console.log("OnChange: ", val)
                                        self.apiUpdatePlayerV2(team_side, currentRow.id, {'assists': val})
                                    },
                                }
                            }),
                            // h('span', currentRow.assists)
                            ]
                        }
                    }
                },
                {
                    title: '盖帽',
                    key: 'blocks',
                    'width': 90,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var editable = true
                        if (currentRow.class_type === 1 || self.mode === self.MODE_AUTO){
                            editable = false
                        }else if (currentRow.category === 1 && self.mode === self.MODE_HALF){
                            editable = false
                        }
                        if (! editable){
                            return h('span', currentRow.blocks)
                        }else{
                            return h(NumberEditor, {
                                props: {
                                    'value': currentRow.blocks,
                                },
                                on: {
                                    'onChange': (val) => {
                                        self.apiUpdatePlayerV2(team_side, currentRow.id, {'blocks': val})
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    title: '犯规',
                    key: 'personal_fouls',
                    'width': 90,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var editable = true
                        if (currentRow.class_type === 1 || self.mode === self.MODE_AUTO){
                            editable = false
                        }else if (currentRow.category === 1 && self.mode === self.MODE_HALF){
                            editable = false
                        }
                        if (! editable){
                            return h('span', currentRow.personal_fouls)
                        }else{
                            return h(NumberEditor, {
                                props: {
                                    'value': currentRow.personal_fouls,
                                },
                                on: {
                                    'onChange': (val) => {
                                        self.apiUpdatePlayerV2(team_side, currentRow.id, {'personal_fouls': val})
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    title: '抢断',
                    key: 'steals',
                    'width': 90,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var editable = true
                        if (currentRow.class_type === 1 || self.mode === self.MODE_AUTO){
                            editable = false
                        }else if (currentRow.category === 1 && self.mode === self.MODE_HALF){
                            editable = false
                        }
                        if (! editable){
                            return h('span', currentRow.steals)
                        }else{
                            return h(NumberEditor, {
                                props: {
                                    'value': currentRow.steals,
                                },
                                on: {
                                    'onChange': (val) => {
                                        self.apiUpdatePlayerV2(team_side, currentRow.id, {'steals': val})
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    title: '失误',
                    key: 'turnovers',
                    'width': 90,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var editable = true
                        if (currentRow.class_type === 1 || self.mode === self.MODE_AUTO){
                            editable = false
                        }else if (currentRow.category === 1 && self.mode === self.MODE_HALF){
                            editable = false
                        }
                        if (! editable){
                            return h('span', currentRow.turnovers)
                        }else{
                            return h(NumberEditor, {
                                props: {
                                    'value': currentRow.turnovers,
                                },
                                on: {
                                    'onChange': (val) => {
                                        self.apiUpdatePlayerV2(team_side, currentRow.id, {'turnovers': val})
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    title: '+/-',
                    key: 'plus_minus',
                    'width': 100,
                    align: 'center',
                    render: function (h, params) {
                        let currentRow = params.row;
                        if (currentRow.class_type === 1){
                            return h('span', currentRow.plus_minus)
                        }else {
                            // input number
                            if (self.mode==self.MODE_HAND){
                                return h('div', 
                                    {
                                        class: "flex items-start gap-x-1",
                                    },
                                    [
                                        // h('span', { class: 'whitespace-nowrap  w-18', }, currentRow.plus_minus || 0),
                                        h('InputNumber', 
                                            {
                                                props: {
                                                    'value':  parseInt( currentRow.plus_minus ),
                                                    "active-change": false,
                                                },
                                                on: {
                                                    'on-change' (value) {
                                                        // console.log(value)
                                                        self.apiUpdatePlayerV2(team_side, currentRow.id, {"plus_minus": value})
                                                    }
                                                }
                                            }
                                        )
                                    ])
                            }else{
                                return h('span', currentRow.plus_minus)
                            }
                        }
                    }
                },
            ]
        },
        // 生成源数据表格的样式
        init_source_cell_classes(){
            let self = this; 
            let source_classes = 'table-source-col'
            var source_col_classnames = {
                ID: source_classes,
            }
            let body_columns = self.createColumnBody(0)
            body_columns.forEach(element => {
                source_col_classnames[element.key] = source_classes
            });
            // console.log(source_col_classnames)
            this.source_col_classnames = source_col_classnames
        },
        // end
        playerFilted(player){
            let self = this;
            // console.log(player, self.search_key)
            // 判断选手是否经过筛选
            if (! self.search_key){
                return true
            }
            // id
            if (player.id && player.id == parseInt(self.search_key)){
                return true
            }else if ( player.name_zh && player.name_zh.indexOf(self.search_key) > -1 ){
                return true
            }else if ( player.name_en && player.name_en.indexOf(self.search_key) > -1 ){
                return true
            }else if ( player.short_name_zh && player.short_name_zh.indexOf(self.search_key) > -1 ){
                return true
            }else if ( player.short_name_en && player.short_name_en.indexOf(self.search_key) > -1 ){
                return true
            }else if ( player.shirt_number && player.shirt_number ==  parseInt(self.search_key) ){
                return true
            }
            return false
        },
        
        filterName(evt){
            let self = this;
            self.loading = true;
            self.$unit.debounce( () => {
                self.createNewTableDatas(0);
                self.loading = false;
            }, 300, 'createNewTableDatas' )
        },
        // end
        // 获取全部第三方源
        apiGetSources(){
            let self = this;
            var params = {
                sport_id: self.sport_id,
            }
            getPlayerStatSources(params).then(response => {
                if (response.data.code == 0) {
                    self.sources = response.data.data.list;
                    // console.log(self.sources);
                } else {
                    self.$Message.error(response.data.msg);
                }
            });
        },

        changeSourceId(value){
            // console.log("changeSourceId: ", value)
            let self = this;
            var params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                source_id: value,
                mode: self.mode,
            }
            updateMatchSource(params).then(response => {
                // 重新获取当前的文字直播列表
                // self.$set(self.nami_text_lives, {} );
                if (response.data.code == 0) {
                    // 重新获取线上数据
                    self.apiGetAllPlayer()
                    this.$Message.success('源更新成功');
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        fixTimeBtn(){
            let self = this;
            var params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
            }
            addFixMatch(params).then(response => {
                // 重新获取当前的文字直播列表
                // self.$set(self.nami_text_lives, {} );
                if (response.data.code == 0) {
                    // 重新获取线上数据
                    self.apiGetAllPlayer()
                    this.$Message.success('提醒成功');
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        transferSourceToLine(){
            let self = this;
            var params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                source_id: self.source_id,
            }
            source2Line(params).then(response => {
                if (response.data.code == 0) {
                    // 重新获取线上数据
                    self.apiGetAllPlayer()
                    this.$Message.success('替换成功');
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        onModeChange(){
            let self = this;
            // console.log(self.mode)
            let params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                mode: self.mode,
                source_id: self.source_id,
            }
            updatePlayerStatMode(params).then(response => {
                if (response.data.code == 0) {
                    // self.$Message.success('成功');
                    if (self.mode == self.MODE_HALF){
                        self.createNewTableDatas(0)
                    }
                } else {
                    self.$Message.error(response.data.msg);
                }
            });
        },
        // end
        onChildExpand(row_id, status){
            // 当展开子选项时候
            let self = this;
            self.show_children[row_id] = status;
        },
        // end
        

        createTableColumns(){
            let self = this;
            self.home_columns = self.createColumnHeader(self.match.home, 1)
            self.away_columns = self.createColumnHeader(self.match.away, 2) 
            self.home_columns.push.apply(self.home_columns, self.createColumnBody(1))
            self.away_columns.push.apply(self.away_columns, self.createColumnBody(2))
        },

        // 生成主客队的数据
        initTableDatas( data_team, time_sort ){
            let self = this;
            var datas = [];
            if (!data_team){
                return datas
            }
            let line = data_team.line || {}
            let source = data_team.source || {}
            
            var enriched_player_ids = [];
            // var updated_line_rows = [];
            for (var player_id in source){
                let source_data = source[player_id]
                // if (!parseInt(player_id)){
                //     console.log(player_id, line[player_id], source_data)
                // }
                enriched_player_ids.push(player_id);
                var line_data = line[player_id] || {"id": player_id, category:source_data.category , player: source_data.player};
                // if (!parseInt(player_id)){
                //     console.log(2, line_data)
                // }
                source_data['row_id'] = "s-" + player_id
                source_data['class_type'] = 1
                source_data['cellClassName'] = self.source_col_classnames;
                line_data['row_id'] = player_id
                if (self.show_children.hasOwnProperty(player_id)){
                    line_data['_showChildren'] = self.show_children[player_id] 
                }else{
                    line_data['_showChildren'] = true // 展开
                }
                if (self.env == 'form'){
                    line_data['children'] = [ source_data ]
                }
                // if (!parseInt(player_id)){
                //     console.log(3, line_data)
                // }
                // 判断选手经过筛选
                let player = line_data.player
                // if (!parseInt(player_id)){
                //     console.log(player_id, player, self.playerFilted(player))
                // }
                if (player && self.playerFilted(player)){
                    datas.push( line_data )
                }
            }
            // 线上存在而源不存在
            for (var player_id in line){
                if (enriched_player_ids.indexOf(player_id) === -1){
                    line[player_id]['row_id'] = player_id
                    line[player_id]['children'] = [ {"row_id": "s-" + player_id, 'class_type': 1, cellClassName: self.source_col_classnames} ]
                    // 判断选手经过筛选
                    let player = line[player_id].player
                    if (self.playerFilted(player)){
                        datas.push( line[player_id] )
                    }
                }
            }
            self.sortCols(datas, time_sort)
            // console.log("datas: ",  datas);
            return datas;
        },

        sortCols(datas, time_sort){
            if (! time_sort){
                datas.sort( function(a,b) {
                    if ((a.category || 0) !== (b.category || 0) ){
                        // 升序
                        return (b.category || 0) - (a.category|| 0);
                    } else if (a.is_show !== b.is_show ){
                        // 升序
                        return a.is_show - b.is_show;
                    } else if (a.first !== b.first){
                        // 降序
                        return a.first - b.first;
                    }else{
                        return
                    }
                })
            }else{
                datas.sort( function(a,b) {
                    if ((a.category || 0) !== (b.category || 0) ){
                        // 升序
                        return (b.category || 0) - (a.category|| 0);
                    } else if (a.is_show !== b.is_show ){
                        // 升序
                        return a.is_show - b.is_show;
                    } else if (a.minutes_played !== b.minutes_played){
                        if (time_sort == 2){
                            // 降序
                            return a.minutes_played - b.minutes_played;
                        }
                        else{ 
                            return b.minutes_played - a.minutes_played;
                        }
                    }else{
                        return
                    }
                })
            }
        },

        createNewTableDatas(team){
            let self = this;
            if (team === 1){
                self.$set(self.tables, 'home_datas', self.initTableDatas( self.data.home, self.home_time_sort ))  //.splice(0, parseInt(home_datas.length/2)))
            }else if (team === 2){
                self.$set(self.tables, 'away_datas', self.initTableDatas( self.data.away, self.away_time_sort ))
            }else{
                self.$set(self.tables, 'home_datas', self.initTableDatas( self.data.home, self.home_time_sort ))
                self.$set(self.tables, 'away_datas', self.initTableDatas( self.data.away, self.away_time_sort ))
            }
            // 使用强制更新
            self.$forceUpdate()
        },
        // end 

        apiGetAllPlayer(){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                team: self.team,
                home_id: self.match.home.id,
                away_id: self.match.away.id,
                source: self.source_id,
            }
            getPlayers(params).then(response => {
                if (response.data.code == 0) {
                    self.data = response.data.data
                    // 获取战队的表格数据
                    self.createNewTableDatas(0);
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        apiGetMatch(){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
            }
            getMatch(params).then(response => {
                if (response.data.code == 0) {
                    self.mode = response.data.mode;
                    self.match = response.data.data;
                    self.source_id = self.match.source_id;
                    self.season_id = self.match.season_id;
                    // 获取战队的表格数据
                    self.createTableColumns()  // 生成标题
                    // 合并列 (线上和源)
                    // 请求技术统计数据
                    self.apiGetAllPlayer();
                } else {
                    self.$Message.error(response.data.msg);
                }
            });
        },
        // end

        apiUpdatePlayerV2(team, player_id, data_dic, currentRow){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                team: team,
                player_id,
                "params": data_dic,
            }
            updatePlayerV2(params).then(response => {
                if (response.data.code == 0) {
                    // return
                    // 保险措施 - 防止长连接数据没有收到的时候 所以前端自动变更下数据
                    if (currentRow){
                        for (var _key in data_dic){
                            // 更新
                            currentRow[_key] = data_dic[_key]
                        }
                        self.ruleCalcNewRow(currentRow) // 
                    }
                } else {
                    self.$Message.error(response.data.msg);
                }
            });
        },

        ruleCalcNewRow( row ){
            // 重新计算一个新的row
            let three_points_scored = parseInt( row.three_points_scored) || 0
            let two_points_scored = parseInt(row.two_points_scored) || 0
            let free_throws_scored = parseInt(row.free_throws_scored) || 0
            let three_points_unscored = parseInt(row.three_points_unscored) || 0
            let two_points_unscored = parseInt(row.two_points_unscored) || 0
            let free_throws_unscored = parseInt(row.free_throws_unscored) || 0
            // 投篮次数
            row.three_points_total = three_points_scored + three_points_unscored
            row.two_points_total = two_points_scored + two_points_unscored
            row.free_throws_total = free_throws_scored + free_throws_unscored
            // 1 总得分
            row.points = three_points_scored*3 + two_points_scored*2 + free_throws_scored*1
            // 2 投篮次数
            row.field_goals_total = row.three_points_total + row.two_points_total
            // 3 投篮命中次数
            row.field_goals_scored = three_points_scored + two_points_scored
            // 4 总篮板
            let offensive_rebounds = parseInt(row.offensive_rebounds) || 0
            let defensive_rebounds = parseInt(row.defensive_rebounds) || 0
            row.rebounds = offensive_rebounds + defensive_rebounds
            // 时间/首发 从源获取

        },
        // end
        // 获取文字直播
        onHomeSortChange(column){
            let self = this;
            let order = column.order;
            // 主队数据排序
            if (order == 'asc'){ self.home_time_sort = 2}
            else if (order == 'desc'){ self.home_time_sort = 1}
            else{ self.home_time_sort = 0}
            // 排序主队数据
            self.sortCols(self.tables.home_datas, self.home_time_sort)
        },
        onAwaySortChange(column){
            let self = this;
            let order = column.order;
            // 主队数据排序
            if (order == 'asc'){ self.away_time_sort = 2}
            else if (order == 'desc'){ self.away_time_sort = 1}
            else{ self.away_time_sort = 0}
            // 排序主队数据
            self.sortCols(self.tables.away_datas, self.away_time_sort)
        },
        //end
        // 长连接
        socketConnetc (topic) {
            function split_player_type2( row ){
                let player = {
                    "id": row.id,
                    name_zh: row.name_zh,
                    name_en: row.name_en,
                    short_name_zh: row.short_name_zh,
                    short_name_en: row.short_name_en,
                    logo: row.logo,
                    shirt_number: row.shirt_number,
                    s: row.s
                }
                for (var _key in player){
                    if (_key !== 'id'){
                        delete row[_key]
                    }
                }
                row.player = player
            }

            let self = this;
            // console.log("执行connect方法")
            self.mqtt_client = new Client({
                topic: `${topic}`,
                message (t, msg) {
                    // console.log("mqtt msg")
                    if (msg.type === 1 ){
                        // 比赛比分等基础数据
                        let msgData = msg.data;
                        // console.log(msgData);
                        // 更新比赛状态 和 走表倒计时时间
                        self.match.status_id = msgData.status_id 
                        self.match.status_name = msgData.status_name 
                        self.match.time_left = msgData.time_left 
                        self.match.time_left_zoubiao = msgData.time_left_zoubiao 
                    }else if (msg.type===2){
                        // 线上选手技术统计数据
                        if (process.env.NODE_ENV === 'development'){
                            console.log("2: msg.data:", msg.data );
                            // return // todo
                        }
                        if (! self.data.home){
                            self.data.home = {}
                        }
                        if (! self.data.away){
                            self.data.away = {}
                        }
                        // 线上选手
                        let msgData = msg.data;
                        let new_home_players = msgData.home_players || [];
                        let new_away_players = msgData.away_players || [];
                        // 因为长连接里的选手字段不存在 players 扁平化到同一个层级 需要拆分出来
                        let home_line = {}
                        let away_line = {}
                        new_home_players.forEach(element => {
                            split_player_type2( element )
                            home_line[element.id] = element
                        })
                        new_away_players.forEach(element => {
                            split_player_type2( element )
                            away_line[element.id] = element
                        })
                        // console.log("set line home", home_line)
                        self.$set(self.data.home, 'line', home_line)
                        // console.log("set line away", away_line)
                        self.$set(self.data.away, 'line', away_line)
                        // self.data.home.line = home_line
                        // self.data.away.line = away_line
                        // 重新生成数据
                        self.createNewTableDatas(0);  // 线上数据 不再同步源到线上
                    }else if (msg.type === 5){
                        // 来自于源的技术统计数据
                        if (! self.data.home){
                            self.data.home = {}
                        }
                        if (! self.data.away){
                            self.data.away = {}
                        }
                        let msgData = msg.data;
                        if (process.env.NODE_ENV === 'development'){
                            console.log("5: Source.data:", msgData);
                        }
                        self.$set(self.data.home, 'source', msgData.home.source || {})
                        self.$set(self.data.away, 'source', msgData.away.source || {})
                        self.createNewTableDatas(0);  // 更新时间比分等
                    }
                },
                connect(){
                },
            })
        },
        // end 
    },
    
    // end
    mounted () {
        let self = this;
        self.init_source_cell_classes();

        this.sport_id = parseInt( this.$route.query.sport_id );
        this.match_id = parseInt( this.$route.query.match_id );
        let env = self.$route.query.env;
        if (env){
            self.env = env
        }
        // console.log(self.env);
        self.apiGetSources();
        self.apiGetMatch();
        // self.apiGetPlayerStat( 0);
        // self.apiGetPlayerStat( self.source_id);
        self.loading = false;
        document.onkeydown = function(e) {
            let key = window.event.keyCode;
            if (key == 9){
                // Tab 切换
                window.event.preventDefault() //关闭浏览器快捷键
                if (self.team == 2){
                    self.team = 0
                }else{
                    self.team += 1
                }
            }else{}
        };
        // 长连接
        var topic = "namicms/match_detail/"+ this.sport_id + "_" + this.match_id;
        this.socketConnetc(topic); //
    },
    created () {
    },
    watch: {
        // end
    },
    computed: {
    }
}
</script>
<style scoped>
    .team-name {
        margin: 10px;
    }
   
</style>
<style >
    .ivu-table .table-source-col{
        background-color: #FBEDED;
        /* color: #000; */
        height: 8px;
    }
</style>
