<template>
    <!-- <SlickList
        :lockToContainerEdges="true"
        axis="y"
        lockAxis="y"
        v-model="items"
        class="SortableList"
        @input="getChangeLists"
        helperClass="moving-item"
        :distance="-1"
        :pressDelay = 200
    ></SlickList> -->
    <div>
        <Row :gutter="16" v-for="(_, index) in items || []"
            :index="index" :key="index">
            <Col span="6">
                <span :style="'color: '+ items[index] ">{{ items[index] }}</span>
            </Col>
            <Col span="6">
                <ColorPicker v-model="items[index]" />
            </Col>
            <Col span="6">
                <Icon type="ios-trash-outline" size="20" @click="doRemove(index)"></Icon>
            </Col>
            
        </Row>
        <Button shape="circle" icon="ios-add" @click="doAdd()" ></Button>
    </div>
</template>

<script>
import { Row } from 'view-design';


// import { SlickList, SlickItem } from 'vue-slicksort';  // 引入

export default {
    name: 'colors-dragable',
    components: {
        // SlickItem,
        // SlickList,
    },
    props: ['value'],
    data () {
        return {
            items: [],
        }
    },
    watch:{
        value(val){
            // console.log("value changed", val);
            this.items=val;
        }
    },
    mounted(){
        // console.log("1 mounted")
    },
    methods: {
        updateValue(value){
            this.$emit('input', value);
        },
        getChangeLists(vals) {
            this.updateValue(vals)
        },
        // end
        doRemove(index){
            // 删除英雄
            let self = this;
            // console.log(index);
            self.items.splice(index, 1);
            self.updateValue(self.items);
        },
        doAdd(){
            let self = this;
            self.items.push("#ffffff");
            self.updateValue(self.items);
        },
        // end 
    }
}
</script>

