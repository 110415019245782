import http from '@/utils/http';

// 搜索
export const searchPromotion = (params) => {
    return http.request({
        url: '/v1/ballsports/base/promotion/search',
        method: 'post',
        data: params
    })
}
