<template>
    <div>
        <Modal
            v-model="modal_add"
            title="新增选手"
            @on-ok="modal_add_on_ok"
        >
            <Form label-position="left" :label-width="60">
                <FormItem label="战队: ">
                    <Select v-model="new_player.side" >
                        <Option :value="1" :key="1">主队</Option>
                        <Option :value="2" :key="2">客队</Option>
                    </Select>
                </FormItem>
                <FormItem label="选手: ">
                    <PlayerSearch v-model="new_player.player"> </PlayerSearch>
                </FormItem>
                <FormItem label="角色: ">
                    <Select v-model="new_player.role_id" >
                        <Option v-for="item in roles" :value="item.id" :key="item.id">{{ item.name }} </Option>
                    </Select>
                </FormItem>
                <FormItem label="首发: ">
                    <Select v-model="new_player.is_first" >
                        <Option :value="0" :key="0">否</Option>
                        <Option :value="1" :key="1">是</Option>
                    </Select>
                </FormItem>
            </Form>
        </Modal>

        <Spin size="large" fix v-if="spinShow"></Spin>
        <Row type="flex" justify="end">
            <Col span="1">
                <Button type="success" icon="md-add" @click="modal_add=true"></Button>
            </Col>
            <Col span="4">
                <Select v-model="current_source_id" @on-change="on_source_id_change">
                    <Option value="1" key="1">手动</Option>
                    <Option v-for="item in third_lineup" :value="item.source_id" :key="item.source_id">{{ item.source_name }}</Option>
                </Select>
            </Col>
            <Col span="2">
                <Button type="primary" @click="api_save">保存</Button>
            </Col>
        </Row>
        <!-- <Row >
            <Col span="12" style="border-right: 1px solid black;">
                <Row class="text-main" type='flex' justify='center'>主队:</Row>
                <Table 
                    stripe
                    :columns="lineup_columns" 
                    :data="lineup.home"
                >
                </Table> 
            </Col>
            <Col span="12">
                <Row class="text-main" type='flex' justify='center'>客队:</Row>
                <Table 
                    stripe
                    :columns="lineup_columns" 
                    :data="lineup.away"
                >
                </Table> 
            </Col>
        </Row> -->
        <Row class="title-main" type='flex' justify='center'>主队:</Row>
        <Table 
            :columns="lineup_columns" 
            :data="lineup.home"
        >
        </Table> 
        <Row class="title-main" type='flex' justify='center'>客队:</Row>
        <Table 
            :columns="lineup_columns" 
            :data="lineup.away"
        >
        </Table> 
        <br />
        <div >
            <Row>
                <Col class="text-assist" span="2">展示源:</Col> 
                <Col class="text-assist" span="4">
                    <Select v-model="third_source_id">
                        <Option v-for="item in third_lineup" :value="item.source_id" :key="item.source_id">{{ item.source_name }}</Option>
                    </Select>
                </Col>
            </Row>
            <br />
            <!-- {{  third_lineup[third_source_id] }} -->
            <Row class="text-main"  >
                <Col span="12"  style="border-right: 1px solid black; align-text:center;">
                    <Row justify='center' 
                        v-for="(item, index) in (third_lineup[third_source_id]? third_lineup[third_source_id].lineup.home : [])" 
                        :key="index">
                        {{ item.player.id }} | {{item.player.name}} |  <Avatar :src="item.player.logo" /> | 
                        {{ item.role_id }} | {{ item.is_first==0?'否':'是' }} 
                    </Row>
                </Col>
                <Col span="12"  style="align-text:center;">
                    <Row justify='center' 
                        v-for="(item, index) in (third_lineup[third_source_id]? third_lineup[third_source_id].lineup.away : [])" 
                        :key="index">
                        {{ item.player.id }} | {{item.player.name}} |  <Avatar :src="item.player.logo" /> | 
                        {{ item.role_id }} | {{ item.is_first==0?'否':'是' }} 
                    </Row>
                </Col>
            </Row>
        </div>
    </div>
</template>
<script>

import { getMatchLineup } from '@/api/ballsports/base/match';
import Lock from '../lock.vue';
import PlayerSearch from '../player/search.vue';

export default {
    name: "detail-lineup",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
        Lock,
        PlayerSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 1,
            type: 4, // 类型id
            spinShow: false,
            locks: {},
            lineup: {
                home: [],   // 主队阵容
                away: [],   // 客队阵容
            },
            third_lineup: {},   // 第三方事件
            third_source_id: '',

            lineup_columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    // width: 50,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h(
                            'span', currentRow.player.id,
                        )
                    }
                },
                {
                    title: '头像',
                    align: 'center',
                    key: 'logo',
                    // width: 50,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h(
                            'Avatar', {
                                props:{
                                    src: currentRow.player.logo,
                                },
                                on: {
                                }
                            },
                        )
                    }
                },
                {
                    title: '球员',
                    align: 'center',
                    key: 'id',
                    width: 120,
                    render: function (h, params) {
                        let currentRow = params.row
                        return h(
                            PlayerSearch, {
                                class: self.locks[String(self.type) + '-' + currentRow.player.id] == 1? 'locked-input': '',
                                props:{
                                    value: currentRow.player,
                                },
                                on: {
                                    input: function(params){
                                        currentRow.player = params;
                                    }
                                }
                            },
                        )
                    }
                },
                {
                    title: '角色',
                    align: 'center',
                    key: 'role_id',
                    // width: 120,
                    render: function (h, params) {
                        let currentRow = params.row
                        let roles = [];
                        self.roles.forEach(element => {
                            roles.push( h('Option',{ props: {value: element.id}}, element.name )  )
                        });
                        return h(
                            'i-select', {
                                class: self.locks[String(self.type) + '-' + currentRow.player.id] == 1? 'locked-select': '',
                                props:{
                                    value: currentRow.role_id,
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.player.id] == 1,
                                },
                                on: {
                                    'on-change':(event) => {
                                        // console.log(event);
                                    }
                                }
                            }, roles
                        );
                    }
                },
                {
                    title: '首发',
                    align: 'center',
                    key: 'is_first',
                    // width: 100,
                    render: function (h, params) {
                        let currentRow = params.row
                        return h(
                            'i-select', {
                                class: self.locks[String(self.type) + '-' + currentRow.player.id] == 1? 'locked-select': '',
                                props:{
                                    value: currentRow.is_first,
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.player.id] == 1,
                                },
                                on: {
                                    'on-change':(event) => {
                                        console.log(event);
                                    }
                                }
                            },[
                                h('Option',{
                                    props: {
                                        value: 0
                                    }
                                },'否'),
                                h('Option',{
                                    props: {
                                        value: 1
                                    }
                                },'是'),
                            ]
                        );
                    }
                },
                {
                    title: '模式',
                    align: 'center',
                    // key: 'lock',
                    // width: 50,
                    render: function (h, params) {
                        let currentRow = params.row
                        return h(Lock, 
                            {
                                props:{
                                    value: self.locks[String(self.type)+'-' + currentRow.player.id ],
                                    sport_id: self.sport_id,
                                    obj_type: self.obj_type,
                                    obj_id: self.match_id,
                                    type: self.type,
                                    sub_type: currentRow.player.id,
                                },
                                on: {
                                    input(value){
                                        self.$set( self.locks, String(self.type)+'-' + currentRow.player.id, value );
                                    }
                                }
                            },
                        )
                    }
                },
                {
                    title: '操作',
                    align: 'center',
                    // width: 60,
                    render: function (h, params) {
                        let currentRow = params.row
                        return h('Poptip', 
                            {
                                props:{
                                    confirm: true,
                                    title: "确定删除本条数据?",
                                },
                                on: {
                                    'on-ok'(){
                                        // self.events.splice(params.index, 1)
                                        // self.$set( self.locks, String(self.type)+'-' + currentRow.player.id, value );
                                    },
                                }
                            },
                            [
                                h(
                                    'Button',
                                    {
                                        style: {
                                        },
                                        props: {
                                            // type: 'warning',
                                            // disabled: self.locks[String(self.type) + '-' + currentRow.player.id] == 1,
                                        }
                                    },
                                    '删除'
                                )
                            ]
                        )
                    }
                },
            ],
            
            // 模态框:
            modal_add: false,
            current_source_id: '',
            new_player: {
                player: {
                    id: 0,
                    name: '',
                    logo: '',
                },
                side: 0,
                role_id: 0,
                is_first: 0
            },

            roles: [
                {id: 0, name:"无"},
                {id: 1, name:"投球手"},
                {id: 2, name:"接球手"},
            ],
            // 修改变动历史
            counter : 0, 
            raw_copy: [],
        }
    },
    methods: {
        // 获取比分数据
        getData () {
            let self = this;
            self.spinShow = true;
            // 搜索日期
            var params = {
                sport_id: this.sport_id,
                match_id: this.match_id,
            }
            getMatchLineup( params).then(response => {
                if (response.data.code == 0) {
                    self.lineup  = response.data.data.lineup;
                    self.third_lineup = response.data.data.third_lineup;
                    self.locks = response.data.data.locks;
                    self.third_source_id = response.data.data.third_source_id;  // 默认展示的
                    self.current_source_id = response.data.data.current_source_id; 
                    self.spinShow = false;
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        // end
        modal_add_on_ok(){
            let self = this;
            if (self.new_player){
                if (self.new_player.side === 1 ){
                    self.lineup.home.push(
                        self.new_player
                    )
                }else if (self.new_player.side === 2){
                    self.lineup.away.push(
                        self.new_player
                    )
                }
                else{
                    self.$Message.error("无效的'队伍'")
                    return
                }
                // self.$set(self.tabs, _key, JSON.parse( JSON.stringify( {
                //     "key": _key,  // 全局比分
                //     "name": desc,
                // })));
            }
        },
        // end 
        api_save(){
            let self = this;
            // 将 保存到数据库
            // var params = {
            //     sport_id: self.sport_id,
            //     match_id: self.match_id,
            //     stat: self.stat,
            //     current_source_id: self.current_source_id,  // 当前的比分源id
            // }
            // console.log(params);
            // updateMatchStat( params).then(response => {
            //     if (response.data.code == 0) {
            //         self.$Message.success( "保存成功!" )
            //     } else {
            //         self.$Message.error(response.data.msg)
            //     }
            // })
        },

        // end 
        on_source_id_change(value){
            // 替换比分
            let self = this;
            if (value === '0'){
            }else if (value === '1'){
            }else{
                // 使用第三方源的数据直接替换
                self._update_data(value );
            }
        },
        // end
        _update_list( to, from ){
            let self = this;
            to = to || [];
            from = from || [];
            var existed_players = {};            
            for (let i = to.length - 1; i >= 0; i--) {
                // 如果存在锁
                let item = to[i];
                let player_id = item.player.id;
                // 如果被锁住 - 保留
                if ( self.locks[String(self.type)+  '-' + player_id ] === 1 ){
                    // 保留
                    existed_players[player_id] = true;
                }else{
                    // 删除
                    to.splice(i, 1);
                }
            }
            // 将from中的数据 重新写入到to中
            from.forEach(element => {
                let player_id = element.player.id;
                if ( ! existed_players[player_id] ){
                    // 写入
                    to.push(element);
                    existed_players[player_id] = true;
                }
            });
            return to;
        },
        // end

        _update_data( source_id ){
            // 使用结构相同的新数据更新旧数据
            // 待更新的数据如下:
            // self.lineup.home/away :   String(self.type) + '-' + currentRow.player.id = "4-player_id"
            // 判断self.locks中是否存在锁 如果存在 则保留之前的数据 否则更新
            let self = this;
            var to_home = self.lineup.home;
            var to_away = self.lineup.away;
            // 从第三方源的原始数据中 选择当前的源的数据 整合成self.stat的格式
            let third = self.third_lineup[source_id] || {};
            let from_lineup = third ? third.lineup : {} ;
            var from_home = from_lineup.home || {};
            var from_away = from_lineup.away || {};

            self._update_list(to_home, from_home);
            self._update_list(to_away, from_away);
        },
        // end 
        feed( data ){
            // 处理实时数据
            console.log("LIVE IN LINEUP: ", data)
        },
        // end
    },
    mounted () {
    },
    watch: {
        'match_id': {
            handler(value){
                let self = this;
                if (value){
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
        'lineup': {
            handler(value){
                let self = this;
                self.counter += 1;
                if (self.counter > 1){
                    // console.log("changed: ", value)
                    self.counter = 2;  // 最大为2
                    // this.$emit('onChange', self.type );
                }else if (self.counter === 1){
                    self.raw_copy = JSON.parse( JSON.stringify( self.lineup ));
                }
            },
            deep: true,
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    @import "detail.css"
</style>