<template>
    <div>
        <Drawer
            v-model="drawer_edit_match"
            width="550"
            :mask-closable="true"
        >
            <matchSingleEdit :game_id="game_id" :match_single_id="edit_id" @closePage="closeEditDrawer"></matchSingleEdit>
        </Drawer>  

        <!-- 模态框 - 编辑赛事赛季队伍等信息-->
        <!-- <Modal  v-model="modal_edit_unique_tournament" draggable title="编辑赛事" footer-hide >
            <div class="modal-div-containter">
                <uniqueTournamentEdit :game_id="game_id" :unique_tournament_id="edited_unique_tournament_id" @closePage="closeEditModalUniqueTournament"></uniqueTournamentEdit>
            </div>
        </Modal> -->
        <Row style="margin-bottom:10px;">
            <Col >
                <RadioGroup v-model="status_ids_str" type="button" @on-change="onStatusIdsStrChange">
                    <Radio v-for="(item, index) in status_union_dic" :key="index" :label="item.ids.join(',')">
                        {{ item.name }}
                    </Radio>
                </RadioGroup>
            </Col>
            <Col span="4">
                <Select v-model="status_ids" multiple @on-change="onStatusIdsChange" placeholder="多选状态">
                    <Option v-for="(item, index) in status_map" :value="item.id" :key="index">{{ item.name }}</Option>
                </Select>
            </Col>
            <Col span="4" style="text-align: left">
                <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
            </Col>
            <Col span="6" style="text-align: left" v-if=" $unit.filterGameids( [2,3], game_id )">
                <DatePicker format="yyyy-MM-dd HH:mm" :options="date_options" v-model="datarange" type="daterange" placement="bottom-end"
                    placeholder="日期范围" style="width: 100%" @on-change='onDatarangeChange' editable></DatePicker>
            </Col>
            <Col style="text-align: right" v-if="delete_filter">
                <Checkbox v-model="deleted" border>筛选删除</Checkbox> 
            </Col>
            <Col >
                <Button type="success"  @click="add">新增</Button>
            </Col>
        </Row>
        <fitTable 
            :columns="columns" 
            :data="data" 
            :loading="loading" 
        >
        </fitTable>
        <Page
            :total="total_page"
            :current="current_page"
            :page-size="page_size"
            @on-change="onPageChange"
            show-total
            show-elevator
        ></Page>
    </div>
</template>
<script>

import { getMatchSingleList, delActualData } from '@/api/games/match_single';
import moment from 'moment';
import matchSingleEdit from './edit.vue';
import {getImgGameUrl} from '@/utils/util';
import fitTable from '../../basic/table.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id);
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    // margin: '2px 2px'
                },
                props: {
                    type: 'warning',
                    size: 'small'
                }
            },
            '删除'
        )
        ]
    )
}
const delActualDataButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除本局比赛的实时数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // 删除本小局的实时数据
                let game_id = vm.game_id;
                let match_id = params.row.match_id;
                let box_num = params.row.box_num;
                vm.del_actual_data(game_id, match_id, box_num);
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    // margin: '2px 2px'
                },
                props: {
                    type: 'error',
                    size: 'small'
                }
            },
            '删除局实时数据'
        )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                // margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit_match = true;
                }
            }
        },
        '编辑'
    )
}

// 选手统计
const playerStatButton = (vm, h, params) => {
  return h(
    'Button',
    {
        props: {
            type: 'default',
        },
        on: {
            click: () => {
                let query = { match_single_id: params.row.id, game_id: params.row.game_id }
                // vm.$router.push({path: '/numer', query: query});
                let game = vm.$conf.games.games[vm.game_id];
                if (game){
                    let resolve_data = vm.$router.resolve({ path: '/games/'+ game.name_en +'/player_stat', query: query })
                    window.open(resolve_data.href, '_blank')
                }else{
                    vm.$Message.error("暂不支持的游戏类型")
                }
            }
        }
    },
    '选手统计'
  )
}

export default {
    name: "match-single-list",
    props: {
        game_id: Number,   // 运动id
        // match_id: Number, // 比赛id
    },
    components: {
        matchSingleEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            delete_filter: false,
            match_id: 0,
            deleted: false,
            drawer_detail: false,
            drawer_relation: false,
            drawer_edit_match: false,
            edit_id : 0,
            search_key: '',
            start_date: parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            end_date  : parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            datarange : [new Date(new Date().toLocaleDateString()),new Date(new Date().toLocaleDateString())],
            date_options: {
                shortcuts: [
                    {
                        text: '1个月',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [start, end];
                        }
                    },
                    {
                        text: '1周',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [start, end];
                        }
                    },
                    {
                        text: '6小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 6);
                            return [start, end];
                        }
                    },
                    {
                        text: '3小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 3);
                            return [start, end];
                        }
                    },
                    {
                        text: '30分钟',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 1800 * 1000);
                            return [start, end];
                        }
                    },
                    {
                        text: '昨天',
                        value () {
                            const end = new Date(new Date().toLocaleDateString());
                            const start = new Date();
                            start.setTime(end.getTime() - 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '今天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            end.setTime(start.getTime() + 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '明天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            start.setTime(start.getTime() + 3600 * 1000 * 24);
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
                            return [start, end];
                        }
                    },
                    
                ]
            },
            status_ids: [],
            status_ids_str: '',

            status_ids_list: [], // 用于搜索
            status_union_dic: {},
            status_map : {}, // 状态map表
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,

            edit_data: {
                uid: 0,
                name: ''
            },
            modal1: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 100,
                    key: 'id',
                },
                {
                    title: '赛事',
                    align: 'center',
                    key: 'tournament_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        var tournament = currentRow.tournament
                        return tournament.id > 0? [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    self.editTournament(tournament.id);
                                }
                            } },  tournament.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    self.editTournament(tournament.id);
                                }
                            } }, tournament.name,  )
                        ]: ''
                    }
                },
                {
                    title: '比赛id',
                    align: 'center',
                    minWidth: 100,
                    key: 'match_id',
                },
                {
                    title: '小局',
                    width: 60,
                    align: 'center',
                    key: 'box_num',
                },
                
                {
                    title: self.getTeamSide()[0],
                    key: 'home_team_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let home = currentRow.home;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'team', home.logo, true), shape: 'square', size: 'middle' }, class: {'team-logo': true} })],
                                ));
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(home.id);
                                            }
                                        } },  home.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(home.id);
                                            }
                                        } }, home.name,  )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: self.getTeamSide()[1],
                    key: 'away_team_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let away = currentRow.away;
                        let resp = [];
                        
                        resp.push(h("Row", {attrs: {justify: 'center',  }, }, 
                                    [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'team', away.logo, true), shape: 'square', size: 'middle' }, class: {'team-logo': true} })],
                                ));
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(away.id);
                                            }
                                        } },  away.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(away.id);
                                            }
                                        } }, away.name, )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: self.getTeamSide()[0] + 'BP',
                    key: 'home_bp',
                    align: 'center',
                    minWidth: 200,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let home_bans = currentRow.home_bans;
                        let home_pick = currentRow.home_pick;
                        let resp = [];
                        if (home_bans && home_bans.length > 0){
                            let cols = [];
                            home_bans.forEach(element => {
                                cols.push(
                                    // h('Tooltip', {attrs: {content: element.id+element.name} }, 
                                        [h('lazy-component',
                                            [h('img', { attrs: { 'src': getImgGameUrl(self.game_id, self.game_id==3? 'hero': 'hero', element.logo, true), shape: 'square', },
                                                class: {'table-hero-logo': true, 'ban': true},
                                            })]
                                        )]
                                    // )
                                )
                            });
                            resp.push(h("Row", {attrs: {justify: 'start' }}, cols, ));
                        }else{
                            resp.push(h("Row", {attrs: {justify: 'start' }},  [], ));
                        }
                        if (home_pick && home_pick.length > 0){
                            let cols = [];
                            home_pick.forEach(element => {
                                cols.push(
                                    // h('Tooltip', {attrs: {content: element.id+element.name} }, 
                                        [h('lazy-component',
                                            [h('img', { attrs: { 'src': getImgGameUrl(self.game_id,  self.game_id==3? 'hero': 'hero', element.logo, true), shape: 'square', },
                                                class: {'table-hero-logo': true} })]
                                        )]
                                    // )
                                );
                            });
                            resp.push(h("Row", {attrs: {justify: 'start' }},  cols ));
                        }else{
                            resp.push(h("Row", {attrs: {justify: 'start' }},  [], ));
                        }
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: self.getTeamSide()[1] + 'BP',
                    key: 'away_bp',
                    align: 'center',
                    minWidth: 200,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let away_bans = currentRow.away_bans;
                        let away_pick = currentRow.away_pick;
                        let resp = [];
                        if (away_bans && away_bans.length > 0){
                            let cols = [];
                            away_bans.forEach(element => {
                                cols.push(
                                    // h('Tooltip', {attrs: {content: element.id+element.name} }, 
                                        [h('lazy-component',
                                            [h('img', { attrs: {  'src': getImgGameUrl(self.game_id, self.game_id==3? 'hero': 'hero', element.logo, true), shape: 'square', },
                                                class: {'table-hero-logo': true, 'ban': true} })]
                                        )]
                                    // )
                                );
                            });
                            resp.push(h("Row", {attrs: {justify: 'start' }}, cols ));
                        }else{
                            resp.push(h("Row", {attrs: {justify: 'start' }}, [] ));
                        }
                        if (away_pick && away_pick.length > 0){
                            let cols = [];
                            away_pick.forEach(element => {
                                cols.push(
                                    // h('Tooltip', {attrs: {content: element.id+element.name} }, 
                                        [h('lazy-component',
                                            [h('img', { attrs: { 'src': getImgGameUrl(self.game_id,  self.game_id==3? 'hero': 'hero', element.logo, true), shape: 'square', },
                                                class: {'table-hero-logo': true} })]
                                        )]
                                    // )
                                );
                            });
                            resp.push(h("Row", {attrs: {justify: 'start', }, }, 
                                        cols,
                                    ));
                        }else{
                            resp.push(h("Row", {attrs: {justify: 'start' }}, 
                                        [],
                                    ));
                        }
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: self.getTeamSide()[0]+'胜',
                    key: 'home_win',
                    align: 'center',
                    minWidth: 50,
                },
                {
                    title: self.getTeamSide()[1]+'胜',
                    key: 'away_win',
                    align: 'center',
                    minWidth: 50,
                },
                {
                    title: self.getTeamSide()[0]+'击杀',
                    key: 'home_kills',
                    align: 'center',
                    minWidth: 50,
                },
                {
                    title: self.getTeamSide()[1]+'击杀',
                    key: 'away_kills',
                    align: 'center',
                    minWidth: 50,
                },
                {
                    title: '地图',
                    width: 100,
                    align: 'center',
                    key: 'map',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', currentRow.map? currentRow.map.name || '' : '')
                    }
                },
                {
                    title: self.getTeamSide()[0]+'比分',
                    key: 'home_score',
                    align: 'center',
                    minWidth: 50,
                },
                {
                    title: self.getTeamSide()[1]+'比分',
                    key: 'away_score',
                    align: 'center',
                    minWidth: 50,
                },
                {
                    title: '上下半场比分',
                    key: 'score_s',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let score_s = currentRow.score_s || [];
                        // console.log(score_s)
                        var resp = [];
                        let side_map = {0:'主', 1:'客'}
                        score_s.forEach((element, index) => {
                            resp.push( h( 'Row', (side_map[index] || '')+': ' + JSON.stringify(element) ) );
                        })
                        return resp;
                    }
                },
                {
                    title: '比赛时间',
                    width: 100,
                    align: 'center',
                    key: 'match_time',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', moment.unix(currentRow.match_time).format('YYYY-MM-DD HH:mm') )
                    }
                },
                {
                    title: '状态',
                    key: 'status_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        const status = self.status_map[ currentRow.status_id ];
                        const status_desc = status ? status.name: "未知状态: "+ currentRow.status_id;
                        const status_type = status ? status.type : 0;
                        const status_status = status_type == 1 ? 'success': (
                            status_type == 2 ? 'processing': (
                                status_type == 3 ? 'default': 'error'
                            )
                        )
                        return [h( 'Badge', {attrs: {status: status_status,  }} ), 
                                h('span', status_desc)]
                    }
                },
                // 
                {
                    title: '被删除',
                    key: 'deleted',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let deleted = currentRow.deleted;
                        let deleted_str = deleted == 0 ? '正常': (
                            deleted == 1 ? '被删除': '未知:'+deleted
                        )
                        const deleted_status = deleted == 0 ? 'success': (
                            deleted == 1 ? 'error': 'default:'
                        )
                        return [h( 'Badge', {attrs: {status: deleted_status,  }} ), 
                                h('span', deleted_str)]
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'left',
                    width: 160,
                    render (h, params) {
                        if (self.game_id === 3){
                            return [delActualDataButton(self, h, params), editButton(self, h, params), playerStatButton(self, h, params) ]  // delButton(self, h, params)
                        }
                        return [ editButton(self, h, params), playerStatButton(self, h, params) ]  // delButton(self, h, params)
                    }
                }
            ],
            data: [],
            drawer_styles: {
                padding: '2px',
            },
            // 编辑
            modal_edit_unique_tournament: false,
            modal_edit_season: false,
            modal_edit_tournament: false,
            modal_edit_team: false,
            edited_unique_tournament_id: 0,
            edited_season_id: 0,
            edited_tournament_id: 0,
            edited_team_id: 0,
        }
    },
    methods: {
        getTeamSide(){
            if (this.game_id == 1){
                return ['红', '蓝']
            }else if (this.game_id == 3 ){
                return ['天辉', '夜魇']
            }else if (this.game_id == 2 ){
                return ['主', '客']
            }else if (this.game_id == 6 ){
                return ['红', '蓝']
            }else{
                return ['', '']
            }
        },
        onPageChange (page) {
            // console.log("1 change", page);
            this.current_page = page;
            this.getData();
        },
        onSearch (search_key) {
            // console.log("2 change", search_key);
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onStatusIdsChange(value){
            // console.log("3 change", value);
            let self = this;
            self.current_page = 1;
            self.status_ids_str = '';
            self.getData();
        },
        onStatusIdsStrChange(value){
            // console.log("4 change", value);
            // console.log( value );
            let self = this;
            if (value){
                // self.status_ids = [];
                // self.status_ids_str.split(",").forEach(item => {
                //     self.status_ids.push(parseInt(item) )
                // })
            } else{
                // self.status_ids = [];
            }
            self.current_page = 1;
            self.getData(value);
        },
        onDatarangeChange(value){
            // console.log("5 change", value);
            let self = this;
            self.current_page = 1;
            if (value.length > 0){
                self.start_date = parseInt( Date.parse(value[0]) * 0.001 );
                self.end_date   = parseInt( Date.parse(value[1]) * 0.001 );
                // console.log( typeof( self.start_date ), self.start_date );
            }else{
                self.start_date = 0;
                self.end_date = 0;
            }
            self.start_date = self.start_date || 0;
            self.end_date   = self.end_date || 0;
            self.getData();
        },
        // end
        closeRelationDrawer(){
            let self = this;
            self.drawer_relation = false;
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit_match = false;
            self.edit_id = 0;
            self.getData();
        },
        // end
        closeEditModalUniqueTournament(){
            let self = this;
            self.modal_edit_unique_tournament = false;
            self.edited_unique_tournament_id = 0;
        },
        // end
        closeEditModalSeason(){
            let self = this;
            self.modal_edit_season = false;
            self.edited_season_id = 0;
        },
        // end
        closeEditModalTournament(){
            let self = this;
            self.modal_edit_tournament = false;
            self.edited_tournament_id = 0;
        },
        // end
        closeEditModalTeam(){
            let self = this;
            self.modal_edit_team = false;
            self.edited_team_id = 0;
        },
        // end
        editUniqueTournament(_id){
            let self = this;
            self.edited_unique_tournament_id = _id;
            self.modal_edit_unique_tournament = true;
        },
        // end

        // 
        editTournament(_id){
            let self = this;
            self.edited_tournament_id = _id;
            self.modal_edit_tournament = true;
        },
        // end
        editSeason(_id){
            let self = this;
            self.edited_season_id = _id;
            self.modal_edit_season = true;
        },
        // end
        editTeam(_id){
            let self = this;
            self.edited_team_id = _id;
            self.modal_edit_team = true;
        },
        // end
        getData (status_ids) {
            let self = this;
            this.loading = true
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            // 搜索状态
            if (status_ids){
            }else{
                if (this.status_ids){
                    // console.log(this.status_ids, typeof(this.status_ids) );
                    var status_ids = this.status_ids.join(',');
                }else{
                    var status_ids = '';
                }
            }
            //
            if (self.end_date > 0 && self.end_date === self.start_date){
                self.end_date = self.end_date + 3600 * 24 - 1;
            } 
            var params = {
                game_id: this.game_id,
                search_key: this.search_key,
                page: this.current_page,
                start_time: self.start_date,
                end_time  : self.end_date,
                status_ids: status_ids,
                match_id: self.match_id,
                lang: lang,
            }
            if (self.deleted){
                params['deleted'] = 1;
            }
            getMatchSingleList(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    // console.log(response.data.data.list);
                    this.loading = false;
                    this.data = response.data.data.list
                    this.total_page = response.data.data.total_page
                    this.page_size = response.data.data.page_size
                    this.current_page = response.data.data.current_page
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        
        
        add () {
            // this.edit_data = {
            //     'logo': ''
            // }
            this.edit_id = 0;
            this.drawer_edit_match = true
        },
        delete (_id) {
            var params = {
                id: _id,
                type: this.data_type
            }
            // console.log(params);
            deleteData(params).then(response => {
                if (response.data.code == 0) {
                    return true
                } else {
                    this.$Message.error(response.data.msg)
                    return false
                }
            })
        },
        // end 

        // 获取状态
        _init_status(){
            let self = this;
            const status = self.$conf.games.status;
            self.status_union_dic = {
                0: {
                    'ids': [],
                    "name": "全部",
                },
                1: {
                    'ids': [],
                    "name": "未开始",
                },  // 未开始
                2: {
                    'ids': [],
                    "name": "进行中",
                },  // 进行中
                3: {
                    'ids': [],
                    "name": "已结束",
                },  // 已结束
            }
            if (status){
                self.status_map = status.map;
                for (const status_id in self.status_map){
                    const element = self.status_map[ status_id];
                    if (element.type && element.type > 0){
                        self.status_union_dic[element.type].ids.push( element.id )
                    }
                }
            }
        },
        // end 
        // 删除实时数据
        del_actual_data(game_id, match_id, box_num){
            let self = this;
            var params = {
                game_id,
                match_id,
                box_num,
            }
            if (self.deleted){
                params['deleted'] = 1;
            }
            delActualData(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    self.$Message.success('实时数据删除成功');
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
    },
    mounted () {
        this.$nextTick(function () {
            this._init_status();
            // 只有网球展示列: 盘数
            if(this.game_id == 3){
                var hide_cols = ['map', 'home_score', 'away_score', 'score_s']
            }else if (this.game_id == 1){
                var hide_cols = ['tournament_id', 'match_time','map', 'home_score', 'away_score', 'score_s']
            }else if (this.game_id == 2){
                var hide_cols = ['home_bp', 'away_bp', 'home_win', 'away_win', 'home_kills', 'away_kills']
            }else if (this.game_id == 6){
                var hide_cols = ['tournament_id', 'match_time', 'map', 'home_score', 'away_score', 'score_s']
            }else{
                var hide_cols = []
            }
            this.columns = this.columns.filter(col => hide_cols .indexOf( col.key ) === -1 );
            // console.log(this.columns);
            try{
                if (this.$route.query.match_id){
                    this.match_id = parseInt(this.$route.query.match_id);
                    this.delete_filter = true;
                }
            }catch{
                this.match_id = 0;
            }
            // console.log(this.game_id, this.match_id);
            this.getData();
        })
    },
    watch: {
        // status_ids (value){
        // },
        deleted(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        }
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css";
</style>