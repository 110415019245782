import http from '@/utils/http';


export const FacetList = (parameter) => {
  return http.request({
    url: '/v1/games/facet/list',
    method: 'get',
    params: parameter
  })
}



export const EditFacet = (parameter) => {
  return http.request({
    url: '/v1/games/facet/edit',
    method: 'post',
    data: parameter
  })
}