<template>
    <div>
        <!-- <Drawer
            v-model="drawer_detail"
            width="830"
            :mask-closable="true"
            :styles="drawer_styles"
        >
            <p slot="header" style="color:#090C13;">
                <Row>
                    <Col span="6" class='text-main'>
                        比赛ID: {{detail_match_id}}
                    </Col>
                    <Col span="10">
                        <span class='text-main' v-if="detail_match_info.home && detail_match_info.away">
                            {{detail_match_info.home.name || ''}} vs {{detail_match_info.away.name || ''}}
                        </span>
                    </Col>
                </Row>
            </p>
            <matchDetail :game_id="game_id" :match_id="detail_match_id" ></matchDetail>
        </Drawer>   -->
        
        <!-- <Drawer
            v-model="drawer_relation"
            width="500"
            :mask-closable="true"
            :closable="false"
        >
            <matchRelation :game_id="game_id" :match_id="relation_match_id" @closePage="closeRelationDrawer"></matchRelation>
        </Drawer>   -->

        <Drawer
            v-model="drawer_edit_match"
            width="500"
            :mask-closable="true"
        >
            <matchEdit :game_id="game_id" :match_id="edit_match_id" @closePage="closeEditDrawer"></matchEdit>
        </Drawer>  

        <Drawer
            v-model="drawer_edit_match_source"
            width="500"
            :mask-closable="true"
        >
            <matchEditSource :game_id="game_id" :match_id="edit_match_source_id" 
                :home_id="edit_match_home_id" :away_id="edit_match_away_id"
                @closePage="closeEditSourceDrawer"></matchEditSource>
        </Drawer>  

        <!-- 模态框 - 编辑赛事赛季队伍等信息-->
        <!-- <Modal  v-model="modal_edit_unique_tournament" draggable title="编辑赛事" footer-hide >
            <div class="modal-div-containter">
                <uniqueTournamentEdit :game_id="game_id" :unique_tournament_id="edited_unique_tournament_id" @closePage="closeEditModalUniqueTournament"></uniqueTournamentEdit>
            </div>
        </Modal> -->
        <!-- <Modal  v-model="modal_edit_season" draggable title="编辑赛季" footer-hide >
            <div class="modal-div-containter">
                <seasonEdit :game_id="game_id" :season_id="edited_season_id" @closePage="closeEditModalSeason"></seasonEdit>
            </div>
        </Modal> -->
        <!-- <Modal  v-model="modal_edit_tournament" draggable title="编辑阶段" footer-hide >
            <div class="modal-div-containter">
                <tournamentEdit :game_id="game_id" :tournament_id="edited_tournament_id" @closePage="closeEditModalTournament"></tournamentEdit>
            </div>
        </Modal> -->
        <!-- <Modal  v-model="modal_edit_team" draggable title="编辑战队" footer-hide >
            <div class="modal-div-containter">
                <teamEdit :game_id="game_id" :team_id="edited_team_id" @closePage="closeEditModalTeam"></teamEdit>
            </div>
        </Modal> -->
        <Row style="margin-bottom:10px;">
            <Col >
                <RadioGroup v-model="status_ids_str" type="button" @on-change="onStatusIdsStrChange">
                    <Radio v-for="(item, index) in status_union_dic" :key="index" :label="item.ids.join(',')">
                        {{ item.name }}
                    </Radio>
                </RadioGroup>
            </Col>
            <Col span="4">
                <Select v-model="status_ids" multiple @on-change="onStatusIdsChange" placeholder="多选状态">
                    <Option v-for="(item, index) in status_map" :value="item.id" :key="index">{{ item.name }}</Option>
                </Select>
            </Col>
            <Col span="4" style="text-align: left">
                <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
            </Col>
            <Col span="6" style="text-align: left">
                <DatePicker format="yyyy-MM-dd HH:mm" :options="date_options" v-model="datarange" type="daterange" placement="bottom-end"
                    placeholder="日期范围" style="width: 100%" @on-change='onDatarangeChange' editable></DatePicker>
            </Col>
            <!-- <Col style="text-align: right">
                <Checkbox v-model="deleted" border>筛选删除</Checkbox> 
            </Col> -->
            <Col >
                <Button type="success"  @click="add">新增</Button>
            </Col>
        </Row>
        <fitTable 
            :columns="columns" 
            :data="data" 
            :loading="loading" 
        >
        </fitTable>
        <Page
            :total="total_page"
            :current="current_page"
            :page-size="page_size"
            @on-change="onPageChange"
            show-total
            show-elevator
        ></Page>
    </div>
</template>
<script>

import { GameMatchList, setMatchTeamRev, delMatchTeamRev, updateEsSource, FixMatch } from '@/api/games/match';
import moment from 'moment';
import matchEdit from './edit.vue';
import matchEditSource from './edit_source.vue';
import {getImgGameUrl} from '@/utils/util';
// 编辑选项
// import uniqueTournamentEdit from '../unique_tournament/edit.vue';
// import tournamentEdit from '../tournament/edit.vue';
// import seasonEdit from '../season/edit.vue';
// import teamEdit from '../team/edit.vue';
import fitTable from '../../basic/table.vue';

// const delButton = (vm, h, params) => {
//     return h(
//         'Poptip',
//         {
//         props: {
//             confirm: true,
//             title: '您确定要删除这条数据吗?',
//             transfer: true
//         },
//         on: {
//             'on-ok': () => {
//                 // vm.delete(params.row.id);
//                 // 删除本行
//                 vm.data.splice(params.index, 1)
//             }
//         }
//         },
//         [
//         h(
//             'Button',
//             {
//                 style: {
//                     margin: '2px 2px'
//                 },
//                 props: {
//                     type: 'warning',
//                     size: 'small'
//                 }
//             },
//             '删除'
//         )
//         ]
//     )
// }

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    // vm.edit_data = JSON.parse(JSON.stringify(params.row))
                    vm.edit_match_id = params.row.id;
                    vm.drawer_edit_match = true;
                }
            }
        },
        '编辑'
    )
}

const editSourceButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    // vm.edit_data = JSON.parse(JSON.stringify(params.row))
                    vm.edit_match_source_id = params.row.id
                    vm.edit_match_home_id = params.row.home.id
                    vm.edit_match_away_id = params.row.away.id
                    vm.drawer_edit_match_source = true;
                }
            }
        },
        '编辑源'
    )
}

const fixButton = (vm, h, params) => {
    return h('Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                size: 'small'
            },
            on: {
                click: () => {
                    vm.fixMatch(vm.game_id, params.row.id)
                }
            }
        },
        '修复'
    )
}

// 篮球 文字直播
const matchSingleButton = (vm, h, params) => {
  return h(
    'Button',
    {
        style: {
            margin: '2px 2px'
        },
        props: {
            type: 'default',
        },
        on: {
            click: () => {
                let query = { match_id: params.row.id, game_id: params.row.game_id }
                // vm.$router.push({path: '/numer', query: query});
                let game = vm.$conf.games.games[vm.game_id];
                if (game){
                    let resolve_data = vm.$router.resolve({ path: '/games/'+ game.name_en +'/match_single', query: query })
                    window.open(resolve_data.href, '_blank')
                }else{
                    vm.$Message.error("暂不支持的游戏类型")
                }
            }
        }
    },
    '小局'
  )
}

const eventButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    let query = { match_id: params.row.id, game_id: params.row.game_id }
                    let sport = vm.$conf.games.games[vm.game_id];
                    if (sport){
                        let path = '/cmsv2/esports/match/event';
                        let resolve_data = vm.$router.resolve({ path: path, query: query })
                        window.open(resolve_data.href, '_blank')
                    }else{
                        vm.$Message.error("暂不支持的游戏")
                    }
                }
            }
        },
        '赛后事件'
    )
}

export default {
    name: "match-list",
    props: {
        game_id: Number,   // 运动id
    },
    components: {
        matchEdit,
        // 编辑
        // uniqueTournamentEdit,
        // seasonEdit,
        // tournamentEdit,
        // teamEdit,
        fitTable,
        matchEditSource,
    },
    data () {
        let self = (window.x = this)
        return {
            edit_match_source_id : 0,   // 编辑源比赛id
            edit_match_home_id : 0,   // 编辑源比赛主队id
            edit_match_away_id : 0,   // 编辑源比赛客队id
            drawer_edit_match_source: false,  // 是否展示编辑源
            deleted: false,
            drawer_detail: false,
            drawer_relation: false,
            drawer_edit_match: false,
            relation_match_id: 0,
            detail_match_id: 0,
            edit_match_id : 0,
            detail_match_info: {
                home: {},
                away: {},
            },
            search_key: '',
            start_date: parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            end_date  : parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            datarange : [new Date(new Date().toLocaleDateString()),new Date(new Date().toLocaleDateString())],
            date_options: {
                shortcuts: [
                    {
                        text: '1个月',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [start, end];
                        }
                    },
                    {
                        text: '1周',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [start, end];
                        }
                    },
                    {
                        text: '6小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 6);
                            return [start, end];
                        }
                    },
                    {
                        text: '3小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 3);
                            return [start, end];
                        }
                    },
                    {
                        text: '30分钟',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 1800 * 1000);
                            return [start, end];
                        }
                    },
                    {
                        text: '昨天',
                        value () {
                            const end = new Date(new Date().toLocaleDateString());
                            const start = new Date();
                            start.setTime(end.getTime() - 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '今天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            end.setTime(start.getTime() + 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '明天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            start.setTime(start.getTime() + 3600 * 1000 * 24);
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
                            return [start, end];
                        }
                    },
                    
                ]
            },
            status_ids: [],
            status_ids_str: '',

            status_ids_list: [], // 用于搜索
            status_union_dic: {},
            status_map : {}, // 状态map表
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,

            edit_data: {
                uid: 0,
                name: ''
            },
            modal1: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 100,
                    key: 'id',
                },
                {
                    title: 'thesports id',
                    align: 'center',
                    width: 100,
                    key: 'thesports_id',
                },
                {
                    title: '赛事',
                    align: 'left',
                    key: 'tournament_id',
                    minWidth: 100,
                    render: function (h, params) {
                        let currentRow = params.row
                        var tournament = currentRow.tournament
                        return tournament.id > 0? [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    self.editTournament(tournament.id);
                                }
                            } },  tournament.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    self.editTournament(tournament.id);
                                }
                            } }, tournament.name,  )
                        ]: ''
                    }
                },
                {
                    title: '阶段',
                    align: 'left',
                    key: 'stage_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var stage = currentRow.stage;
                        return stage.id > 0? [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    // self.editStage(stage.id);
                                }
                            } },  stage.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    // self.editStage(stage.id);
                                }
                            } }, stage.name,  )
                        ]: ''
                    }
                },
                {
                    title: 'Bo',
                    width: 60,
                    align: 'left',
                    key: 'box',
                },
                
                {
                    title: '主队',
                    key: 'home_team_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let homes = currentRow.homes;
                        let home = currentRow.home;
                        let resp = [];
                        if (homes && homes.length > 0){
                            let cols = [];
                            homes.forEach(element => {
                                cols.push(
                                    h('Col', { attrs: { span: 12 }}, 
                                        [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'team', element.logo), shape: 'square', size: 'middle' },
                                            class: {'team-logo': true} })]
                                    ),);
                            });
                            resp.push(h("Row", {attrs: {justify: 'center', }, }, 
                                        cols,
                                    ));
                        }else{
                            resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                        [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'team', home.logo), shape: 'square', size: 'middle' },
                                            class: {'team-logo': true} })],
                                    ));
                        }
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(home.id);
                                            }
                                        } },  home.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(home.id);
                                            }
                                        } }, home.name,  )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                
                {
                    title: '客队',
                    key: 'away_team_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let aways = currentRow.aways;
                        let away = currentRow.away;
                        let resp = [];
                        if (aways && aways.length > 0){
                            let cols = [];
                            aways.forEach(element => {
                                cols.push(
                                    h('Col', { attrs: { span: 12 }}, 
                                        [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'team', element.logo), shape: 'square', size: 'middle' },
                                            class: {'team-logo': true} })]
                                    ),);
                            });
                            resp.push(h("Row", {attrs: {justify: 'center',  }, }, 
                                        cols,
                                    ));
                        }else{
                            resp.push(h("Row", {attrs: {justify: 'center',  }, }, 
                                        [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'team', away.logo), shape: 'square', size: 'middle' },
                                            class: {'team-logo': true} })],
                                    ));
                        }
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(away.id);
                                            }
                                        } },  away.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(away.id);
                                            }
                                        } }, away.name, )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '主分',
                    key: 'home_score',
                    align: 'center',
                    minWidth: 80,
                    
                },
                {
                    title: '客分',
                    key: 'away_score',
                    align: 'center',
                    minWidth: 80,
                    
                },
                {
                    title: '比赛时间',
                    width: 100,
                    align: 'center',
                    key: 'match_time',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', moment.unix(currentRow.match_time).format('YYYY-MM-DD HH:mm') )
                    }
                },
                {
                    title: '状态',
                    key: 'status_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        const status = self.status_map[ currentRow.status_id ];
                        const status_desc = status ? status.name: "未知状态: "+ currentRow.status_id;
                        const status_type = status ? status.type : 0;
                        const status_status = status_type == 1 ? 'success': (
                            status_type == 2 ? 'processing': (
                                status_type == 3 ? 'default': 'error'
                            )
                        )
                        return [h( 'Badge', {attrs: {status: status_status,  }} ), 
                                h('span', status_desc)]
                    }
                },
                {
                    title: '赛程源',
                    width: 100,
                    align: 'center',
                    key: 'es_source',
                    // game_ids: [1,],
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h('i-select', {
                                props:{
                                    value: currentRow.es_source,
                                },
                                on: {
                                    'on-change':(event) => {
                                        // 更新es-source
                                        self.updateEsSource(self.game_id, currentRow.id, event);
                                    } 
                                },
                            },
                            [{id:0, name:'无'}, {id:1, name:'源1'},{id:2, name:'源2'},{id:10, name:'手动'}].map((item) => {
                                return h('Option',{
                                    props: {
                                        value: item.id,
                                        label: item.name,
                                    }
                                })
                            })
                        );
                    }
                },
                {
                    title: '主客反转',
                    width: 100,
                    align: 'center',
                    key: 'team_rev',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h('i-switch', { //数据库1是已处理，0是未处理
                            props: {
                                type: 'primary',
                                value: currentRow.team_rev === 1 //控制开关的打开或关闭状态，官网文档属性是value
                            },
                            style: {
                                marginRight: '5px'
                            },
                            on: {
                                'on-change': (value) => { //触发事件是on-change,用双引号括起来，
                                    //参数value是回调值，并没有使用到
                                    // this.switch(params.index) //params.index是拿到table的行序列，可以取到对应的表格值
                                    // console.log(self.game_id, currentRow.id, value)
                                    if (value){
                                        self.set_team_rev(self.game_id, currentRow.id);
                                    }else{
                                        self.del_team_rev(self.game_id, currentRow.id);
                                    }
                                }
                            }
                        }, )
                    }
                },
                // {
                //     title: '被删除',
                //     key: 'deleted',
                //     align: 'center',
                //     minWidth: 80,
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         let deleted = currentRow.deleted;
                //         let deleted_str = deleted == 0 ? '正常': (
                //             deleted == 1 ? '被删除': '未知:'+deleted
                //         )
                //         const deleted_status = deleted == 0 ? 'success': (
                //             deleted == 1 ? 'error': 'default:'
                //         )
                //         return [h( 'Badge', {attrs: {status: deleted_status,  }} ), 
                //                 h('span', deleted_str)]
                //     }
                // },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'left',
                    width: 150,
                    render (h, params) {
                        // if (self.game_id === 1){
                        // }else if (self.game_id === 2){
                        //     return [ logButton(self, h, params), ];
                        // }
                        let btns = [editButton(self, h, params), editSourceButton(self, h, params), matchSingleButton(self, h, params), fixButton(self, h, params) ]
                        if (self.game_id === 1){
                            btns.push(eventButton(self, h, params));
                        }
                        return btns;
                    }
                }
            ],
            data: [],
            drawer_styles: {
                padding: '2px',
            },
            // 编辑
            modal_edit_unique_tournament: false,
            modal_edit_season: false,
            modal_edit_tournament: false,
            modal_edit_team: false,
            edited_unique_tournament_id: 0,
            edited_season_id: 0,
            edited_tournament_id: 0,
            edited_team_id: 0,
        }
    },
    methods: {
        onPageChange (page) {
            // console.log("1 change", page);
            this.current_page = page;
            this.getData()
        },
        onSearch (search_key) {
            // console.log("2 change", search_key);
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onStatusIdsChange(value){
            // console.log("3 change", value);
            let self = this;
            self.current_page = 1;
            self.status_ids_str = '';
            self.getData();
        },
        onStatusIdsStrChange(value){
            // console.log("4 change", value);
            // console.log( value );
            let self = this;
            if (value){
                // self.status_ids = [];
                // self.status_ids_str.split(",").forEach(item => {
                //     self.status_ids.push(parseInt(item) )
                // })
            } else{
                // self.status_ids = [];
            }
            self.current_page = 1;
            self.getData(value);
        },
        onDatarangeChange(value){
            // console.log("5 change", value);
            let self = this;
            self.current_page = 1;
            if (value.length > 0){
                self.start_date = parseInt( Date.parse(value[0]) * 0.001 );
                self.end_date   = parseInt( Date.parse(value[1]) * 0.001 );
                // console.log( typeof( self.start_date ), self.start_date );
            }else{
                self.start_date = 0;
                self.end_date = 0;
            }
            self.start_date = self.start_date || 0;
            self.end_date   = self.end_date || 0;
            self.getData();
        },
        // end
        closeRelationDrawer(){
            let self = this;
            self.drawer_relation = false;
            self.relation_match_id = 0;
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit_match = false;
            self.edit_match_id = 0;
            self.getData();
        },
        closeEditSourceDrawer(){
            let self = this;
            self.edit_match_source_id = 0;   // 编辑源比赛id
            self.drawer_edit_match_source = false;  // 是否展示编辑源
        },
        // end
        closeEditModalUniqueTournament(){
            let self = this;
            self.modal_edit_unique_tournament = false;
            self.edited_unique_tournament_id = 0;
        },
        // end
        closeEditModalSeason(){
            let self = this;
            self.modal_edit_season = false;
            self.edited_season_id = 0;
        },
        // end
        closeEditModalTournament(){
            let self = this;
            self.modal_edit_tournament = false;
            self.edited_tournament_id = 0;
        },
        // end
        closeEditModalTeam(){
            let self = this;
            self.modal_edit_team = false;
            self.edited_team_id = 0;
        },
        // end
        editUniqueTournament(_id){
            let self = this;
            self.edited_unique_tournament_id = _id;
            self.modal_edit_unique_tournament = true;
        },
        // end

        // 
        editTournament(_id){
            let self = this;
            self.edited_tournament_id = _id;
            self.modal_edit_tournament = true;
        },
        // end
        editSeason(_id){
            let self = this;
            self.edited_season_id = _id;
            self.modal_edit_season = true;
        },
        // end
        editTeam(_id){
            let self = this;
            self.edited_team_id = _id;
            self.modal_edit_team = true;
        },
        // end
        getData (status_ids) {
            let self = this;
            this.loading = true
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            // 搜索状态
            if (status_ids){
            }else{
                if (this.status_ids){
                    // console.log(this.status_ids, typeof(this.status_ids) );
                    var status_ids = this.status_ids.join(',');
                }else{
                    var status_ids = '';
                }
            }
            //
            if (self.end_date > 0 && self.end_date === self.start_date){
                self.end_date = self.end_date + 3600 * 24 - 1;
            } 
            var params = {
                game_id: this.game_id,
                search_key: this.search_key,
                page: this.current_page,
                start_time: self.start_date,
                end_time  : self.end_date,
                status_ids: status_ids,
                lang: lang,
            }
            if (self.deleted){
                params['deleted'] = 1;
            }
            GameMatchList(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list
                    this.total_page = response.data.data.total_page
                    this.page_size = response.data.data.page_size
                    this.current_page = response.data.data.current_page
                    this.loading = false
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        
        
        add () {
            // this.edit_data = {
            //     'logo': ''
            // }
            this.edit_match_id = 0;
            this.drawer_edit_match = true
        },
        delete (_id) {
            var params = {
                id: _id,
                type: this.data_type
            }
            // console.log(params);
            deleteData(params).then(response => {
                if (response.data.code == 0) {
                    return true
                } else {
                    this.$Message.error(response.data.msg)
                    return false
                }
            })
        },
        // end 

        // 获取状态
        _init_status(){
            let self = this;
            const status = self.$conf.games.status;
            self.status_union_dic = {
                0: {
                    'ids': [],
                    "name": "全部",
                },
                1: {
                    'ids': [],
                    "name": "未开始",
                },  // 未开始
                2: {
                    'ids': [],
                    "name": "进行中",
                },  // 进行中
                3: {
                    'ids': [],
                    "name": "已结束",
                },  // 已结束
            }
            if (status){
                self.status_map = status.map;
                for (const status_id in self.status_map){
                    const element = self.status_map[ status_id];
                    if (element.type && element.type > 0){
                        self.status_union_dic[element.type].ids.push( element.id )
                    }
                }
            }
        },
        // end 
        // 设置主客反转
        set_team_rev(game_id, match_id){
            let self = this;
            var params = {
                game_id: game_id,
                match_id: match_id,
            }
            setMatchTeamRev(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    self.$Message.success("成功");
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        del_team_rev(game_id, match_id){
            let self = this;
            var params = {
                game_id: game_id,
                match_id: match_id,
            }
            delMatchTeamRev(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    self.$Message.success("成功");
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        updateEsSource(game_id, match_id, source){
            let self = this;
            var params = {
                game_id: game_id,
                match_id: match_id,
                source: source
            }
            updateEsSource(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    self.$Message.success("成功");
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        fixMatch (game_id, match_id) {
            let self = this;
            FixMatch({ game_id, match_id }).then(response => {
                // console.log(response);
                if (response.data.code === 0) {
                    self.$Message.success('上报成功');
                } else {
                    self.$Message.error('出现异常');
                }
            })
        },
        // end
    },
    mounted () {
        this.$nextTick(function () {
            this._init_status();
            // 只有网球展示列: 盘数
            // if ( ! this.game_id || this.game_id <= 0 ){
            //     this.game_id = 3;
            // }
            this.columns = this.columns.filter(col => col.game_ids? this.$unit.filterGameids( col.game_ids, this.game_id ): true );
            this.getData();
        })
    },
    watch: {
        // status_ids (value){
        // },
        deleted(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        }
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css";
</style>