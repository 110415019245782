import http from '@/utils/http';

export const getPlayerStatSources = (parameter) => {
    return http.request({
      url: '/v1/sports/player_stat/sources',
      method: 'get',
      params: parameter
    })
} 

export const updateMatchSource = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/sources/update',
        method: 'post',
        data: parameter
    })
}

export const getPlayerStat = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/get',
        method: 'get',
        params: parameter
    })
}

export const updatePlayerStat = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/transfer',
        method: 'post',
        data: parameter
    })
}

export const getMatch = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/match/get',
        method: 'get',
        params: parameter
    })
}


export const getPlayers = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/players',
        method: 'get',
        params: parameter
    })
}


export const updatePlayer = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/update',
        method: 'post',
        data: parameter
    })
}
export const updatePlayerV2 = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/updatev2',
        method: 'post',
        data: parameter
    })
}
export const deletePlayer = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/delete',
        method: 'post',
        data: parameter
    })
}


export const updatePlayerStatMode = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/mode/update',
        method: 'post',
        data: parameter
    })
}

export const addFixMatch = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/fix/add',
        method: 'post',
        data: parameter
    })
}

export const source2Line = (parameter) => {
    return http.request({
        url: '/v1/sports/player_stat/source2line',
        method: 'post',
        data: parameter
    })
}

