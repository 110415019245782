<template>
    <div>
        <Modal
            v-model="modal_add_pan"
            title="新增盘"
            @on-ok="modal_add_on_ok"
        >
            <Form label-position="left" :label-width="60">
                <FormItem label="类型: ">
                    <Select v-model="new_tab.sub_type" >
                        <Option :value="1" :key="1">盘</Option>
                        <Option :value="2" :key="2">抢7</Option>
                    </Select>
                </FormItem>
                <FormItem label="盘数: ">
                    <Input v-model="new_tab.box_num" number placeholder="盘数-0(总盘) 1(盘1)"></Input>
                </FormItem>
            </Form>
        </Modal>
        <Spin size="large" fix v-if="spinShow"></Spin>
        
        <Tabs type="card" closable @on-tab-remove="handleTabRemove" :before-remove="beforeTabRemove">
            <TabPane v-for="(item, index) in tabs" :key="index" :label="item.name" :name="item.key">
                <Row :gutter="16">
                    <Col v-for="(a_stst, sub_index) in stat[item.key]" :key="sub_index" class='box-border'>
                        <div>
                            <Row type='flex' justify='center' class="text-main">{{ a_stst.name }}</Row>
                            <Row type='flex' justify='center'><Input style="width: 60px" v-model="a_stst.score[0]" placeholder="主" :class="locks[String(type)+'-' + a_stst.sub_type + '-' + item.key]=== 1? 'locked-input': '' "></Input></Row>
                            <Row type='flex' justify='center'><Input style="width: 60px" v-model="a_stst.score[1]" placeholder="客" :class="locks[String(type)+'-' + a_stst.sub_type + '-' + item.key]=== 1? 'locked-input': '' "></Input></Row>
                            <Row type='flex' justify='center'><Lock v-model="locks[String(type)+'-' + a_stst.sub_type + '-' + item.key]" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="a_stst.sub_type" :_key="item.key"></Lock></Row>
                        </div>
                    </Col>
                </Row>
                <!-- <Divider /> -->
                <!-- 第三方关联的源的比分 -->
                <br>
                <Table 
                    :columns="third_table_data[item.key]? third_table_data[item.key].cols || [] : []" 
                    :data="third_table_data[item.key]? third_table_data[item.key].data || [] : []"
                >
                </Table>
            </TabPane>
            <Row slot="extra">
                <Col span="4">
                    <Button type="success" icon="md-add" @click="modal_add_pan=true"></Button>
                </Col>
                <Col span="12">
                    <Select v-model="current_source_id" @on-change="on_source_id_change">
                        <Option value="1" key="1">手动</Option>
                        <Option v-for="item in sources" :value="item.source_id" :key="item.source_id">{{ item.source_name }}</Option>
                    </Select>
                </Col>
                <Col span="8">
                    <Button type="primary" @click="api_save">保存</Button>
                </Col>
            </Row>
        </Tabs>
        
    </div>
</template>
<script>

import { getMatchStat } from '@/api/ballsports/base/match';
import Lock from '../lock.vue';

export default {
    name: "detail-stat",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
        Lock,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 1, // 比赛
            type: 2, // 类型id
            spinShow: false,
            columns: [], // 列 - 技术统计 全部列
            tabs: {},    // 盘列表
            stat: {},    // 盘对应的统计
            locks: {},
            third_stat: {},  // 第三方源的接口返回原始数据
            current_source_id: '',  // 当前的源id
            sources: {}, // 源列表
            third_table_data: {} , // 展示第三方源的表格数据 - 格式调整
            third_table_col_map: {}, // 存储列表中已经存在的维度

            // 处理盘删减的变量
            new_tab: {
                sub_type: 1,  // 
                box_num : 0,  
            },    // 新盘数据
            modal_add_pan: false,
            // 修改变动历史
            counter : 0, 
            raw_copy: [],
        }
    },
    methods: {
        table_third_stat_score_handler(){
            let self = this;
            var handler = function(h, params){
                let currentRow = params.row;
                var stat = currentRow.stat;
                var _key = params.column.key;
                var _single = stat[_key] || {}; 
                var score = _single? _single.score.join(' - ') : ''
                return h(
                    'span', score,
                )
            }
            return handler;
        },
        // 解析第三方的数据 成表格的样式
        _init_third_stat(){
            let self = this;

            self.third_table_data = {};
            self.third_table_col_map = {};
            self.sources = {};

            for (var pan_key in self.third_stat){
                if(! self.third_table_data[pan_key] ){
                    self.third_table_data[pan_key] = {
                        "data": [],    // 数据
                        "cols": [],   // 列名称
                    }
                    self.third_table_col_map[pan_key] = {}
                }
                // 遍历各个源 - 新增表格的列
                self.third_table_data[pan_key].cols.push({
                    title: '源名称',
                    align: 'center',
                    key:   "source_name",
                    fixed: 'left',
                    minWidth: 80,
                })
                self.third_table_col_map[pan_key]["source_name"] = true;

                for (var source_id in self.third_stat[pan_key]){
                    var  source_data =  self.third_stat[pan_key][source_id];
                    self.third_table_data[pan_key].data.push( source_data );
                    // 统计维度
                    var source_stat = source_data.stat;
                    for (var stat_sub_type in source_stat){
                        if (! self.third_table_col_map[pan_key][stat_sub_type] ){
                            // 新增列
                            self.third_table_data[pan_key].cols.push({
                                title: source_stat[stat_sub_type].name,
                                align: 'center',
                                key:   stat_sub_type,
                                minWidth: 80,
                                render:  self.table_third_stat_score_handler()
                            })
                        }
                        self.third_table_col_map[pan_key][stat_sub_type] = true;
                    } 
                    // 整合第三方的源列表
                    if ( ! self.sources[ source_id] ){
                        self.sources[source_id] = {
                            source_id: source_id,
                            source_name: source_data.source_name,
                        }
                    }
                }
            }
        },
        // end

        // 获取比分数据
        getData () {
            let self = this;
            self.spinShow = true;
            // 搜索日期
            var params = {
                sport_id: this.sport_id,
                match_id: this.match_id,
            }
            getMatchStat( params).then(response => {
                if (response.data.code == 0) {
                    self.tabs  = response.data.data.tabs;
                    self.locks = response.data.data.locks;
                    self.stat  = response.data.data.stat;
                    self.columns  = response.data.data.columns;
                    self.third_stat  = response.data.data.third_stat;
                    self.current_source_id = response.data.data.current_source_id;
                    self._init_third_stat();
                    self.spinShow = false;
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        // end 
        api_save(){
            let self = this;
            // 将 保存到数据库
            var params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                stat: self.stat,
                current_source_id: self.current_source_id,  // 当前的比分源id
            }
            console.log(params);
            // updateMatchStat( params).then(response => {
            //     if (response.data.code == 0) {
            //         self.$Message.success( "保存成功!" )
            //     } else {
            //         self.$Message.error(response.data.msg)
            //     }
            // })
        },
        // end 
        on_source_id_change(value){
            // 替换比分
            let self = this;
            if (value === '0'){
            }else if (value === '1'){
            }else{
                // 使用第三方源的数据直接替换
                var source_data = {}
                for (var pan_key in self.third_stat){
                    var a_third = self.third_stat[pan_key] ? self.third_stat[pan_key][value] : null;
                    if (a_third){
                        if (a_third.stat){
                            source_data[pan_key] = a_third.stat;
                        }
                    }
                }
                self._update_data( source_data );
            }
        },
        // end
        _update_data( source_data ){
            console.log(source_data);
            // 使用结构相同的新数据更新旧数据
            // 待更新的数据如下:
            // stat.盘.sub_type :       String(type)+ '-' + a_stat.sub_type + '-' + pan_key = "2-{sub_type}-{pan-key}"
            // 判断self.locks中是否存在锁 如果存在 则保留之前的数据 否则更新
            let self = this;
            // 首先从第三方源的原始数据中 选择当前的源的数据 整合成self.stat的格式
            
            var data = JSON.parse(JSON.stringify( self.stat || {} )) ; 
            var source_data = JSON.parse(JSON.stringify( source_data || {} )) ; 
            for (var pan_key in data){
                var pan_data = data[pan_key];
                var source_pan_data = source_data[pan_key];
                for (var sub_type in pan_data){
                    // 如果被锁住 - 保留
                    if ( self.locks[String(self.type)+  '-' + sub_type + '-' + pan_key] === 1 ){
                        // 保留
                    }else if ( source_pan_data && source_pan_data[sub_type] ){
                        // 新源存在数据则 使用新源的数据替换
                        self.$set( self.stat[pan_key], sub_type, source_pan_data[sub_type] );
                    }else{
                        // 新源不存在数据 同时也不保留历史数据 - 删除
                        if (self.stat[pan_key] [sub_type]){
                            self.$delete( self.stat[pan_key], sub_type );
                        }
                    }
                    // 删除处理过的维度数据
                    if (source_pan_data){
                        delete source_pan_data[ sub_type ]
                    }
                } 
            }
            // 可能新增的 - 键
            for ( var pan_key in source_data ){
                if (self.stat[pan_key]){
                    for (var new_sub_type in source_data[pan_key]){
                        self.$set( self.stat[pan_key], new_sub_type, source_data[pan_key][new_sub_type] );
                    }
                }else{
                    self.$set(self.stat, pan_key, source_data[pan_key]);
                }
            }
        },
        // end
        beforeTabRemove(index){
            let self = this;
            return new Promise(function (resolve, reject) {
                // 确认框
                self.$Modal.confirm({
                    title: '确认删除',
                    content: '<p>是否确认删除?</p>',
                    onOk: () => {
                        resolve();
                    },
                    onCancel: () => {
                        reject();
                    }
                });
            })
        },
        // end 
        handleTabRemove( name ){
            let self = this;
            if (self.tabs && self.tabs[name]){
                self.$delete( self.tabs, name );
            }
            if (self.stat && self.stat[name]){
                self.$delete( self.stat, name);
            }
        },
        // end
        modal_add_on_ok(){
            let self = this;
            if (self.new_tab){
                if (self.new_tab.box_num > 0){
                    if (self.new_tab.sub_type == 1){
                        var _key = "p" + self.new_tab.box_num;
                        var desc = "盘" + self.new_tab.box_num;
                    }else if (self.new_tab.sub_type == 2){
                        var _key = "x" + self.new_tab.box_num
                        var desc = "抢7盘" + self.new_tab.box_num;
                    }else{
                        self.$Message.error("类型错误:请选择'盘'或者'抢七'")
                        return
                    }
                }else if (self.new_tab.box_num == 0){
                        var _key = "ft" ;
                        var desc = "总盘"
                }else{
                    self.$Message.error("无效的'盘数'")
                    return
                }
                self.$set(self.tabs, _key, JSON.parse( JSON.stringify( {
                    "key": _key,  // 全局比分
                    "name": desc,
                })));
                // 新增数据
                var new_tab_stat = {};
                self.columns.forEach(element => {
                    new_tab_stat[element.sub_type] = {
                        sub_type: element.sub_type,
                        name: element.name,
                        score: ['', '']
                    }
                });
                self.$set( self.stat, _key, JSON.parse( JSON.stringify(new_tab_stat)) );
            }
            // console.log(self.stat);
        },
        // end 
        feed( data ){
            // 处理实时数据
            console.log("LIVE IN STAT: ", data)
            let self= this;
            self._update_data(data.stat || {})
        },
        // end
    },
    mounted () {
    },
    watch: {
        'match_id': {
            handler(value){
                let self = this;
                if (value){
                    self.tabs = {}    // 盘列表
                    self.stat = {}    // 盘对应的统计
                    self.locks= {}
                    self.third_stat= {}  // 第三方源的接口返回原始数据
                    self.current_source_id= ''  // 当前的源id
                    self.sources = {} // 源列表
                    self.third_table_data = {}  // 展示第三方源的表格数据 - 格式调整
                    self.third_table_col_map = {} // 存储列表中已经存在的维度
                    
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
        'stat': {
            handler(value){
                let self = this;
                self.counter += 1;
                if (self.counter > 1){
                    // console.log("changed: ", value)
                    self.counter = 2;  // 最大为2
                    // this.$emit('onChange', self.type);
                }else if (self.counter === 1){
                    self.raw_copy = JSON.parse( JSON.stringify( self.stat ) );
                }
            },
            deep: true,
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    @import "detail.css"
</style>