<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
    >
        <delegatePlayerEdit :_id="edit_id" @closePage="closeEditDrawer"></delegatePlayerEdit>
    </Drawer> 
    <Modal v-model="modal_edit_athlete" draggable title="编辑运动员" footer-hide >
        <div class="modal-div-containter">
            <playerEdit :_id="edited_athlete_id" @closePage="closeEditModalPlayer"></playerEdit>
        </div>
    </Modal>
    <Modal v-model="modal_edit_delegate" draggable title="编辑代表团" footer-hide >
        <div class="modal-div-containter">
            <delegateEdit :_id="edited_delegate_id" @closePage="closeEditModalDelegate"></delegateEdit>
        </div>
    </Modal>
    <Row style="margin-bottom: 10px;" :gutter="16" align="middle">
        <Col span="1" class="text-main"  style="text-align: left">
            运动会
        </Col>
        <Col span="4" style="text-align: left">
            <seasonSearch v-model="season"></seasonSearch>
        </Col>
        <Col span="1" class="text-main" style="text-align: left">
            代表团
        </Col>
        <Col span="4" style="text-align: left">
            <delegateSearch v-model="delegate"></delegateSearch>
        </Col>
        <Col span="14" style="text-align: right">
            <Button type="success"  @click="add">新增</Button>
        </Col>
    </Row>
    <fitTable
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getDelegationPlayerList } from '@/api/olympics/delegation_player';
import delegatePlayerEdit from './edit.vue';
import seasonSearch from '../season/search.vue';
import delegateSearch from '../delegation/search.vue';
import playerEdit from '../player/edit.vue';
import delegateEdit from '../delegation/edit.vue';
import fitTable from '../../basic/table.vue';

const editButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "delegate-player-list",
    props: {
        
    },
    components: {
        delegatePlayerEdit,
        seasonSearch,
        delegateSearch,
        playerEdit,
        delegateEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            edited_athlete_id: 0,
            modal_edit_athlete: false,
            edited_delegate_id: 0,
            modal_edit_delegate: false,

            season: {
                id: 0,
                name: '',
            }, 
            delegate: {
                id: 0,
                name: '',
            },
            drawer_edit: false,
            edit_id : 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 100,
                    key: 'id',
                },
                {
                    title: '奥运会',
                    align: 'center',
                    key: 'season',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var season = currentRow.season;
                        return h('span', season.name );
                    }
                },
                {
                    title: '代表团',
                    align: 'center',
                    key: 'delegate',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var delegate = currentRow.delegate;
                        // return h('span', delegate.name );
                        return delegate.id > 0? [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    self.editDelegate(delegate.id);
                                }
                            } },  delegate.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    self.editDelegate(delegate.id);
                                }
                            } }, delegate.name,  )
                        ]: ''
                    }
                },
                {
                    title: '选手',
                    align: 'center',
                    key: 'athlete',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var athlete = currentRow.athlete;
                        // return h('span', athlete.name );
                        return athlete.id > 0? [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    self.editAthlete(athlete.id);
                                }
                            } },  athlete.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    self.editAthlete(athlete.id);
                                }
                            } }, athlete.name,  )
                        ]: ''
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton( self, h, params) ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        // end
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit = false;
            self.edit_id = 0;
            self.getData();
        },
        // end
        getData () {
            this.loading = true;
            var params = {
                // search_key: this.search_key,
                page: this.current_page,
                season_id: this.season.id,
                delegate_id: this.delegate.id,
            }
            getDelegationPlayerList(params).then(response => {
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add () {
            this.edit_id = 0;
            this.drawer_edit = true;
        },
        // end 
        editAthlete(_id){
            let self = this;
            self.edited_athlete_id = _id;
            self.modal_edit_athlete = true;
        },
        closeEditModalPlayer(){
            let self = this;
            self.edited_athlete_id = 0;
            self.modal_edit_athlete = false;
        },
        // end
        editDelegate(_id){
            let self = this;
            self.edited_delegate_id = _id;
            self.modal_edit_delegate = true;
        },
        closeEditModalDelegate(){
            let self = this;
            self.edited_delegate_id = 0;
            self.modal_edit_delegate = false;
        },
        // end
    },
    mounted () {
        this.getData();
    },
    watch: {
        "delegate.id"(){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        "season.id"(){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
    },
    computed: {}
}
</script>
