<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
    >
        <playerEdit :_id="edit_id" @closePage="closeEditDrawer"></playerEdit>
    </Drawer> 
    <Row style="margin-bottom: 10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="4" style="text-align: left">
            <Row>
                <Col span=5><span class="text-main" > 地区ID </span></Col>
                <Col span=18><areaSearch v-model="area"></areaSearch></Col>
            </Row>
        </Col>
        <Col span="4" style="text-align: left">
            <Row>
                <Col span=5><span class="text-main" > 大运动 </span></Col>
                <Col span=18><sportSearch v-model="sport"></sportSearch></Col>
            </Row>
        </Col>
        <Col span="12" style="text-align: right">
            <Button type="success"  @click="add">新增</Button>
        </Col>
    </Row>
    <fitTable 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getPlayerList } from '@/api/olympics/player';
import playerEdit from './edit.vue';
import moment from 'moment';
import areaSearch from '../area/search.vue';
import sportSearch from '../sport/search.vue';
import fitTable from '../../basic/table.vue';

const editButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                // type: "info" 
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "player-list",
    props: {
    },
    components: {
        playerEdit,
        areaSearch,
        sportSearch,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            edit_id : 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            area: {id:0, name:''},
            sport: {id:0, name:''},
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 80,
                    key: 'id',
                },
                {
                    title: '中文名称',
                    align: 'center',
                    key: 'name_zh',
                    minWidth: 80,
                },
                {
                    title: '英文名称',
                    align: 'center',
                    key: 'name_en',
                    minWidth: 80,
                },
                {
                    title: '简介',
                    align: 'center',
                    minWidth: 300,
                    key: 'summary',
                    render(h, params){
                        let currentRow = params.row;
                        return h('span', {class:"table-column-intro"}, currentRow.summary );
                    }
                },
                {
                    title: '出生地',
                    align: 'center',
                    key: 'birth_place',
                    minWidth: 80,
                },
                {
                    title: '地区',
                    align: 'center',
                    key: 'area',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var area = currentRow.area;
                        return h('span', area.name );
                    }
                },
                {
                    title: '身高',
                    align: 'center',
                    key: 'height',
                    minWidth: 80,
                },
                {
                    title: '体重',
                    align: 'center',
                    key: 'weight',
                    minWidth: 80,
                },
                {
                    title: '性别',
                    align: 'center',
                    key: 'gender',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h('span', currentRow.gender === 1? '男': (
                            currentRow.gender === 2? '女': "未知") );
                    }
                },
                {
                    title: '类型',
                    align: 'center',
                    key: 'type',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h('span', currentRow.type === 0? '个人': '团体' );
                    }
                },
                {
                    title: '生日',
                    align: 'center',
                    width: '100',
                    key: 'birthday',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h('span', currentRow.birthday>0? moment.unix(currentRow.birthday).format('YYYY-MM-DD'): '' );
                    }
                },
                {
                    title: '参赛信息',
                    align: 'center',
                    width: 200,
                    key: 'type',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let infos = currentRow.info || [];
                        if (infos && infos.length > 0){
                            var _tree = []
                            var sports_tree = [];
                            infos.forEach((element, _index) => {
                                let tree_data = {
                                    expand: true,
                                    children: [],
                                    render: (h, { root, node, data }) => {
                                        return [
                                            h('span',
                                                {
                                                    class: 'text-main',
                                                },
                                                element.season ? element.season.name : '' ,
                                            ),
                                        ]
                                    }
                                };
                                if (element.sport && element.sport.id > 0){
                                    let sport = {
                                        expand: true,
                                        children: [],
                                        render: (h, { root, node, data }) => {
                                            return [
                                                h(
                                                    'span',
                                                    {
                                                        class: 'text-main',
                                                    },
                                                    element.sport ? element.sport.name : '',
                                                )
                                            ]
                                        }
                                    }
                                    // 小运动项目
                                    let sub_sports = element.sub_sports;
                                    if (sub_sports && sub_sports.length > 0){
                                        sub_sports.forEach( sub_sport => {
                                            sport.children.push(
                                                {
                                                    // title: sub_sport.name,
                                                    render: (h, { root, node, data }) => {
                                                        return [
                                                            h(
                                                                'span',
                                                                {
                                                                    class: 'text-assist',
                                                                },
                                                                sub_sport.name ? sub_sport.name : '',
                                                            )
                                                        ]
                                                    }
                                                }
                                            )
                                        })
                                    }
                                    tree_data.children.push( sport );
                                    sports_tree.push( sport );  // 不展示奥运会
                                }
                                _tree.push( tree_data );
                            });
                            return h("Tree", 
                                {
                                    props: {
                                        // data: _tree,
                                        data: sports_tree,
                                    }
                                }
                            )
                        }else{
                            return h( 'span', '' )
                        }
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton( self, h, params) ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        // end
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit = false;
            self.edit_id = 0;
            self.getData();
        },
        // end
        getData () {
            this.loading = true;
            var params = {
                search_key: this.search_key,
                page: this.current_page,
                area_id: this.area.id,
                sport_id: this.sport.id,
            }
            getPlayerList(params).then(response => {
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add () {
            this.edit_id = 0;
            this.drawer_edit = true;
        },
        // end 
    },
    mounted () {
        this.getData();
    },
    watch: {
        // status_ids (value){
        // },
        "area.id"(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        "sport.id"(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
    },
    computed: {}
}
</script>
