<template>
  <div>
    <Modal
        v-model = "modal_add_relation"
        :title= "String(modal_edit_id) + ' 搜索' "
        @on-ok="addRelation"
    >
        <equipmentSearch :game_id="game_id" v-model="new_relation" ></equipmentSearch>
    </Modal>

    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="4" style="text-align: left">
            <Select v-model="source_id" @on-change="onSourceIdChange">
                <Option v-for="item in sources" :value="item.id" :key="item.id">{{item.name}}</Option>
            </Select>
        </Col>
        <Col span="4" style="text-align: left">
            <Checkbox v-model="filter" border>未关联</Checkbox>
        </Col>
    </Row>
    <fitTable
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getSources } from '@/api/relation/games/source';
import { updateDetail, deleteDetail } from '@/api/relation/games/detail';
import { getEquipments } from '@/api/relation/games/equipment';
import fitTable from '../../../basic/table.vue';
import equipmentSearch from '@/components/games/equipment/search.vue';

export default {
    name: "relation-equipment-list",
    props: {
        game_id: Number,   // 运动id
    },
    components: {
        fitTable,
        equipmentSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            filter: false,
            tid: 0,
            source_id: 0, 
            sources: {},
            modal_add_relation: false,
            modal_edit_id: 0,
            new_relation: {id:0, name:''},

            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width: 80,
                },
                // {
                //     title: '中文名称',
                //     align: 'center',
                //     key: 'name_zh',
                //     minWidth: 80,
                // },
                {
                    title: '英文名称',
                    align: 'center',
                    key: 'name_en',
                    minWidth: 80,
                    maxWidth: 300,
                },
                {
                    title: 'logo',
                    align: 'center',
                    key: 'logo',
                    minWidth: 80,
                    maxWidth: 200,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let logo = currentRow.logo;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: self.$unit.getImgGameUrl(self.game_id, self.game_id==1? 'equipment':'item', logo), shape: 'square', size: 'middle' },
                                class: {'logo': true} })],
                        ));
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '关联线上',
                    align: 'left',
                    key: 'relation',
                    minWidth: 120,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let relation = currentRow.relation ;
                        if (relation && relation.id > 0){
                            return [ h("Row", { attrs: { align: 'middle' }}, 
                                [h('Col', { attrs: { span: 4 }}, 
                                    [h('Poptip', {
                                            props: {
                                                placement: 'left-start',
                                                confirm: true,
                                                transfer: true,
                                                title: '确定删除关联？',
                                            },
                                            on: {
                                                'on-ok': () => {
                                                    self.delRelation(params.row.id, relation.relation_id);
                                                },
                                                'on-cancel': () => {
                                                }
                                            }
                                        }, 
                                        [h('Icon', 
                                            {   
                                                attrs: { 
                                                    type: "md-trash",
                                                    color: 'red' ,
                                                    size: 16,
                                                }, 
                                            },  
                                        )] 
                                    )],
                                ),
                                h('Col', { attrs: { span: 6 }}, 
                                    [h('span', 
                                        {   
                                            class: "text-link", 
                                        }, 
                                        relation.id,
                                    )] 
                                ),
                                h('Col', { attrs: { span: 8 }}, 
                                    [h('span', 
                                        {   
                                            class: "text-main", 
                                        }, 
                                        relation.name ,
                                    )]
                                ),
                                h('Col', { attrs: { span: 6 }}, 
                                    [h('img', { attrs: { src: self.$unit.getImgGameUrl(self.game_id, self.game_id==1? 'equipment':'item', relation.logo, true), shape: 'square', size: 'middle' },
                                        class: {'logo': true} })]
                                ),
                                ]
                            )]
                        }else{
                            return [ h("Row", 
                                [h('Col', { attrs: { span: 24 }}, 
                                    [h('Icon', 
                                        {   
                                            attrs: { 
                                                type: "md-add-circle", 
                                                color: 'green' ,
                                                size: 16,
                                            }, 
                                            on: {
                                                'click':() => {
                                                    // 新增关联
                                                    self.modal_add_relation = true;
                                                    self.modal_edit_id = params.row.id;
                                                    self.new_relation = {id:0, name:''};
                                                }
                                            } 
                                        },  
                                    )] 
                                )]
                            )]
                        }
                        
                    }
                },
                // {
                //     title: 'test称',
                //     align: 'center',
                //     key: 'relation',
                //     minWidth: 80,
                // },
            ],
            data: [],
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onSourceIdChange(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        },

        getSourceData () {
            let self = this;
            var params = {
                game_id: self.game_id,
            }
            getSources(params).then(response => {
                if (response.data.code == 0) {
                    let sources = response.data.data.list || [];
                    sources.forEach(element => {
                        self.sources[element.id] = element;
                        if (self.source_id == 0){
                            self.source_id = element.id;
                        }
                    });
                    self.getData();
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        getData () {
            let self = this;
            this.loading = true;
            var params = {
                game_id: self.game_id,
                search_key: self.search_key,
                page: self.current_page,
                source_id: self.source_id,
                filter: self.filter ? 1 : 0,
            }
            getEquipments(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.tid = response.data.data.tid;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        addRelation(){
            let self = this;
            // console.log(self.tid, self.uid, self.new_relation)
            if(self.tid > 0 && self.modal_edit_id > 0 
                    && self.new_relation && self.new_relation.id > 0){
                let params = {
                    // id: _id,
                    game_id: self.game_id,
                    tid: self.tid,
                    pid: self.source_id,
                    uid: self.modal_edit_id,
                    'data': self.new_relation.id,
                }
                // console.log(params)
                updateDetail(params).then(response => {
                    if (response.data.code == 0) {
                        var relation_id = response.data.data.id;
                        if (relation_id > 0){
                            self.new_relation['relation_id'] = relation_id;
                            self.data.forEach(element => {
                                if (element.id == this.modal_edit_id){
                                    // console.log(element)
                                    self.$set(element, 'relation', self.new_relation)
                                    return
                                }
                            })
                            self.$Message.success('添加成功');
                        }
                        
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }
        },
        // end
        delRelation(row_id, relation_id){
            let self = this;
            let params = {
                relation_id: relation_id,
            }
            deleteDetail(params).then(response => {
                if (response.data.code == 0) {
                    self.data.forEach(element => {
                        if (element.id == row_id){
                            // console.log(element)
                            self.$delete(element, 'relation')
                            return
                        }
                    })
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
    },
    mounted () {
        this.getSourceData();
        // console.log(this.source_id);
        // this.getData();
    },
    watch: {
        filter(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        }
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css"
</style>