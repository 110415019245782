<template>
    <div>
        <Drawer
            v-model="drawer_edit"
            width="500"
            :mask-closable="true"
            :closable="false"
        >
            <userGroupEdit :group_id="edit_id" @closePage="closeEdit"></userGroupEdit>
        </Drawer> 
        <Button type="primary" @click="edit_id=0;drawer_edit=true;" long>新增组</Button>
        <fitTable
            :columns="columns" 
            :data="data" 
            :loading="loading" 
            :header_height=130
        >
        </fitTable>
        <Row>
            <Button v-if="load_more_show" type="primary" :disabled="load_more_disabled" @click="getData" long>加载更多</Button>
        </Row>
    </div>
</template>
<script>

import { getAdminUserGroups, updateAdminUserGroup, delAdminUserGroup } from '@/api/admin';
import fitTable from '../../basic/table.vue';
import userGroupEdit from './edit.vue';
// import {asyncRouterMap} from '@/router/index.js';
// console.log("user_group/list.vue", asyncRouterMap);

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
            props: {
                confirm: true,
                title: '您确定要删除这个账号吗?',
                transfer: true
            },
            on: {
                'on-ok': () => {
                    vm.delete(params.row.id, params.index);
                }
            }
        },
        [
            h(
                'Button',
                {
                    style: {
                        margin: '2px 2px'
                    },
                    props: {
                        type: 'warning',
                        size: 'small'
                    }
                },
                '删除'
            )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    // let query = { sport_id: vm.sport_id, player_id: params.row.id };
                    // let resolve_data = vm.$router.resolve({ path: '/ballsports/tennis/player/detail', query: query });
                    // window.open(resolve_data.href, '_blank');
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "admin-user_group-list",
    props: {
    },
    components: {
        fitTable,
        userGroupEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            edit_id: 0,
            drawer_edit: false,
            cursor: 0,
            loading: true,
            load_more_disabled: false,
            load_more_show: true,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width: 100,
                },
                {
                    title: '名称',
                    align: 'center',
                    key: 'name',
                    minWidth: 100,
                },
                {
                    title: '权限',
                    align: 'left',
                    key: 'permissions',
                    minWidth: 350,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let permissions = currentRow.permissions;
                        if (permissions === true){
                            return h('span', '最高权限')
                        }
                        // console.log(permissions)
                        if (permissions.length == 0){
                            return '无权限'
                        }
                        var trees = [];
                        var arrr = [];
                        var result = permissions.reduce(function(pre,item,index,arr){
                            var begin =index*5;
                            var end = begin+5;
                            var res = arr.slice(begin,end); 
                            if(res.length != 0){
                                arrr[index] = res;
                            }
                            return arrr;
                        },[]);
                        // console.log(result)
                        result.forEach(element => {
                            trees.push( 
                                h("Col", { attrs:{'span': 5} }, [h("Tree", { props: {data:element} } ) ])
                            )
                        })
                        console.log(trees);
                        return h("Row", trees);
                    }
                },
                {
                    title: '子模块',
                    align: 'left',
                    key: 'sub_webs',
                    width: 120,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return [
                            h('Checkbox', { //数据库1是已处理，0是未处理
                                props: {
                                    type: 'primary',
                                    value: currentRow.permissions_portal, //控制开关的打开或关闭状态，官网文档属性是value
                                    disabled: currentRow.id === 1,
                                },
                                style: {
                                    marginRight: '5px'
                                },
                                on: {
                                    'on-change': (value) => { //触发事件是on-change,用双引号括起来，
                                        //参数value是回调值，并没有使用到
                                        // this.switch(params.index) //params.index是拿到table的行序列，可以取到对应的表格值
                                        // console.log(self.game_id, currentRow.id, value)
                                        self.update(currentRow.id, {"permissions_portal": value})
                                    }
                                }
                            }, '纳米'),
                            h('Checkbox', { //数据库1是已处理，0是未处理
                                props: {
                                    type: 'primary',
                                    value: currentRow.permissions_thesports, //控制开关的打开或关闭状态，官网文档属性是value
                                    disabled: currentRow.id === 1,
                                },
                                style: {
                                    marginRight: '5px'
                                },
                                on: {
                                    'on-change': (value) => { //触发事件是on-change,用双引号括起来，
                                        //参数value是回调值，并没有使用到
                                        // this.switch(params.index) //params.index是拿到table的行序列，可以取到对应的表格值
                                        // console.log(self.game_id, currentRow.id, value)
                                        self.update(currentRow.id, {"permissions_thesports": value})
                                    }
                                }
                            }, 'thesports'),
                        ]
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        if (params.row.id === 1){
                            return []
                        }
                        return [ editButton(self, h, params), delButton(self, h, params) ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        // end
        getData () {
            let self = this;
            this.loading = true;
            // 搜索日期
            var params = {
                cursor: self.cursor,
            }
            getAdminUserGroups(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    // this.data = response.data.data.list;
                    if (response.data.data.list.length > 0){
                        response.data.data.list.forEach(element => {
                            this.data.push(element);
                        })
                    }
                    this.cursor = response.data.data.cursor;
                    if (response.data.data.cursor == 0){
                        self.load_more_disabled = true;
                        self.load_more_show = false;
                    }
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        delete (_id, _index) {
            let self = this;
            // 搜索日期
            var params = {
                'id': _id,
            }
            delAdminUserGroup(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    self.data.splice( _index, 1);
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        update(id, params) {
            let self = this;
            let post_params = {
                id: id,
                data_dic: params,
            };
            // console.log(params);
            updateAdminUserGroup(post_params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        closeEdit(data){
            let self = this;
            self.drawer_edit = false;
            if (data){
                // 更新或者新增数据
                if (data.id){
                    var update_status = false;
                    self.data.forEach(element => {
                        if (element.id === data.id){
                            var data_dic = data.data_dic
                            element.name = data_dic.name;
                            element.permissions = data_dic.permissions;
                            element.permissions_portal = data_dic.permissions_portal;
                            element.permissions_thesports = data_dic.permissions_thesports;
                            update_status = true;
                        }
                    })
                    // 为找到固定id 新增一条
                    if (!update_status){
                        var data_dic = data.data_dic
                        data_dic['id'] = data.id
                        self.data.unshift(data_dic);
                    }
                }else{
                    // 新增
                    self.data.unshift(data.data_dic);
                }
            }
        },
        // end
    },
    mounted () {
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>