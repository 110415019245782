<template>
    <div>
        <matchList :game_id="game_id"></matchList>
    </div>
</template>

<script>

import matchList from '@/components/games/match/list.vue';

export default {
    components: {
        matchList
    },
    data () {
        let self = (window.x = this)
        return {
            game_id: 2,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>