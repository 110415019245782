<template>
  <div>
    <Modal
        v-model="modal_add_relation"
        :title="modal_add_title"
        @on-ok="modal_add_on_ok"
    >
        <Form >
            <FormItem>
                <Row>
                    <Col span="8">
                        <Select v-model="new_relation.source_id" >
                            <Option v-for="(item, index) in sources[ new_relation.type] || []" :value="item.id" :key="index">{{ item.name }}</Option>
                        </Select>
                    </Col>
                    <Col span="6">
                        <DatePicker type="date" placeholder="日期" v-model="new_relation.match_date"></DatePicker>
                    </Col>
                    <Col span="6">
                        <Input placeholder="关键字" v-model="new_relation.search_key"></Input>
                    </Col>
                    <Col span="4">
                        <!-- <Button type="primary" icon="ios-search" @click="onSearchSourceMatch" long></Button> -->
                        <Button type="primary" icon="ios-search" @click="onSearchSourceMatch">搜索</Button>
                    </Col>
                </Row>
            </FormItem>
        </Form>

        <Row class-name="relation-row" v-for="(item, index) in new_relation.matches" :key="index">
            <Col class-name="relation-head" span="2"><Radio :value="new_relation.match_id===item.match_id" @on-change="(event) => {onRadioClick(event, item)}" ></Radio></Col>
            <Col class-name="relation-head" span="4">{{item.match_id}}</Col>
            <Col class-name="relation-content" span="8">{{item.match_time_str}}</Col>
            <Col class-name="relation-content" span="10">{{item.home.name}} vs {{item.away.name}}</Col>
        </Row>
    </Modal>
    <Card :bordered="false" :padding="0" :dis-hover="true">
        <p slot="title">
            赛程关联
        </p>
        <a href="#" slot="extra" @click.prevent="addMatchRelation">
            <Icon type="md-add"></Icon>
            添加关联
        </a>
        <Row class-name="relation-row" v-for="(item, index) in r_matches" :key="index">
            <Col class-name="relation-head" span="4">{{item.source_name}}</Col>
            <Col class-name="relation-content" span="8">{{item.match_time_str}}</Col>
            <Col class-name="relation-content" span="10">{{item.home.name}} vs {{item.away.name}}</Col>
            <Col class-name="relation-close" span="2"> <Icon @click.native="rmRelation(1, item, index)" type="ios-close-circle-outline" /></Col>
        </Row>
    </Card>
    <br />
    <Card :bordered="false" :padding="0" :dis-hover="true">
        <p slot="title">
            指数关联
        </p>
        <a href="#" slot="extra" @click.prevent="addOddRelation">
            <Icon type="md-add"></Icon>
            添加关联
        </a>
        <Row class-name="relation-row" v-for="(item, index) in r_odds" :key="index">
            <Col class-name="relation-head" span="4">{{item.source_name}}</Col>
            <Col class-name="relation-content" span="9">{{item.match_time_str}}</Col>
            <Col class-name="relation-content" span="9">{{item.home.name}} vs {{item.away.name}}</Col>
            <Col class-name="relation-close" span="2"> <Icon @click.native="rmRelation(2, item, index)" type="ios-close-circle-outline" /></Col>
        </Row>
    </Card>

    <br />
    <div class="drawer-footer">
        <!-- <Button style="margin-right: 8px" @click="value3 = false">Cancel</Button>
        <Button type="primary" @click="value3 = false">Submit</Button> -->
        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>

  </div>
</template>
<script>

import { getMatchRelation,  updateMatchSources } from '@/api/ballsports/base/match';
import { getSourceList, getSourceMatch } from '@/api/ballsports/base/source';
import moment from 'moment';

export default {
    name: "match-relation",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
        // imgEditor
    },
    data () {
        let self = (window.x = this)
        return {
            loading: true,
            modal_add_relation: false,
            r_matches : [],     // 比赛关联
            r_odds: [],         // 指数关联
            // teams_vs_str: '',   // 主队 vs 客队名称
            modal_add_title: '', // 模态框标题
            new_relation: {
                source_id: '',    // 源id
                match_date: '',      // 比赛时间 用于筛选
                search_key: '',     // 
                type: 0,
                matches: [],    // 搜索到的比赛列表
                match_id: 0,    //
            },
            sources: {},       // 第三方源列表
            sources_map : {},   // 源对应的名称
        }
    },
    methods: {
        getData () {
            let self = this;
            this.loading = true
            // 搜索日期
            var params = {
                sport_id: this.sport_id,
                match_id: this.match_id,
            }
            getMatchRelation( params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    // this.data = response.data.data;
                    self.r_matches = response.data.data.matches;
                    self.r_odds = response.data.data.odds;

                    self.r_matches.forEach(element => {
                        element["match_time_str"] = moment.unix(element.match_time).format('YYYY-MM-DD HH:mm');
                    });
                    self.r_odds.forEach(element => {
                        element["match_time_str"] = moment.unix(element.match_time).format('YYYY-MM-DD HH:mm');
                    });
                    self.loading = false
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        rmRelation(_type, item, index){
            let self = this;
            // console.log("delete: ", _type, item, index)
            if (_type === 1){
                // 比赛关联
                self.r_matches.splice(index, 1);
            }else if (_type === 2){
                // 比赛关联
                self.r_odds.splice(index, 1);
            }else{
                self.$Message.error("未知操作")
            }
        },
        _initMatchRelation(){
            this.new_relation = {
                source_id: '',    // 源id
                match_date: '',      // 比赛时间 用于筛选
                search_key: '',     // 
                type: 0,
                matches: [],    // 搜索到的比赛列表
                match_id: 0,    //
                relation: null,   // 新的关联关系数据
            }
        },
        addMatchRelation(){
            let self = this;
            self._initMatchRelation();
            self.modal_add_title = "赛程关联"
            self.new_relation.type = 1;
            self.modal_add_relation = true;
        },
        addOddRelation(){
            let self = this;
            self._initMatchRelation();
            self.modal_add_title = "指数关联";
            self.new_relation.type = 2;
            self.modal_add_relation = true;
        },
        // end 
        onSearchSourceMatch(){
            let self = this;
            var params = {
                sport_id: self.sport_id,
                source_id: self.new_relation.source_id,
                date: parseInt( self.new_relation.match_date.valueOf() * 0.001 ) ,
                search_key: self.new_relation.search_key,
                type: self.new_relation.type,
            }
            getSourceMatch( params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    self.new_relation.matches = response.data.data.list;
                    self.new_relation.matches.forEach(element => {
                        element["match_time_str"] = moment.unix(element.match_time).format('YYYY-MM-DD HH:mm');
                    });
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        onRadioClick(value, item){
            let self = this;
            // console.log(value, item)
            if (value){
                self.new_relation.match_id=item.match_id;
                self.new_relation.relation = Object.assign({
                    source_id: self.new_relation.source_id,
                    source_name: self.sources_map[ self.new_relation.type ][self.new_relation.source_id],
                }, item);
                // console.log(self.new_relation.relation);
            }else{
                self.new_relation.match_id = 0;
                self.new_relation.relation = null;
            }
        },

        modal_add_on_ok () {
            let self = this;
            // this.new_relation = {
            //     source_id: '',    // 源id
            //     match_date: '',      // 比赛时间 用于筛选
            //     search_key: '',     // 
            //     type: 0,
            //     matches: [],    // 搜索到的比赛列表
            //     match_id: 0,    //
            // }
            var relation = self.new_relation.relation;
            if (relation){
                if (self.new_relation.type == 1){
                    // 赛程新增
                    self.r_matches.push(relation);
                }else if (self.new_relation.type == 2){
                    // 指数新增
                    self.r_odds.push(relation);
                }
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            // self.match_id = 0; 不能直接修改probs传参
            self.emit("cancle")
        },
        save() {
            let self = this;
            var m_list = []
            self.r_matches.forEach(element => {
                m_list.push( [element.source_id, element.match_id] )
            })
            var odd_list = []
            self.r_odds.forEach(element => {
                odd_list.push( [element.source_id, element.match_id] )
            })
            var data = [ [1, m_list], [2, odd_list] ];
            var params = {
                sport_id: self.sport_id,
                match_id: self.match_id,
                relations: data,
            }
            updateMatchSources(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end 
    },
    mounted () {
    },
    watch: {
        match_id(value){
            let self = this;
            if (value){
                // console.log("Relation Get Data", value);
                this.getData();
            };
            // 获取源的列表
            if ( ! self.sources[1] ) {
                var params = {
                    sport_id: self.sport_id,
                    type: 1
                }
                getSourceList(params).then(response => {
                    self.sources_map[1] = {};
                    if (response.data.code == 0) {
                        self.$set(this.sources, 1, response.data.data[1])
                        response.data.data[1].forEach(element => {
                            self.sources_map[1][element.id] = element.name
                        });
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }
            if ( !self.sources[2] ) {
                var params = {
                    sport_id: self.sport_id,
                    type: 2
                }
                getSourceList(params).then(response => {
                    if (response.data.code == 0) {
                        self.sources_map[2] = {};
                        self.$set(this.sources, 2, response.data.data[2]);
                        response.data.data[2].forEach(element => {
                            self.sources_map[2][element.id] = element.name
                        });
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }
        },
        // end 
    },
    computed: {
        
    }
}
</script>
<style scoped>
    @import "relation.css"
</style>