import http from '@/utils/http';


export const getTeams = (parameter) => {
  return http.request({
    url: '/v1/relation/games/team/list',
    method: 'get',
    params: parameter
  })
}

export const searchTeam = (params) => {
  return http.request({
    url: '/v1/relation/games/team/search',
    method: 'post',
    data: params,
  })
}