<template>
    <div>
        <Row>
            <Col span=8>
                <Select v-model="source_id" @on-change="onSourceIdChange">
                    <Option v-for="item in sources" :value="item.id" :key="item.id">{{item.name}}</Option>
                </Select>
            </Col>
            <Col span=8>
                <DatePicker type='date' format="yyyy-MM-dd" :value="date"  placeholder="选择日期" @on-change="date=$event; onDateChange()" ></DatePicker>
            </Col>
        </Row>
        <Row >
            <AutoComplete
                v-model="search_key"
                placeholder="搜索"
                @on-change = "change"
                @on-blur  = 'blur'
                @on-focus  = 'focus'
                :class="classname"
            >
                <Scroll :on-reach-bottom="handleReachBottom" :height="300" :distance-to-edge="[10, 10]">
                    <Option v-for="option in datas" :value="search_key" :tag="option.id" :key="option.id" @click.native="onClicked(option)">
                        <span class="text-link">{{ option.id }}:</span> 
                        <span class="text-main"> {{ transferMatchTime( option.match_time) }}</span>
                        <span class="text-assist"> [{{ option.tournament ? option.tournament.name : '' }}] </span>
                        <span class="text-main">{{ option.home.name }} vs {{ option.away.name }}</span>
                    </Option>
                </Scroll>
            </AutoComplete>
        </Row>
        <Row >
            <Col span="3" >
                <span class="text-link">{{ value.id }}</span>
            </Col>
            <Col span="6" >
                <span class="text-main">{{ transferMatchTime( value.match_time) }}</span>
            </Col>
            <Col span="5" >
                <span class="text-assist">{{ value.tournament ? value.tournament.name : '' }} </span>
            </Col>
            <Col span="10" >
                <span class="text-main">{{ value.home.name }} vs {{ value.away.name }}</span>
            </Col>
        </Row>
    </div>
</template>

<script>

import { getSources } from '@/api/relation/games/source';
import { searchMatch } from '@/api/relation/games/match';
import moment from 'moment';

export default {
    name: "match-search",
    props: {
        game_id  : Number, 
        value   : Object,    // {id, name, logo}
        classname : String,
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            search_key: '',
            date: '',
            data: {
                'id': 0,
                'home': {},
                'away': {},
                tournament: {},
            },
            datas: [],
            sources: {},
            source_id: 0,
            loading: false,
            page: 1,
        }
    },
    methods: {
        onSourceIdChange(){
            this.page = 1;
            this.datas = []
            this.doSearch();
        },
        onDateChange(){
            this.page = 1;
            this.datas = []
            this.doSearch();
        },
        change(value){
            let self = this;
            self.page= 1;
            self.doSearch();
        },
        focus(){
            let self = this;
            if (this.datas.length == 0){
                self.doSearch();
            }
        },
        getSourceData () {
            let self = this;
            var params = {
                game_id: self.game_id,
            }
            getSources(params).then(response => {
                if (response.data.code == 0) {
                    let sources = response.data.data.list || [];
                    sources.forEach(element => {
                        self.sources[element.id] = element;
                        if (self.source_id == 0){
                            self.source_id = element.id;
                        }
                    });
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        doSearch () {
            let self = this;
            // console.log("PLAYER SEARCH:", value,  self.date);
            // if ( value || this.date ){
                self.loading = true;
                if(this.date){
                    var new_date = moment( this.date).unix();
                }else{
                    var new_date = 0;
                }
                let params = {
                    game_id: self.game_id,
                    search_key: self.search_key,
                    date: new_date,
                    source_id: self.source_id,
                    page: self.page
                }
                searchMatch(params).then(response => {
                    if (response.data.code == 0) {
                        if (self.page == 1){
                            self.datas = response.data.data.list;
                        }else{
                            self.datas = self.datas.concat(response.data.data.list)
                        }
                    } else {
                        this.$Message.error(response.data.msg);
                    }
                })
                self.loading = false;
            // }else if (value ){
            //     // self.datas = []
            //     // self.datas.push( self.data )
            // }
        },
        // end
        onClicked(data){
            // console.log("onClicked(option)", data)
            let self = this;
            self.data = data;
            self._emit();
            this.$emit('onChooseMatch', this.data);
            self.search_key = ''; // self.data.name;
        },
        // end
        _emit(){
            this.$emit('input', this.data);
        },
        // end
        blur(){
            // 失去焦点时触发
            let self = this;
            if (self.search_key){
                self.search_key = self.data.name;
            }else{
                self.data = {
                    'id': 0,
                    'home': {},
                    'away': {},
                };
                // self.datas = [];
            }
            self._emit();
        },
        // end
        transferMatchTime(tsp){
            if (tsp){
                return moment.unix(tsp).format('YYYY-MM-DD HH:mm');
            }else{
                return '';
            }
        },
        // end 
        handleReachBottom(){
            let self = this;
            self.page += 1
            // console.log("page", self.page)
            self.doSearch();
        },
        // end 
    },
    mounted () {
    },
    watch: {
        'value': {
            handler: function(value){
                this.data = value;
                // this.search_key = value.name;
            },
            deep: true,
        },
        // end 
        game_id: {
            handler: function(value){
                // alert(value);
                // this.search_key = value.name;
                this.getSourceData();
            },
            deep: true,
            immediate: true,
        }
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "search.css" */
</style>