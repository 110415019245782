// import * as mqtt from './mqtt.min.js' ;
import mqtt from 'mqtt';
// npm install @msgpack/msgpack --save
import { decode } from "@msgpack/msgpack";


// import protobuf from 'protobufjs'
// const root = protobuf.Root.fromJSON(require('@/config/modal.json')).resolveAll()
// let Response = root.lookup('cfesports.api.base.Response')

let Client = function (opt) {
    let that = this
    that.opt = {
        id: '',
        host: 'wss://push.cfesport.com',
        port: '8099',
        route: '',
        topic: '',
        initCallback: '',
        message: ''
    }
    that.opt = { ...that.opt, ...opt }
    that.init()
    return that
}

Client.prototype = {
    client: '',
    init: function () {
        let that = this;
        that.client = mqtt.connect(that.opt.host + ':' + that.opt.port + "/" + that.opt.route, 
            {
                reconnectPeriod:1*1000,  
                clean: true, 
                connectTimeout: 30*1000,
                clientId: 'clientId-'+Math.random(), 
                keepalive: 300,
            }
        );
        if (typeof that.opt.topic == "object") {
            that.opt.topic.forEach(function (item) {
                that.client.subscribe(item);
            });
        } else {
            that.client.subscribe(that.opt.topic);
        }
        that.client.on('error', function (err) {
            console.log("error");
        })
        that.client.on('offline', function () {
            console.log('lost connection!')
        })
        that.client.on('disconnect', function (a) {
            console.log('disconnect!' + a)
        })
        that.client.on('close', function () {
            console.log('close!')
            // that.client.end();
        })
        that.client.on('connect', function () {
            console.log('链接成功')
            if (that.opt.connect) that.opt.connect();
        })
        that.client.on("message", function (topic, payload) {
            try {
                let message = decode(payload)
                // console.log(payload, message)
                // if (message.type === 2){
                //     console.log(payload, message)
                // }
                if (that.opt.message) that.opt.message(topic, message);
            } catch (e) {
                console.log('er' + e)
            }
        });
        that.client.on('reconnect', function () {
            console.log("reconnect");
        });
        if (that.opt.initCallback) that.opt.initCallback(that);
    },
    subscribe: function (topic, options, callback = function () { }) {
        this.client.subscribe(topic, Object.assign({ qos: 0 }, options), (err) => {
            if (err) {
                console.log('订阅失败', topic)
            } else {
                console.log('订阅成功', topic)
            }
            callback(err)
        })
    },
    unsubscribe: function (topic, options, callback = function () { }) {
        this.client.unsubscribe(topic, topic, Object.assign({ qos: 0 }, options), (err) => {
            if (!err) {
                console.log('取消订阅成功', topic)
                callback()
            } else {
                console.log('取消订阅失败', topic)
            }
        })
    },
    end: function () {
        console.log('end!')
        this.client.end()
    }
}

export default Client
