<template>
    <div>
        <Modal
            v-model="modal1"
            title="新增英雄"
            @on-ok='addHero'
            >
            <heroSearch v-model="hero" :game_id="game_id" ></heroSearch>
        </Modal>
        <SlickList
            :lockToContainerEdges="true"
            axis="x"
            lockAxis="x"
            v-model="items"
            class="SortableList"
            @input="getChangeLists"
            helperClass="moving-item"
            :distance="-1"
        >
            <SlickItem
                v-for="(item, index) in items || []"
                :index="index"
                :key="index"
            >
                <!-- <Badge>
                    <img class='item-logo' :src="fullLogo(game_id, 'hero', item.logo)">
                    <Icon class='item-icon' color="#ed4014" size=24 slot="count" type="ios-close" @click="removeHero(index)" ></Icon>
                </Badge> -->
                <Tooltip :content="item.id + item.name">
                    <img class='item-logo' :src="fullLogo(game_id, 'hero', item.logo)">
                </Tooltip>
                <Row justify='center'>
                    <Icon type="ios-trash-outline" @click="removeHero(index)"></Icon>
                </Row>
            </SlickItem>
            <Button shape="circle" icon="ios-add" @click="searchHero()" ></Button>
        </SlickList>
    </div>
</template>

<script>

import {getImgGameUrl} from '@/utils/util';
import { SlickList, SlickItem } from 'vue-slicksort';  // 引入
import heroSearch from '../hero/search.vue';

export default {
    name: 'heros-dragable',
    components: {
        SlickItem,
        SlickList,
        heroSearch,
    },
    props: ['value', 'game_id'],
    data () {
        return {
            modal1: false,
            hero: {id:0, name:'', logo:''},
            items: [],
        }
    },
    watch:{
        value(val){
            // console.log("value changed", val);
            this.items=val;
        }
    },
    mounted(){
        // console.log("1 mounted")
    },
    methods: {
        fullLogo(game_id, table, logo){
            return (getImgGameUrl(game_id, table, logo))
        },
        updateValue(value){
            this.$emit('input', value);
        },
        getChangeLists(vals) {
            this.updateValue(vals)
        },
        // end
        removeHero(index){
            // 删除英雄
            let self = this;
            // console.log(index);
            self.items.splice(index, 1);
            self.updateValue(self.items);
        },
        searchHero(){
            let self = this;
            // console.log('1aaa');
            self.hero = {id:0, name:'', logo:''};
            self.modal1 = true;
        },
        addHero(){
            let self = this;
            if (self.hero.id > 0){
                self.items.push(this.hero);
                self.updateValue(self.items);
            }
        },
        // end 
    }
}
</script>
<style scoped>

.item-logo {
    height: 50px;
    width: 40px;
    /* padding: 2px; */
    object-fit: cover;
    margin-right: 5px;
}
.SortableList {
    display: flex;
    white-space: nowrap;
    max-height: 80vh;
    margin: 0 auto;
    padding: 0;
    overflow: auto;
    border-radius: 3;
}

.moving-item{
    /* 移动到最顶层 */
    z-index: 9999;
}

.demo-upload-list-cover{
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.6);
}
.demo-upload-list-cover i{
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
}

.item-icon{
    /* z-index: 9999; */
    /* background: #; */
    margin-top: 2px;
}
</style>
