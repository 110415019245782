<template>
    <Row>
        <Col span="4" >
            <span class="text-link">{{ value? value.id: "" }} : </span>
            <!-- <span class="text-link">{{ value.id }} : </span> -->
        </Col>
        <Col span="20">
            <AutoComplete
                v-model="search_key"
                @on-change = "doSearch"
                @on-blur  = 'blur'
                @on-focus = 'focus'
                @on-clear = 'clear'
                :class="classname"
                clearable
            >
                <Option v-for="option in datas" :value="option.name" :tag="option.id" :key="option.id" @click.native="onClicked(option)">
                    <span class="text-link">{{ option.id }}:</span> <span class="text-main">{{ option.name }}</span>
                </Option>
            </AutoComplete>
        </Col>
    </Row>
</template>

<script>

import { searchPromotion } from '@/api/ballsports/base/promotion';

export default {
    name: "promotion-search",
    props: {
        sport_id  : Number, 
        value     : Object,    // {id, name, logo}
        classname : String,
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            search_key: self.value? self.value.name : "",
            data: self.value? self.value: {
                'id': 0,
                'name': '',
                'color': '',
            },
            datas: {},
            loading: false,
        }
    },
    methods: {
        clear(){
            let self = this;
            // console.log("clear");
            self.data = {
                id:0,
                name: '',
                color: ''
            };
            self._emit();
        },
        focus(){
            let self = this;
            // console.log("on focus")
            self.loading = true;
            let params = {
                sport_id: self.sport_id,
                search_key: '',
            }
            searchPromotion(params).then(response => {
                if (response.data.code == 0) {
                    self.datas = response.data.data.list;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
            self.loading = false;
        },
        doSearch (value) {
            let self = this;
            // console.log("promotion SEARCH:", value,  self.value);
            self.$unit.debounce( () => {
                if (value !== self.data.name){
                    self.loading = true;
                    let params = {
                        sport_id: self.sport_id,
                        search_key: value,
                    }
                    searchPromotion(params).then(response => {
                        if (response.data.code == 0) {
                            self.datas = response.data.data.list;
                        } else {
                            this.$Message.error(response.data.msg);
                        }
                    })
                    self.loading = false;
                }else if (value && ( value === self.data.name)){
                    self.datas = {}
                    self.datas[self.data.id]= self.data
                }
            }, 300, 'search_promotion')
        },
        // end
        onClicked(data){
            // console.log("onClicked(option)", option)
            let self = this;
            self.data = data;
            self._emit();
            self.search_key = self.data.name;
        },
        // end
        _emit(){
            this.$emit('input', this.data);
        },
        // end
        blur(){
            // 失去焦点时触发
            let self = this;
            if (self.search_key){
                self.search_key = self.data.name;
            }else{
                self.data = {
                    id:0,
                    name: '',
                    color: ''
                };
                self.datas = [];
            }
            self._emit();
        },
        // end
    },
    mounted () {
        // console.log(this.sport_id, this.data);
    },
    watch: {
        'value': {
            handler: function(value){
                this.data = value;
                this.search_key = value.name;
                // console.log(this.search_key);
            },
            deep: true,
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "search.css" */
</style>