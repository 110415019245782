<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Row type="flex" justify="end">
            <Col class="text-main" span="1" >
                源 : 
            </Col>
            <Col span="4">
                <Select v-model="current_source_id" @on-change="on_source_id_change">
                    <Option value="1" key="1">手动</Option>
                    <Option v-for="item in third_events" :value="item.source_id" :key="item.source_id">{{ item.source_name }}</Option>
                </Select>
            </Col>
            <Col span="2">
                <Button type="primary" long @click="api_save">保存</Button>
            </Col>
        </Row>

        <Table 
            :columns="event_columns" 
            :data="events"
            height=300
        >
        </Table>

        <div style="max-height: 300px;">
            <Row>
                <Col class="text-assist" span="2">展示源:</Col> 
                <Col class="text-assist" span="4">
                    <Select v-model="third_source_id">
                        <Option v-for="item in third_events" :value="item.source_id" :key="item.source_id">{{ item.source_name }}</Option>
                    </Select>
                </Col>
            </Row>
            <br>
            <Row class="text-main" v-for="(item, index) in third_events[third_source_id]? third_events[third_source_id].list : []" :key="index">
                <!-- {{item}} -->
                {{item.pan}} | {{item.team==1? '主队': (item.team==2? '客队': '无')}} | {{item.timer}} | {{ item.score.join('-') }} |
                {{item.player.name}} | 助攻1:{{item.assists_1? item.assists_1.name: ''}} | 助攻2:{{item.assists_2? item.assists_2.name: ''}} |
            </Row>
        </div>
    </div>
</template>
<script>

import { getMatchEvent } from '@/api/ballsports/base/match';
import Lock from '../lock.vue';

export default {
    name: "detail-stat",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
        Lock,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 1, //比赛
            type: 3, // 类型id
            spinShow: false,
            locks: {},
            events: [],    // 事件
            event_columns: [
                {
                    title: '小节',
                    align: 'center',
                    key: 'pan',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row;
                        // console.log(self.locks, String(self.type) + '-' + currentRow.id, self.locks[String(self.type) + '-' + currentRow.id] == 1)
                        return h(
                            'i-input', {
                                props:{
                                    value: currentRow.pan,
                                },
                                attrs:{
                                    class: self.locks[String(self.type) + '-' + currentRow.id] == 1 ? 'locked-input': '',
                                },
                                on: {
                                }
                            },
                        )
                    }
                },
                {
                    title: '队伍',
                    align: 'center',
                    key: 'team',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row
                        return h(
                            'i-select', {
                                class: self.locks[String(self.type) + '-' + currentRow.id] == 1 ? 'locked-select': '',
                                props:{
                                    value: currentRow.team,
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.id] == 1,
                                },
                                on: {
                                    'on-change':(event) => {
                                        console.log(event);
                                        // self.selectData[params.index].style = event;
                                        // self.$refs.selectDataTable.objData[params.index]['style'] = value;
                                        // this.data[params.index].volumeType = event;
                                    }
                                }
                            },[
                                h('Option',{
                                    props: {
                                        value: 0
                                    }
                                },'无'),
                                h('Option',{
                                    props: {
                                        value: 1
                                    }
                                },'主队'),
                                h('Option',{
                                    props: {
                                        value: 2
                                    }
                                },'客队'),
                            ]
                        );
                    }
                },
                {
                    title: '时间',
                    align: 'center',
                    key: 'timer',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row
                        var timer = currentRow.timer;
                        return h(
                            'i-input', {
                                props:{
                                    value: '11:20',
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.id] == 1,
                                },
                                attrs:{
                                    class: self.locks[String(self.type) + '-' + currentRow.id] == 1 ? 'locked-input': '',
                                },
                                on: {
                                }
                            },
                        )
                    }
                },
                {
                    title: '主分',
                    align: 'center',
                    // key: 'timer',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row
                        var score = currentRow.score;
                        return h(
                            'i-input', {
                                props:{
                                    value: score[0],
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.id] == 1,
                                },
                                attrs:{
                                    class: self.locks[String(self.type) + '-' + currentRow.id] == 1 ? 'locked-input': '',
                                },
                                on: {
                                }
                            },
                        )
                    }
                },
                {
                    title: '客分',
                    align: 'center',
                    // key: 'timer',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row
                        var score = currentRow.score;
                        return h(
                            'i-input', {
                                props:{
                                    value: score[1],
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.id] == 1,
                                },
                                attrs:{
                                    class: self.locks[String(self.type) + '-' + currentRow.id] == 1 ? 'locked-input': '',
                                },
                                on: {
                                }
                            },
                        )
                    }
                },
                {
                    title: '球员',
                    align: 'center',
                    key: 'player',
                    width: 150,
                    render: function (h, params) {
                        let currentRow = params.row
                        var player = currentRow.player;
                        return h(
                            'i-input', {
                                props:{
                                    value: player['name'],
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.id] == 1,
                                },
                                attrs:{
                                    class: self.locks[String(self.type) + '-' + currentRow.id] == 1 ? 'locked-input': '',
                                },
                                on: {
                                }
                            },
                        )
                    }
                },
                {
                    title: '类型',
                    align: 'center',
                    key: 'score_type',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h(
                            'i-select', {
                                class: self.locks[String(self.type) + '-' + currentRow.id] == 1 ? 'locked-select': '' ,
                                props:{
                                    value: currentRow.score_type,
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.id] == 1,
                                },
                                on: {
                                    'on-change':(event) => {
                                        console.log(event);
                                    }
                                }
                            },[
                                h('Option',{
                                    props: {
                                        value: 0
                                    }
                                },'无'),
                                h('Option',{
                                    props: {
                                        value: 1
                                    }
                                },'进球'),
                                h('Option',{
                                    props: {
                                        value: 2
                                    }
                                },'罚球'),
                            ]
                        );
                    }
                },
                {
                    title: '助攻1',
                    align: 'center',
                    key: 'assists_1',
                    width: 150,
                    render: function (h, params) {
                        let currentRow = params.row
                        var player = currentRow.assists_1;
                        return h(
                            'i-input', {
                                props:{
                                    value: player['name'],
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.id] == 1,
                                },
                                attrs:{
                                    class: self.locks[String(self.type) + '-' + currentRow.id] == 1 ? 'locked-input': '',
                                },
                                on: {
                                }
                            },
                        )
                    }
                },
                {
                    title: '助攻2',
                    align: 'center',
                    key: 'assists_2',
                    width: 150,
                    render: function (h, params) {
                        let currentRow = params.row
                        var player = currentRow.assists_2;
                        return h(
                            'i-input', {
                                props:{
                                    value: player['name'],
                                    // disabled: self.locks[String(self.type) + '-' + currentRow.id] == 1,
                                },
                                attrs:{
                                    class: self.locks[String(self.type) + '-' + currentRow.id] == 1 ? 'locked-input': '',
                                },
                                on: {
                                }
                            },
                        )
                    }
                },
                {
                    title: '模式',
                    align: 'center',
                    // key: 'lock',
                    width: 50,
                    render: function (h, params) {
                        let currentRow = params.row
                        return h(Lock, 
                            {
                                props:{
                                    value: self.locks[String(self.type)+'-' + currentRow.id ],
                                    sport_id: self.sport_id,
                                    obj_type: self.obj_type,
                                    obj_id: self.match_id,
                                    type: self.type,
                                    sub_type: currentRow.id,
                                },
                                on: {
                                    input(value){
                                        // alert(value);
                                        self.$set( self.locks, String(self.type)+'-' + currentRow.id, value );
                                    }
                                }
                            },
                        )
                    }
                },
                {
                    title: '操作',
                    align: 'center',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row
                        return h('Poptip', 
                            {
                                props:{
                                    confirm: true,
                                    title: "确定删除本条数据?",
                                },
                                on: {
                                    'on-ok'(){
                                        self.events.splice(params.index, 1)
                                    },
                                }
                            },
                            [
                                h(
                                    'Button',
                                    {
                                        style: {
                                        },
                                        props: {
                                            // type: 'warning',
                                            // disabled: self.locks[String(self.type) + '-' + currentRow.id] == 1,
                                        }
                                    },
                                    '删除'
                                )
                            ]
                        )
                    }
                },
                
            ],
            third_events: {},   // 第三方事件
            third_source_id: '',  // 源展示区域的 第三方源id
            current_source_id: "",  // 线上使用的源id - 切换使用
            // 修改变动历史
            counter : 0, 
            raw_copy: [],
        }
    },
    methods: {
        // 获取比分数据
        getData () {
            let self = this;
            self.spinShow = true;
            // 搜索日期
            var params = {
                sport_id: this.sport_id,
                match_id: this.match_id,
            }
            getMatchEvent( params).then(response => {
                if (response.data.code == 0) {
                    self.events  = response.data.data.events;
                    self.third_events = response.data.data.third_events;
                    self.locks = response.data.data.locks;
                    self.third_source_id = response.data.data.third_source_id;
                    // alert(self.third_source_id);
                    // // console.log(self.data);
                    // self._init_third_stat();
                    self.spinShow = false;
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        // end 
        on_source_id_change(value){
            // 替换比分
            let self = this;
            if (value === '0'){
            }else if (value === '1'){
            }else{
                // 使用第三方源的数据直接替换
                // var source_data = self.third_score_dic[value] || {};
                // self._update_data( source_data.score );
            }
        },
        // end
        // _update_data(  ){
        //     // 使用结构相同的新数据更新旧数据
        //     // 待更新的数据如下:
        //     // singles._key :       String(type)+'-2'+ '-' + item.key = "1-2-{_key}"
        //     // 判断self.locks中是否存在锁 如果存在 则保留之前的数据 否则更新
        //     let self = this;
            
        //     // 1 status_id
        //     if (self.locks[String(self.type)+'-1'] === 1){
        //         // 保留之前的数据
        //     }else if (self.data.status_id !== new_data.status_id){
        //         self.$set(self.data, "status_id", new_data.status_id);
        //     }
        //     // 2 singles
        //     var new_singles = JSON.parse(JSON.stringify( new_data.singles || {} )) ;  // 创建新的对象
        //     var data_singles = JSON.parse(JSON.stringify(self.data.singles || {} )) ;
        //     for (var _key in data_singles){
        //         var item = data_singles[_key];
        //         if ( self.locks[String(self.type)+'-2'+ '-' + _key] === 1 ){
        //             // 锁住
        //         }else if ( new_singles[_key] ){
        //             if (item.score !== new_singles[_key].score ){
        //                 self.$set( self.data.singles, _key, new_singles[_key] )
        //             }
        //         }else{
        //             self.$delete( self.data.singles, _key );
        //         }
        //         delete new_singles[_key];
        //     }
        //     // 有可能新增 - 键
        //     for ( var new_key in new_singles ){
        //         self.$set(self.data.singles, new_key, new_singles[new_key]);
        //     }
        // },
        // end
        api_save(){

        },
        // end 
        feed( data ){
            // 处理实时数据
            console.log("LIVE IN EVENT: ", data)
        },
        // end
        
    },
    mounted () {
    },
    watch: {
        'match_id': {
            handler(value){
                let self = this;
                if (value){
                    // console.log("DetailEvent Get Data", value);
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
        'events': {
            handler(value){
                let self = this;
                self.counter += 1;
                if (self.counter > 1){
                    // console.log("changed: ", value)
                    self.counter = 2;  // 最大为2
                    // this.$emit('onChange', self.type);
                }else if (self.counter === 1){
                    self.raw_copy = JSON.parse( JSON.stringify( self.events ));
                }
            },
            deep: true,
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    @import "detail.css"
</style>