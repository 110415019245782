<template>
    <div>
        <Modal
            v-model="modal_add_sport"
            title="编辑路由"
            @on-ok='addNewSport'
        >
            <sportSearch  v-model="new_sport" :season_id="data.season.id" > </sportSearch>
        </Modal>

        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="奥运会: ">
                    <Row>
                        <Col span="20" >
                            <seasonSearch v-model="data.season" ></seasonSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="ioc: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.ioc" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="noc: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.noc" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="noc(英文): ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.noc_en" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="地区: ">
                    <Row>
                        <Col span="20" >
                            <areaSearch v-model="data.area" ></areaSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="描述: ">
                    <Row>
                        <Col span="20" >
                            <Input type="textarea" v-model="data.description" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="运动项目: ">
                    <Row v-for="(item, index) in data.items" :key="index" >
                        <Col span="22" >
                            <Row>
                                <Col span="8" >
                                    <span class="text-main"> {{item.name}} </span>
                                </Col>
                                <Col span="12" >
                                    <span class="text-link"> {{item.id}} </span>
                                </Col>
                                <Col span="4" >
                                    <Icon type="md-close-circle"  @click.native="data.items.splice(index, 1)" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="20" >
                            <Button shape="circle" icon="md-add" @click="modal_add_sport=true" ></Button>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="参赛人数: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.participant_num" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" >
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getDelegationList, updateDelegation } from '@/api/olympics/delegation';
import areaSearch from '../area/search.vue';
import seasonSearch from '../season/search.vue';
import sportSearch from '../sport/search.vue';

export default {
    name: "area-edit",
    props: {
        _id: Number,   // id
    },
    components: {
        areaSearch,
        seasonSearch,
        sportSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                season: {},
                area: {},
            },
            new_sport: {
                "id": 0,
                "name": '',
            },
            modal_add_sport: false,
        }
    },
    methods: {
        // 获取联赛分类
        getData () {
            let self = this;
            if (self._id && self._id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : self._id,
                }
                getDelegationList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {};
                        }
                        self.spinShow = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self.data = {
                    id: 0,
                    season: {},
                    area: {},
                }
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name);
        },

        save() {
            let self = this;
            let items = [];
            self.data.items.forEach(element => {
                if (element.id > 0 && items.indexOf(element.id) == -1 ){
                    items.push( element.id )
                }
            });
            let params = {
                id: self.data.id,
                season_id: self.data.season.id,
                area_id: self.data.area.id,
                ioc: self.data.ioc,
                noc: self.data.noc,
                noc_en: self.data.noc_en,
                participant_num: self.data.participant_num,
                description: self.data.description,
                "sports": items,
                deleted: self.data.deleted,
            }
            // console.log(params);
            updateDelegation(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功");
                    self.emit("save");
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        addNewSport(){
            let self = this;
            if (self.new_sport.id > 0){
                self.data.items.push( self.new_sport );
            }
            self.new_sport = {
                id: 0,
                name: '',
            }
        },
        // end 
    },
    mounted () {
        // let self = this;
    },
    watch: {
        "_id":{
            handler(value){
                // console.log( value );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
    }
}
</script>
