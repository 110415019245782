<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="奥运会: ">
                    <Row>
                        <Col span="20" >
                            <seasonSearch v-model="data.season" ></seasonSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="大运动项目: ">
                    <Row>
                        <Col span="20" >
                            <sportSearch v-model="data.sport" :season_id="data.season.id" ></sportSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="中文名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_zh" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="英文名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_en" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="性别类型: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.type">
                                <Option v-for="item in types" :value="item.id" :key="item.id" :label="item.name"></Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="团体类型: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.race_type">
                                <Option v-for="item in race_types" :value="item.id" :key="item.id" :label="item.name"></Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="logo: ">
                    <Row>
                        <Col span="20" >
                            <imgEditor v-model="data.logo" :prefix="$conf.olympics.img_prefix" 
                                dir="olympics" :previewButton='true' :clearButton='true'>
                            </imgEditor>
                        </Col>
                    </Row>
                </FormItem> 

                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" >
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getSubSportList, updateSubSport } from '@/api/olympics/subsport';
import seasonSearch from '../season/search.vue';
import sportSearch from '../sport/search.vue';
import imgEditor from '@/components/img-editor/img-editor.vue';

export default {
    name: "area-edit",
    props: {
        _id: Number,   // id
    },
    components: {
        seasonSearch,
        sportSearch,
        imgEditor,
    },
    data () {
        let self = (window.x = this)
        return {
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                season: {},
                sport: {},
            },
            types: {
                0: {
                    "id": 0,
                    name: '未知',
                },
                1: {
                    "id": 1,
                    name: '男子',
                },
                2: {
                    "id": 2,
                    name: '女子',
                },
                3: {
                    "id": 3,
                    name: '混合',
                },
            },
            race_types: {
                0: {
                    "id": 0,
                    name: '个人',
                },
                1: {
                    "id": 1,
                    name: '团体',
                },
            },
            modal_add_sport: false,
        }
    },
    methods: {
        // 获取联赛分类
        getData () {
            let self = this;
            if (self._id && self._id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : self._id,
                }
                getSubSportList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {};
                        }
                        self.spinShow = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self.data = {
                    id: 0,
                    season: {},
                    sport: {},
                }
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name);
        },

        save() {
            let self = this;
            let params = {
                id: self.data.id,
                season_id: self.data.season.id,
                sport_id: self.data.sport.id,
                name_zh: self.data.name_zh,
                name_en: self.data.name_en,
                type: self.data.type,
                race_type: self.data.race_type,
                logo: self.data.logo,
                deleted: self.data.deleted,
            }
            // console.log(params);
            updateSubSport(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功");
                    self.emit("save");
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end 
    },
    mounted () {
        // let self = this;
    },
    watch: {
        "_id":{
            handler(value){
                // console.log( value );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
    }
}
</script>
