<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Modal
            v-model="modal_add"
            title="新增关联"
            @on-ok= 'addRelation'
        >
            <relationMatchSearch v-model="new_relation_match" :game_id="game_id"></relationMatchSearch>
        </Modal>
        <Tabs value="1">
            <TabPane label="比赛关联" name="1">
                <Row>
                    <Button @click="showAddRelation()" long size="small">新增关联</Button>
                </Row>
                <br />
                <Row v-for="(item, index) in data" :key="item.id" :index="item.index" align='middle'>
                    <Col span=8 class='text-main'>关联表ID: <span class='text-link'>{{ item.id }}</span></Col>
                    <Col span=4 class='text-main'>源{{ item.source_id }}</Col>
                    <Col span=6 class='text-main'>源数据ID: <span class='text-link'>{{ item.uid }}</span></Col>
                    <!-- <Col span=6>
                        <Row  align='middle'>
                            <Col  class='text-main'>实时 : </Col>
                            <Col >
                                <Select v-model="item.source.live" placeholder="实时源" @on-change="save(item)" >
                                    <Option :value="0" key="0">空</Option>
                                    <Option :value="4" key="4">源4</Option>
                                    <Option :value="5" key="5">源5</Option>
                                </Select>
                            </Col>
                        </Row>
                    </Col> -->
                    <Col span=4>
                        <!-- <Button @click="deleteRelation(item.id)" size="small">删除</Button> -->
                        <Poptip
                            confirm
                            title="确定删除?"
                            @on-ok="deleteRelation(item.id)"
                            >
                            <Button>删除</Button>
                        </Poptip>
                    </Col>
                </Row>
                <br />
                <Row align='middle'>
                    <Col span=4 class='text-main'>实时数据源: </Col>
                    <Col span=12 >
                        <Select v-model="source['2']" placeholder="实时源"  >
                            <Option :value="0" key="0">空</Option>
                            <Option v-for="(item, index) in data" :key="item.id" :index="item.index" :value="item.source_id" key="item.source_id">源{{item.source_id}}</Option>
                        </Select>
                    </Col>
                    <Col span=4><Button @click="matchSourceSet('2')" size="small">确定</Button></Col>
                </Row>
                <br />
                <Row align='middle'>
                    <Col span=4 class='text-main'>赛后数据源: </Col>
                    <Col span=12 >
                        <Select v-model="source['3']" placeholder="赛后源"  >
                            <Option :value="0" key="0">空</Option>
                            <Option v-for="(item, index) in data" :key="item.id" :index="item.index" :value="item.source_id" key="item.source_id">源{{item.source_id}}</Option>
                        </Select>
                    </Col>
                    <Col span=4><Button @click="matchSourceSet('3')" size="small">确定</Button></Col>
                </Row>
                <Divider>战队关联</Divider>
            <!-- </TabPane> -->
            <!-- <TabPane label="战队关联" name="2"> -->
                <template v-for="item in [{'id': home_id, name:'主队' }, {'id': away_id, name:'客队'}]" >
                    <span> {{item.name}} <span class="text-link">{{item.id}}</span>: </span>
                    <teamEditSource :game_id="game_id" :team_id="item.id"></teamEditSource>
                    <Divider />
                </template>
            </TabPane>
        </Tabs>
    </div>
</template>
<script>

import { getDetailSource, deleteDetail, updateDetail} from '@/api/relation/games/detail';
import { setMatchSourceId, getMatchSourceId } from '@/api/relation/games/match';
import relationMatchSearch from '../../relation/games/match/search.vue';
import teamEditSource from '../team/edit_source.vue';

export default {
    name: "match-edit-source",
    props: {
        game_id: Number,   // 运动id
        match_id: Number,   // 比赛id
        home_id: Number,    // 主队id
        away_id: Number,    // 客队id
    },
    components: {
        relationMatchSearch,
        teamEditSource,
    },
    data () {
        let self = (window.x = this)
        return {
            type: 4, 
            spinShow: false,
            locks: {},
            data: [],
            modal_add: false,
            
            new_relation_match: {
                'id': 0,
                'home': {},
                'away': {},
                tournament: {},
            },
            source: {},
        }
    },
    methods: {
        _init_data(){
            this.data = [];
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.match_id > 0){
                var params = {
                    game_id: self.game_id,
                    data_id: self.match_id,
                    type_id: self.type,
                }
                getDetailSource( params ).then(response => {
                    if (response.data.code == 0) {
                        self.data = response.data.data.list;
                        self.loading = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
                var source_params = {
                    type: self.type,
                    game_id: self.game_id,
                    nami_id: self.match_id,
                }
                getMatchSourceId( source_params ).then(response => {
                    if (response.data.code == 0) {
                        self.source = response.data.data;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self._init_data();
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            // self.match_id = 0; 不能直接修改probs传参
            self.emit("cancle")
        },
        // save( item ) {
            // 更新表 删除
        //     let self = this;
        //     // 更新源
        //     if( item.source.live == 0){
        //         delete item.source.live;
        //     }
        //     // console.log( self.source );
        //     if ( item && item.id > 0){
        //         let source_data = {
        //             "id": item.id,
        //             "source": item.source,
        //         }
        //         // console.log( source_data );
        //         updateDetailSource(source_data).then(response => {
        //             if (response.data.code == 0) {
        //                 self.$Message.success("源更新成功");
        //                 // self.emit("save")
        //             } else {
        //                 self.$Message.error(response.data.msg);
        //             }
        //         })
        //     }
        // },
        // // end 
        matchSourceSet(type_id){
            // 
            let self = this;
            let params = {
                type: self.type,
                game_id: self.game_id,
                nami_id: self.match_id,
                source_id: self.source[type_id] || 0,
                type_id,
            }
            setMatchSourceId(params).then(response => {
                if (response.data.code == 0) {
                    self.$Message.success("源更新成功");
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end

        deleteRelation(relation_id){
            let self = this;
            deleteDetail( {relation_id: relation_id}).then( response => {
                if (response.data.code == 0) {
                    self.$Message.success("删除成功");
                    self.getData();
                } else {
                    self.$Message.error(response.data.msg);
                }
            } )
        },
        // end
        showAddRelation(){
            let self = this;
            self.modal_add = true;
        },
        // end
        addRelation(){
            let self = this;
            // console.log(self.new_relation_match);
            let params = {
                // id: _id,
                game_id: self.game_id,
                tid: 4,
                pid: self.new_relation_match.source_id,
                uid: self.new_relation_match.id,
                'data': self.match_id,
            }
            // console.log(params)
            updateDetail(params).then(response => {
                if (response.data.code == 0) {
                    var relation_id = response.data.data.id;
                    if (relation_id > 0){
                        self.getData();
                        self.$Message.success('添加成功');
                    }
                    
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
    },
    mounted () {
    },
    watch: {
        'match_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>