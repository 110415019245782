<template>
    <div>
        <Drawer
            v-model="drawer_edit_match"
            width="500"
            :mask-closable="true"
        >
            <playerStatEdit :game_id="game_id" :player_stat_id="edit_id" @closePage="closeEditDrawer"></playerStatEdit>
        </Drawer>  


        <Row style="margin-bottom:10px;">
            <Col span="4" style="text-align: left">
                <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
            </Col>
            <!-- <Col style="text-align: right">
                <Checkbox v-model="deleted" border>筛选删除</Checkbox> 
            </Col> -->
            <Col >
                <Button type="success"  @click="add">新增</Button>
            </Col>
        </Row>
        <!-- <fitTable 
            :columns="columns" 
            :data="data" 
            :loading="loading" 
        >
        </fitTable> -->
        <!-- 无法解决滚动条消失的bug -->
         <Table 
            ref="table" 
            :columns="columns" 
            :data="data" 
            :loading="loading" 
        >
        </Table>
        <Page
            :total="total_page"
            :current="current_page"
            :page-size="page_size"
            @on-change="onPageChange"
            show-total
            show-elevator
        ></Page>
    </div>
</template>
<script>

import { getPlayerStatList } from '@/api/games/player_stat';
import playerStatEdit from './edit.vue';
import {getImgGameUrl} from '@/utils/util';
import fitTable from '../../basic/table.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id);
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    // margin: '2px 2px'
                },
                props: {
                    type: 'warning',
                    size: 'small'
                }
            },
            '删除'
        )
        ]
    )
}


const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                // margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit_match = true;
                }
            }
        },
        '编辑'
    )
}


export default {
    name: "match-single-list",
    props: {
        game_id: Number,   // 运动id
    },
    components: {
        playerStatEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            match_single_id: 0,
            deleted: false,
            drawer_detail: false,
            drawer_relation: false,
            drawer_edit_match: false,
            edit_id : 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,

            edit_data: {
                uid: 0,
                name: ''
            },
            modal1: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 100,
                    key: 'id',
                },
                
                {
                    title: '比赛id',
                    align: 'center',
                    minWidth: 80,
                    key: 'match_id',
                    game_ids: [1,2,3],
                },
                {
                    title: '小局比赛id',
                    align: 'center',
                    minWidth: 80,
                    key: 'match_single_id',
                },
                {
                    title: '队伍',
                    key: 'team_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let team = currentRow.team;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'team', team.logo, true), shape: 'square', size: 'middle' },
                                class: {'team-logo': true} })],
                        ));
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(team.id);
                                            }
                                        } },  team.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(team.id);
                                            }
                                        } }, team.name,  )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '选手',
                    key: 'player_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let player = currentRow.player;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: getImgGameUrl(self.game_id, self.game_id==3?'player/head':'player', player.logo, true), shape: 'square', size: 'middle' },
                                class: {'player-logo': true} })],
                        ));
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(player.id);
                                            }
                                        } },  player.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(player.id);
                                            }
                                        } }, player.name,  )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '英雄',
                    key: 'hero_id',
                    align: 'center',
                    minWidth: 80,
                    game_ids:[1,3,6],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let hero = currentRow.hero;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'hero', hero.logo, true), shape: 'square', size: 'middle' },
                                class: {'hero-logo': true} })],
                        ));
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(hero.id);
                                            }
                                        } },  hero.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(hero.id);
                                            }
                                        } }, hero.name,  )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '命石',
                    key: 'facet_id',
                    align: 'center',
                    minWidth: 80,
                    game_ids:[3,],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let facet = currentRow.facet;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'facet', facet.logo, true), shape: 'square', size: 'middle' },
                                class: {'facet-logo': true} })],
                        ));
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [
                                h('span', {class: 'text-link', on: {
                                    'click':() => {
                                        // self.editFacet(facet.id);
                                    }
                                } },  facet.id + ' ',  ),
                                h('span', {class: 'text-main',on: {
                                    'click':() => {
                                        // self.editFacet(facet.id);
                                    }
                                } }, facet.name, )
                            ],
                        ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '等级',
                    key: 'level',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[1,3,6],
                },
                {
                    title: '号位',
                    key: 'position',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[1,3,6],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let position_id = currentRow.position;
                        let position = self.$conf.games.positions[self.game_id][position_id];
                        return h('span', position? position.name : '')
                    }
                },
                {
                    title: '击杀',
                    key: 'kill',
                    align: 'center',
                    minWidth: 60,
                },
                {
                    title: '爆头击杀',
                    key: 'hs',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[2],
                },
                {
                    title: '死亡',
                    key: 'die',
                    align: 'center',
                    minWidth: 60,
                },
                {
                    title: '助攻',
                    key: 'assists',
                    align: 'center',
                    minWidth: 60,
                },
                {
                    title: '闪光弹助攻',
                    key: 'f_assists',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[2],
                },
                {
                    title: 'kast',
                    key: 'kast',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[2],
                },
                {
                    title: 'adr',
                    key: 'adr',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[2],
                },
                {
                    title: 'kf_diff',
                    key: 'kf_diff',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[2],
                },
                {
                    title: 'awp击杀',
                    key: 'awp_kill',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[2],
                },
                {
                    title: '首杀轮数',
                    key: 'frist_kill',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[2],
                },
                {
                    title: 'impact',
                    key: 'impact',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[2],
                },
                {
                    title: '评分',
                    key: 'rating',
                    align: 'center',
                    minWidth: 60,
                    game_ids:[2],
                },
                {
                    title: '正补',
                    key: 'last_hits',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [3],
                },
                {
                    title: '反补',
                    key: 'denies',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [3],
                },
                {
                    title: '补兵',
                    key: 'soldiers',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [1],
                },
                {
                    title: '参团率',
                    key: 'part',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [1],
                },
                {
                    title: '经济',
                    key: 'money',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [1, 6],
                },
                {
                    title: '分均经济',
                    key: 'gold_per_min',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [3],
                },
                {
                    title: '分均经验',
                    key: 'xp_per_min',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [3],
                },
                {
                    title: '对英雄伤害',
                    key: 'hero_damage',
                    align: 'center',
                    minWidth: 80,
                    game_ids: [3],
                },
                {
                    title: '对塔伤害',
                    key: 'tower_damage',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [3],
                },
                {
                    title: '输出',
                    key: 'attack',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [1, 6],
                },
                {
                    title: '承伤',
                    key: 'bear',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [1, 3, 6],
                },
                {
                    title: '治愈',
                    key: 'hero_healing',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [3],
                },
                {
                    title: '剩余金币',
                    key: 'gold',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [3],
                },
                {
                    title: '消耗金币',
                    key: 'gold_spent',
                    align: 'center',
                    minWidth: 60,
                    game_ids: [3],
                },
                {
                    title: '装备',
                    key: 'equipments',
                    align: 'center',
                    minWidth: 200,
                    game_ids: [1, 3, 6],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let equipments = currentRow.equipments;
                        if (equipments && equipments.length > 0){
                            let cols = [];
                            equipments.forEach(element => {
                                cols.push(
                                    // h('Tooltip', {attrs: {content: element.id+element.name} }, [
                                        h('lazy-component',
                                            [h('img', { attrs: { 'src': getImgGameUrl(self.game_id, [3, 6].indexOf(self.game_id)>-1? 'item': 'equipment', element.logo, true), shape: 'square', },
                                                class: {'table-lazy-logo': true, },
                                            })]
                                        )
                                    // ])
                                )
                            });
                            return (h('Row', cols))
                        }else{
                            return (h('span', ''))
                        }
                    }
                },
                {
                    title: '召唤师技能',
                    key: 'spells',
                    align: 'center',
                    minWidth: 70,
                    game_ids: [1, 6],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let spells = currentRow.spells;
                        if (spells && spells.length > 0){
                            let cols = [];
                            spells.forEach(element => {
                                cols.push(
                                    // h('Tooltip', {attrs: {content: element.id+element.name} }, [
                                        h('lazy-component',
                                            [h('img', { attrs: { 'src': getImgGameUrl(self.game_id, 'spell', element.logo, true), shape: 'square', },
                                                class: {'table-lazy-logo': true, },
                                            })]
                                        )
                                    // ])
                                )
                            });
                            return (h('Row', cols))
                        }else{
                            return (h('span', ''))
                        }
                    }
                },
                {
                    title: '英雄符文',
                    key: 'runes',
                    align: 'center',
                    minWidth: 100,
                    game_ids: [1],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let runes = currentRow.runes;
                        if (runes && runes.length > 0){
                            let cols = [];
                            runes.forEach(element => {
                                cols.push(
                                    // h('Tooltip', {attrs: {content: element.id+element.name} }, [
                                        h('lazy-component',
                                            [h('img', { attrs: { 'src': getImgGameUrl(self.game_id, 'rune', element.logo, true), shape: 'square', },
                                                class: {'table-lazy-logo': true, },
                                            })]
                                        )
                                    // ])
                                )
                            });
                            return (h('Row', cols))
                        }else{
                            return (h('span', ''))
                        }
                    }
                },
                // {
                //     title: '被删除',
                //     key: 'deleted',
                //     align: 'center',
                //     minWidth: 80,
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         let deleted = currentRow.deleted;
                //         let deleted_str = deleted == 0 ? '正常': (
                //             deleted == 1 ? '被删除': '未知:'+deleted
                //         )
                //         const deleted_status = deleted == 0 ? 'success': (
                //             deleted == 1 ? 'error': 'default:'
                //         )
                //         return [h( 'Badge', {attrs: {status: deleted_status,  }} ), 
                //                 h('span', deleted_str)]
                //     }
                // },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 80,
                    render (h, params) {
                        return [editButton(self, h, params), ];  // delButton(self, h, params)
                    }
                }
            ],
            data: [],
            drawer_styles: {
                padding: '2px',
            },
            // 编辑
            modal_edit_team: false,
            edited_team_id: 0,
        }
    },
    methods: {
        onPageChange (page) {
            // console.log("1 change", page);
            this.current_page = page;
            this.getData();
        },
        onSearch (search_key) {
            // console.log("2 change", search_key);
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },

        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit_match = false;
            self.edit_id = 0;
            self.getData();
        },
        // end
        editTeam(_id){
            let self = this;
            self.edited_team_id = _id;
            self.modal_edit_team = true;
        },
        // end
        getData (status_ids) {
            let self = this;
            this.loading = true
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            var params = {
                game_id: this.game_id,
                search_key: this.search_key,
                page: this.current_page,
                match_single_id: self.match_single_id,
                lang: lang,
            }
            if (self.deleted){
                params['deleted'] = 1;
            }
            getPlayerStatList(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list
                    this.total_page = response.data.data.total_page
                    this.page_size = response.data.data.page_size
                    this.current_page = response.data.data.current_page
                    this.loading = false
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        
        
        add () {
            // this.edit_data = {
            //     'logo': ''
            // }
            this.edit_id = 0;
            this.drawer_edit_match = true
        },
        // end 

    },
    mounted () {
        this.$nextTick(function () {
            this.columns = this.columns.filter(col => col.game_ids? this.$unit.filterGameids( col.game_ids, this.game_id ): true );
            try{
                if (this.$route.query.match_single_id){
                    this.match_single_id = parseInt(this.$route.query.match_single_id);
                }
            }catch{
                this.match_single_id = 0;
            }
            // console.log(this.game_id, this.match_id);
            this.getData();
        })
    },
    watch: {
        // status_ids (value){
        // },
        deleted(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        }
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css";
</style>