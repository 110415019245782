<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Input v-model="data.id"  disabled></Input>
            </FormItem>
            <FormItem label="名称: " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name" ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="子模块: " >
                <Checkbox v-model="data.permissions_portal">纳米</Checkbox>
                <Checkbox v-model="data.permissions_thesports">thesports</Checkbox>
            </FormItem>
        </Form>
        <Row>
            <Col span="24" >
                <permissionEdit ref="permission" :is_components='true' v-model="data.permissions" ></permissionEdit>
            </Col>
        </Row>

        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>
</template>

<script>

import { getAdminUserGroup, updateAdminUserGroup } from '@/api/admin';
import permissionEdit from './permissions_edit.vue';

export default {
    name: "user_group-edit",
    props: {
        group_id: Number,   // 运动id
    },
    components: {
        permissionEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            data: {
                // permissions: false,
            },
        }
    },
    methods: {
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.group_id > 0){
                var params = {
                    'id': self.group_id,
                }
                getAdminUserGroup( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        this.data = response.data.data;
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self.data = {
                    name: '',
                    permissions: [],
                };
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            // self.group_id = 0; 不能直接修改probs传参
            self.emit(false);
        },
        save() {
            let self = this;
            let permissions = self.$refs.permission.save()
            let params = {
                id: self.data.id,
                data_dic: {
                    name: self.data.name,
                    permissions: permissions,
                    permissions_portal: self.data.permissions_portal,
                    permissions_thesports: self.data.permissions_thesports,
                }
            };
            // console.log(params);
            updateAdminUserGroup(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    let resp = response.data.data;
                    if (resp.id){
                        params["id"] = resp.id;
                    }
                    self.emit(params);
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
    },
    watch: {
        'group_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data group_id", value);
                    self.getData();
                    // console.log(self.data);
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>