<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Modal
            v-model="modal_add"
            title="新增关联"
            @on-ok= 'addRelation'
        >
            <relationTeamSearch v-model="new_relation_team" :game_id="game_id"></relationTeamSearch>
        </Modal>
        <Row>
            <Button @click="showAddRelation()" long size="small">新增关联</Button>
        </Row>
        <br />
        <Row v-for="(item, index) in data" :key="item.id" :index="item.index" align='middle'>
            <Col span=8 class='text-main'>关联表ID: <span class='text-link'>{{ item.id }}</span></Col>
            <Col span=4 class='text-main'>源{{ item.source_id }}</Col>
            <Col span=6 class='text-main'>源数据ID: <span class='text-link'>{{ item.uid }}</span></Col>
            <Col span=4>
                <Poptip
                    confirm
                    title="确定删除?"
                    @on-ok="deleteRelation(item.id)"
                    >
                    <Button>删除</Button>
                </Poptip>
            </Col>
        </Row>
    </div>
</template>
<script>

import { getDetailSource, deleteDetail, updateDetail} from '@/api/relation/games/detail';
import relationTeamSearch from '../../relation/games/team/search.vue';

export default {
    name: "team-edit-source",
    props: {
        game_id: Number,   // 运动id
        team_id: Number,   // 战队id
    },
    components: {
        relationTeamSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            type: 1, 
            spinShow: false,
            locks: {},
            data: [],
            modal_add: false,
            
            new_relation_team: {
                'id': 0,
                'name': '',
                'logo': '',
                tournament: {},
            },
            source: {},
        }
    },
    methods: {
        _init_data(){
            this.data = [];
        },
        // 获取战队的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.team_id > 0){
                var params = {
                    game_id: self.game_id,
                    data_id: self.team_id,
                    type_id: self.type,
                }
                getDetailSource( params ).then(response => {
                    if (response.data.code == 0) {
                        self.data = response.data.data.list;
                        self.loading = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self._init_data();
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            // self.team_id = 0; 不能直接修改probs传参
            self.emit("cancle")
        },
        // end
        deleteRelation(relation_id){
            let self = this;
            deleteDetail( {relation_id: relation_id}).then( response => {
                if (response.data.code == 0) {
                    self.$Message.success("删除成功");
                    self.getData();
                } else {
                    self.$Message.error(response.data.msg);
                }
            } )
        },
        // end
        showAddRelation(){
            let self = this;
            self.modal_add = true;
        },
        // end
        addRelation(){
            let self = this;
            // console.log(self.new_relation_team);
            let params = {
                // id: _id,
                game_id: self.game_id,
                tid: self.type,
                pid: self.new_relation_team.source_id,
                uid: self.new_relation_team.id,
                'data': self.team_id,
            }
            // console.log(params)
            updateDetail(params).then(response => {
                if (response.data.code == 0) {
                    var relation_id = response.data.data.id;
                    if (relation_id > 0){
                        self.getData();
                        self.$Message.success('添加成功');
                    }
                    
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
    },
    mounted () {
    },
    watch: {
        'team_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>