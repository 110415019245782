<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="550"
        :mask-closable="true"
    >
        <facetEdit :game_id="game_id" :_id="edit_id" @closePage="closeEditDrawer"></facetEdit>
    </Drawer>  
    <Row>
        <Col  style="text-align: left">
            <Input v-model="search_key" placeholder="搜索名称/id" search @on-search="onSearch" />
        </Col>
        <Col >
            <Button type="success"  @click="add">新增</Button>
        </Col>
    </Row>
    <fitTable :columns="columns" :data="data" :loading="loading"></fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
      ></Page>
  </div>
</template>

<script>

import { FacetList } from '@/api/games/facet';
import fitTable from '../../basic/table.vue';
import {getImgGameUrl} from '@/utils/util';
import facetEdit from './edit.vue';


const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                // margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
  props: {
      game_id: Number,   // 运动id
  },
  data () {
    let self = (window.x = this)
    return {
      search_key: '',
      loading: false,
      total_page: 0,
      page_size: 0,
      current_page: 1,
      columns: [
        {
          title: 'id',
          width: '80',
          key: 'id'
        },
        // {
        //   title: '游戏',
        //   width: '100',
        //   key: 'game_id',
        //   render: function(h, params){
        //     let currentRow = params.row
        //     let game = self.$conf.games.games[currentRow.game_id]
        //     return h('span', game["name"])
        //   }
        // },
        {
            title: '英雄',
            key: 'hero_id',
            align: 'center',
            minWidth: 80,
            game_ids:[1,3,6],
            render: function (h, params) {
                let currentRow = params.row;
                let hero = currentRow.hero;
                let resp = [];
                resp.push(h("Row", {attrs: {justify: 'center' }}, 
                    [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'hero', hero.logo, true), shape: 'square', size: 'middle' },
                        class: {'hero-logo': true} })],
                ));
                resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [
                                h('span', {class: 'text-link', on: {
                                    'click':() => {
                                        self.editTeam(hero.id);
                                    }
                                } },  hero.id + ' ',  ),
                                h('span', {class: 'text-main',on: {
                                    'click':() => {
                                        self.editTeam(hero.id);
                                    }
                                } }, hero.name,  )
                            ],
                        ))
                return h(
                    'div', resp.length > 0 ? resp: ''
                )
            }
        },
        {
            title: '号位',
            key: 'number',
            align: 'center',
            minWidth: 60,
            game_ids:[1,3,6],
            render: function (h, params) {
                let currentRow = params.row;
                let position_id = currentRow.number;
                let position = self.$conf.games.positions[self.game_id][position_id];
                return h('span', position? position.name : '')
            }
        },
        {
          title: '中文全称',
          minWidth: 60,
          key: 'name_zh',
        },
        {
          title: '英文全称',
          minWidth: 60,
          key: 'name_en',
        },
        {
          title: '中文描述',
          minWidth: 80,
          key: 'description_zh',
        },
        {
          title: '英文描述',
          minWidth: 80,
          key: 'description_en',
        },
        {
          title: 'logo',
          key: 'logo',
          align: 'left',
          minWidth: 50,
          render: function (h, params) {
            let currentRow = params.row
            return h('img', {
              attrs: {
                src: getImgGameUrl(self.game_id, 'facet', currentRow.logo),
              },
              class: {'facet-logo': true} 
            })
          }
        },
        {
          title: '颜色',
          key: 'colors',
          align: 'center',
          minWidth: 80,
          render: function (h, params) {
            let currentRow = params.row
            let pickers = [];
            for (let _index in currentRow.colors || []){
              // console.log(color)
              pickers.push(
                h('Row', [
                  // h('ColorPicker', {
                  //   props: {
                  //     "value": currentRow.colors[_index],
                  //     "editable": false
                  //   },
                  // }),
                  h('span', {
                    style: {
                      color: currentRow.colors[_index],
                    },
                  }, currentRow.colors[_index])
                ])
              )
            }
            return pickers;
            
            // console.log(self.$conf.games.img_prefix + 'esports/tournament/' + currentRow.logo);
            // return h('img', {
            //   attrs: {
            //     src: getImgGameUrl(self.game_id, 'facet', currentRow.logo),
            //   },
            //   class: {'facet-logo': true} 
            // })
          }
        },
        {
          title: '操作',
          key: 'deleted',
          align: 'center',
          width: 80,
          render (h, params) {
            return [editButton(self, h, params),]
          }
        }

      ],
      data: [],
      drawer_edit: false,
      edit_id: 0,
    }
  },
  components: {
    // TournamentFinder
    fitTable,
    facetEdit,
  },
  methods: {
    onPageChange (page) {
      this.current_page = page
      this.getData()
    },
    onSearch (search_key) {
      this.current_page = 1
      this.search_key = search_key
      this.getData()
    },
    getData () {
      let self = this
      // console.log(process.env.NODE_ENV);

      this.loading = true
      var params = {
        game_id: self.game_id,
        page: self.current_page,
        search_key: self.search_key,
      }
      FacetList(params).then(response => {
        if (response.data.code == 0) {
          this.data = response.data.data.list;
          this.total_page = response.data.data.total_page;
          this.page_size = response.data.data.page_size;
          this.current_page = response.data.data.current_page;
          this.loading = false;
        } else {
          this.$Message.error(response.data.msg);
        }
      })
    },
    // end
      closeEditDrawer(){
          let self = this;
          self.drawer_edit = false;
          self.edit_id = 0;
          self.getData();
      },
    // end
    add () {
        this.edit_id = 0;
        this.drawer_edit = true
    },
    // end
  },
  mounted () {
    this.getData()
  },
  watch: {
    game_id (value) {
      let self = this
      // console.log(value)
      self.getData()
    },
    date (value) {
      let self = this
      self.getData()
    }

  },
  computed: {}
}
</script>

<style scoped>
    @import "list.css"
</style>
