<template>
    <div>
        <roundList :game_id="game_id"></roundList>
    </div>
</template>

<script>

import roundList from '@/components/games/round/list.vue';

export default {
    components: {
        roundList
    },
    data () {
        let self = (window.x = this)
        return {
            game_id: 1,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>