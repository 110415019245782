import Vue from 'vue'
import Router from 'vue-router'
import store  from '@/store'
import config from '@/config'

Vue.use(Router)

export const constantRouterMap = [
    // 登录页面
    {
        path: '/login/index',
        name: 'login_index',
        meta: {
            title: 'login_index',
            hidden: true 
        },
        component: resolve => require(['@/components/login/index.vue'], resolve)
    },
    // 登录跳转oauth2认证页面
    {
        path: '/login/oauth2/callback',
        name: 'login_oauth2_callback',
        meta: {
            title: 'login_oauth2_callback',
            hidden: true 
        },
        component: resolve => require(['@/components/login/oauth2/callback.vue'], resolve)
    },
    // 授权给第三方网站Token
    {
        path: '/login/authorize',
        name: 'login_authorize',
        meta: {
            title: 'login_authorize',
            hidden: true 
        },
        component: resolve => require(['@/components/login/authorize.vue'], resolve)
    },
    // 404
    {
        path: '/404',
        name: 'notFound',
        meta: {
            keepAlive: true,
            hidden: true,
        },
        component: () => import('@/views/error-page/404.vue')
    },
    {
        path: '/permission_error',
        name: 'permission_error',
        meta: {
            keepAlive: true,
            hidden: true,
        },
        component: () => import('@/views/error-page/permission_error.vue')
    },
    // 默认登录首页
    {
        path: '/index',
        name: 'index',
        meta: {
            keepAlive: true,
            hidden: true,
        },
        component: () => import('@/views/index/index.vue')
    },
]

function getBallsportsList(){
    var ballsports_menu = [];
    // 管理员
    let admin_menu = {
        path: '/admin/index',
        name: 'admin_index',
        meta: { 
            title: "管理员",
            keepAlive: true,
            icon: 'ios-people-outline',
            class: 'menu-group',
        }, 
        component: resolve => require(['@/views/admin/index.vue'], resolve),
        children : [
            {
                path: '/admin/user/list',
                name: 'admin_user_list',
                meta: { 
                    title: "用户",
                    keepAlive: true,
                },  
                component: resolve => require(['@/views/admin/user/list.vue'], resolve)
            },
            {
                path: '/admin/user_group/list',
                name: 'admin_user_group_list',
                meta: { 
                    title: "用户组",
                    keepAlive: true,
                },  
                component: resolve => require(['@/views/admin/user_group/list.vue'], resolve)
            },
        ],
    };
    // 关联 
    let relation_menu = {
        path: '/relation/game/index',
        name: 'relation_game_index',
        meta: { 
            title: "关联",
            keepAlive: true,
            icon: 'ios-link',
            class: 'menu-group',
            // group: 'relation',
        }, 
        component: resolve => require(['@/views/relation/index.vue'], resolve),
        children : [
            {
                path: '/relation/game/lol/index',
                name: 'relation_game_lol_index',
                meta: { 
                    title: "Lol",
                    desc : "Lol关联",
                    keepAlive: true,
                },
                component: resolve => require(['@/views/relation/game/lol/index.vue'], resolve),
                children: [
                    {
                        path: '/relation/game/lol/equipment',
                        name: 'relation_game_lol_equipment',
                        meta: { 
                            title: "装备",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/lol/equipment.vue'], resolve),
                    },
                    {
                        path: '/relation/game/lol/hero',
                        name: 'relation_game_lol_hero',
                        meta: { 
                            title: "英雄",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/lol/hero.vue'], resolve),
                    },
                    {
                        path: '/relation/game/lol/team',
                        name: 'relation_game_lol_team',
                        meta: { 
                            title: "战队",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/lol/team.vue'], resolve),
                    },
                    {
                        path: '/relation/game/lol/player',
                        name: 'relation_game_lol_player',
                        meta: { 
                            title: "选手",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/lol/player.vue'], resolve),
                    },
                    {
                        path: '/relation/game/lol/spell',
                        name: 'relation_game_lol_spell',
                        meta: { 
                            title: "召唤师技能",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/lol/spell.vue'], resolve),
                    },
                    {
                        path: '/relation/game/lol/tournament',
                        name: 'relation_game_lol_tournament',
                        meta: { 
                            title: "赛事",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/lol/tournament.vue'], resolve),
                    },
                    {
                        path: '/relation/game/lol/match',
                        name: 'relation_game_lol_match',
                        meta: { 
                            title: "比赛",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/lol/match.vue'], resolve),
                    },
                ]
            },
            {
                path: '/relation/game/dota2/index',
                name: 'relation_game_dota2_index',
                meta: { 
                    title: "Dota2",
                    desc : "dota2关联",
                    keepAlive: true,
                },
                component: resolve => require(['@/views/relation/game/dota2/index.vue'], resolve),
                children: [
                    {
                        path: '/relation/game/dota2/equipment',
                        name: 'relation_game_dota2_equipment',
                        meta: { 
                            title: "装备",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/dota2/equipment.vue'], resolve),
                    },
                    {
                        path: '/relation/game/dota2/hero',
                        name: 'relation_game_dota2_hero',
                        meta: { 
                            title: "英雄",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/dota2/hero.vue'], resolve),
                    },
                    {
                        path: '/relation/game/dota2/team',
                        name: 'relation_game_dota2_team',
                        meta: { 
                            title: "战队",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/dota2/team.vue'], resolve),
                    },
                    {
                        path: '/relation/game/dota2/player',
                        name: 'relation_game_dota2_player',
                        meta: { 
                            title: "选手",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/dota2/player.vue'], resolve),
                    },
                    // {
                    //     path: '/relation/game/dota2/spell',
                    //     name: 'relation_game_dota2_spell',
                    //     meta: { 
                    //         title: "召唤师技能",
                    //         keepAlive: true,
                    //         hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                    //     },
                    //     component: resolve => require(['@/views/relation/game/dota2/spell.vue'], resolve),
                    // },
                    {
                        path: '/relation/game/dota2/tournament',
                        name: 'relation_game_dota2_tournament',
                        meta: { 
                            title: "赛事",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/dota2/tournament.vue'], resolve),
                    },
                    {
                        path: '/relation/game/dota2/match',
                        name: 'relation_game_dota2_match',
                        meta: { 
                            title: "比赛",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/dota2/match.vue'], resolve),
                    },
                ]
            },
            {
                path: '/relation/game/kog/index',
                name: 'relation_game_kog_index',
                meta: { 
                    title: "Kog",
                    desc : "Kog关联",
                    keepAlive: true,
                },
                component: resolve => require(['@/views/relation/game/kog/index.vue'], resolve),
                children: [
                    // {
                    //     path: '/relation/game/kog/equipment',
                    //     name: 'relation_game_kog_equipment',
                    //     meta: { 
                    //         title: "装备",
                    //         keepAlive: true,
                    //         hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                    //     },
                    //     component: resolve => require(['@/views/relation/game/kog/equipment.vue'], resolve),
                    // },
                    {
                        path: '/relation/game/kog/hero',
                        name: 'relation_game_kog_hero',
                        meta: { 
                            title: "英雄",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/kog/hero.vue'], resolve),
                    },
                    {
                        path: '/relation/game/kog/team',
                        name: 'relation_game_kog_team',
                        meta: { 
                            title: "战队",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/kog/team.vue'], resolve),
                    },
                    {
                        path: '/relation/game/kog/player',
                        name: 'relation_game_kog_player',
                        meta: { 
                            title: "选手",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/kog/player.vue'], resolve),
                    },
                    // {
                    //     path: '/relation/game/kog/spell',
                    //     name: 'relation_game_kog_spell',
                    //     meta: { 
                    //         title: "召唤师技能",
                    //         keepAlive: true,
                    //         hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                    //     },
                    //     component: resolve => require(['@/views/relation/game/kog/spell.vue'], resolve),
                    // },
                    // {
                    //     path: '/relation/game/kog/tournament',
                    //     name: 'relation_game_kog_tournament',
                    //     meta: { 
                    //         title: "赛事",
                    //         keepAlive: true,
                    //         hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                    //     },
                    //     component: resolve => require(['@/views/relation/game/kog/tournament.vue'], resolve),
                    // },
                    {
                        path: '/relation/game/kog/match',
                        name: 'relation_game_kog_match',
                        meta: { 
                            title: "比赛",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/kog/match.vue'], resolve),
                    },
                ]
            },
            {
                path: '/relation/game/csgo/index',
                name: 'relation_game_csgo_index',
                meta: { 
                    title: "Csgo",
                    desc : "csgo关联",
                    keepAlive: true,
                },
                component: resolve => require(['@/views/relation/game/csgo/index.vue'], resolve),
                children: [
                    {
                        path: '/relation/game/csgo/team',
                        name: 'relation_game_csgo_team',
                        meta: { 
                            title: "战队",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/csgo/team.vue'], resolve),
                    },
                    {
                        path: '/relation/game/csgo/player',
                        name: 'relation_game_csgo_player',
                        meta: { 
                            title: "选手",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/csgo/player.vue'], resolve),
                    },
                    {
                        path: '/relation/game/csgo/tournament',
                        name: 'relation_game_csgo_tournament',
                        meta: { 
                            title: "赛事",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/csgo/tournament.vue'], resolve),
                    },
                    {
                        path: '/relation/game/csgo/match',
                        name: 'relation_game_csgo_match',
                        meta: { 
                            title: "比赛",
                            keepAlive: true,
                            hideMenu: true, // 隐藏目录 - 并且每一个用户都有访问权限 二级目录隐藏 三级目录展示
                        },
                        component: resolve => require(['@/views/relation/game/csgo/match.vue'], resolve),
                    },
                ]
            },
        ],
    };
    ballsports_menu.push(admin_menu);
    ballsports_menu.push(relation_menu);
    for( var sport_id in config.ballsports.sports){
        // console.log(sport_id, typeof sport_id);
        let int_sport_id = parseInt(sport_id);
        let sport = config.ballsports.sports[sport_id];
        let menu = {};
        let name_en = sport.name_en;
        menu.path = '/ballsports/' + name_en;
        menu.name = 'ballsports_' + name_en;
        menu.meta = { 
            title: sport.name_zh,
            keepAlive: true,
        }
        if (sport.icon){
            menu.meta.icon = sport.icon;
        }else if (sport.logo){
            menu.meta.logo = require('@/assets/' + sport.logo);
        }
        menu.component = resolve => require(['@/views/ballsports/'+ name_en +'/index.vue'], resolve);
        // 子页面
        menu.children = [
            {
                path: '/ballsports/'+ name_en +'/match',
                name: 'ballsports_'+ name_en +'_match',
                meta: { 
                    title: "比赛",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/match.vue'], resolve)
            },
            {
                path: '/ballsports/'+ name_en +'/unique_tournament',
                name: 'ballsports_'+ name_en +'_unique_tournament',
                meta: { 
                    title: "赛事",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/unique_tournament.vue'], resolve)
            },
            {
                path: '/ballsports/'+ name_en +'/season',
                name: 'ballsports_'+ name_en +'_season',
                meta: { 
                    title: "赛季",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/season.vue'], resolve)
            },
            {
                path: '/ballsports/'+ name_en +'/tournament',
                name: 'ballsports_'+ name_en +'_tournament',
                meta: { 
                    title: "阶段",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/tournament.vue'], resolve)
            },
            {
                path: '/ballsports/'+ name_en +'/team',
                name: 'ballsports_'+ name_en +'_team',
                meta: { 
                    title: "队伍",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/team.vue'], resolve)
            },
            
            {
                path: '/ballsports/'+ name_en +'/player',
                name: 'ballsports_'+ name_en +'_player',
                meta: { 
                    title: "选手",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/player.vue'], resolve)
            },

            {
                path: '/ballsports/'+ name_en +'/venue',
                name: 'ballsports_'+ name_en +'_venue',
                meta: { 
                    title: "场馆",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/venue.vue'], resolve)
            },
            {
                path: '/ballsports/'+ name_en +'/competition_level',
                name: 'ballsports_'+ name_en +'_competition_level',
                meta: { 
                    title: "赛事等级",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/competition_level.vue'], resolve)
            },

            // 几个单独的编辑页面
            {  
                path: '/ballsports/'+ name_en +'/unique_tournament/detail',
                name: 'ballsports_'+ name_en +'_unique_tournament_detail',
                props: (router) => ({
                    sport_id: int_sport_id,
                    unique_tournament_id: parseInt( router.query.unique_tournament_id ),
                }),
                meta: { 
                    title: "赛事详情页",
                    keepAlive: true,
                    hideMenu: true,
                }, //
                component: resolve => require(['@/components/ballsports/unique_tournament/detail.vue'], resolve)
            },
            {
                path: '/ballsports/'+ name_en +'/season/detail',
                name: 'ballsports_'+ name_en +'_season_detail',
                props: (router) => ({
                    sport_id: int_sport_id,
                    season_id: parseInt( router.query.season_id ),
                }),
                meta: { 
                    title: "赛季详情页",
                    keepAlive: true,
                    hideMenu: true,
                }, 
                component: resolve => require(['@/components/ballsports/season/detail.vue'], resolve)
            },
            {
                path: '/ballsports/'+ name_en +'/team/detail',
                name: 'ballsports_'+ name_en +'_team_detail',
                props: (router) => ({
                    sport_id: int_sport_id,
                    team_id: parseInt( router.query.team_id ),
                }),
                meta: { 
                    title: "队伍详情页",
                    keepAlive: true,
                    hideMenu: true,
                },
                component: resolve => require(['@/components/ballsports/team/detail.vue'], resolve)
            },
            // 对阵图
            {
                path: '/ballsports/'+ name_en +'/season/map',
                name: 'ballsports_'+ name_en +'_season_map',
                props: (router) => ({
                    sport_id: int_sport_id,
                    season_id: parseInt( router.query.season_id ),
                }),
                meta: { 
                    title: "赛季对阵图",
                    keepAlive: true,
                    hideMenu: true,
                },
                component: resolve => require(['@/components/ballsports/season/map.vue'], resolve)
            },
            // 积分榜
            {
                path: '/ballsports/'+ name_en +'/season/table',
                name: 'ballsports_'+ name_en +'_season_table',
                props: (router) => ({
                    sport_id: int_sport_id,
                    season_id: parseInt( router.query.season_id ),
                }),
                meta: { 
                    title: "赛季积分榜",
                    keepAlive: true,
                    hideMenu: true,
                },
                component: resolve => require(['@/components/ballsports/season/table.vue'], resolve)
            },
        ]
        if (sport_id == 2){
            // 篮球文字直播页面
            menu.children.push({
                path: '/ballsports/'+ name_en +'/match/text_live/detail',
                name: 'ballsports_'+ name_en +'_match_text_live_detail',
                props: (router) => ({
                    sport_id: int_sport_id,
                    season_id: parseInt( router.query.season_id ),
                }),
                meta: { 
                    title: "文字直播",
                    keepAlive: true,
                    hideMenu: true,
                },
                component: resolve => require(['@/components/ballsports/match/text_live/detail.vue'], resolve)
            })
            menu.children.push({
                path: '/ballsports/'+ name_en +'/match/text_live/demo',
                name: 'ballsports_'+ name_en +'_match_text_live_demo',
                props: (router) => ({
                    sport_id: int_sport_id,
                    season_id: parseInt( router.query.season_id ),
                }),
                meta: { 
                    title: "文字直播-线上",
                    keepAlive: true,
                    hideMenu: true,
                },
                component: resolve => require(['@/components/ballsports/match/text_live/demo.vue'], resolve)
            })
            menu.children.push({
                path: '/ballsports/'+ name_en +'/match/text_live/line',
                name: 'ballsports_'+ name_en +'_match_text_live_line',
                props: (router) => ({
                    sport_id: int_sport_id,
                    season_id: parseInt( router.query.season_id ),
                }),
                meta: { 
                    title: "文字直播-编辑线上",
                    keepAlive: true,
                    hideMenu: true,
                },
                component: resolve => require(['@/components/ballsports/match/text_live/line.vue'], resolve)
            })
            menu.children.push({
                path: '/ballsports/'+ name_en +'/match/player_stat',
                name: 'ballsports_'+ name_en +'_match_player_stat',
                props: (router) => ({
                    sport_id: int_sport_id,
                    match_id: parseInt( router.query.match_id ),
                }),
                meta: { 
                    title: "技术统计",
                    keepAlive: true,
                    hideMenu: true,
                },
                component: resolve => require(['@/components/ballsports/match/player_stat/player_stat.vue'], resolve)
            })
        }else if (sport_id == 1){
            menu.children.push({
                path: '/ballsports/'+ name_en +'/player_honor',
                name: 'ballsports_'+ name_en +'_player_honor',
                meta: { 
                    title: "选手荣誉",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/player_honor.vue'], resolve)
            })
            menu.children.push({
                path: '/ballsports/'+ name_en +'/team_honor',
                name: 'ballsports_'+ name_en +'_team_honor',
                meta: { 
                    title: "战队荣誉",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/team_honor.vue'], resolve)
            })
            menu.children.push({
                path: '/ballsports/'+ name_en +'/honor',
                name: 'ballsports_'+ name_en +'_honor',
                meta: { 
                    title: "荣誉",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/honor.vue'], resolve)
            })
            menu.children.push({
                path: '/ballsports/'+ name_en +'/odds',
                name: 'ballsports_'+ name_en +'_odds',
                meta: { 
                    title: "指数",
                    keepAlive: true,
                    hideMenu: true,
                }, //页面需要的权限
                props: (router) => ({
                    sport_id: parseInt( router.query.sport_id ),
                    match_id: parseInt( router.query.match_id ),
                }),
                component: resolve => require(['@/components/ballsports/odds/detail.vue'], resolve)
            })
            menu.children.push({
                path: '/ballsports/'+ name_en +'/team_ranking',
                name: 'ballsports_'+ name_en +'_team_ranking',
                meta: { 
                    title: "战队排名",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/ballsports/'+ name_en +'/team_ranking.vue'], resolve)
            })
        }
        ballsports_menu.push(menu);
    }
    // 奥运会专题
    let olympic_menu = {
        path: '/olympics/index',
        name: 'olympics_index',
        meta: { 
            title: "奥运会",
            keepAlive: true,
            // icon: 'md-walk',
            logo: require('@/assets/' + 'olympics.png') ,
            class: 'menu-group',
        }, 
        component: resolve => require(['@/views/olympics/index.vue'], resolve),
        children : [
            {
                path: '/olympics/schedule/list',
                name: 'olympics_schedule_list',
                meta: { 
                    title: "赛程",
                    keepAlive: true,
                },  
                component: resolve => require(['@/views/olympics/schedule/list.vue'], resolve)
            },
            {
                path: '/olympics/schedule/team',
                name: 'olympics_schedule_team',
                meta: { 
                    title: "赛程战队",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/schedule/team_list.vue'], resolve)
            },
            {
                path: '/olympics/delegation/list',
                name: 'olympics_delegation_list',
                meta: { 
                    title: "代表团",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/delegation/list.vue'], resolve)
            },
            {
                path: '/olympics/player/list',
                name: 'olympics_player_list',
                meta: { 
                    title: "运动员",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/player/list.vue'], resolve)
            },
            {
                path: '/olympics/medal/list',
                name: 'olympics_medal_list',
                meta: { 
                    title: "奖牌",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/medal/list.vue'], resolve)
            },
            {
                path: '/olympics/venue/list',
                name: 'olympics_venue_list',
                meta: { 
                    title: "场馆",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/venue/list.vue'], resolve)
            },
            {
                path: '/olympics/area/list',
                name: 'olympics_area_list',
                meta: { 
                    title: "国家/地区",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/area/list.vue'], resolve)
            },
            {
                path: '/olympics/sport/list',
                name: 'olympics_sport_list',
                meta: { 
                    title: "运动项",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/sport/list.vue'], resolve)
            },
            {
                path: '/olympics/subsport/list',
                name: 'olympics_subsport_list',
                meta: { 
                    title: "子运动项",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/subsport/list.vue'], resolve)
            },
            {
                path: '/olympics/player_honor/list',
                name: 'olympics_player_honor_list',
                meta: { 
                    title: "运动员荣誉",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/player_honor/list.vue'], resolve)
            },
            
            {
                path: '/olympics/delegation_player/list',
                name: 'olympics_delegation_player_list',
                meta: { 
                    title: "代表团选手",
                    keepAlive: true,
                }, 
                component: resolve => require(['@/views/olympics/delegation_player/list.vue'], resolve)
            },
        ],
    }
    ballsports_menu.push(olympic_menu);

    

    // 电竞
    for( var game_id in config.games.games){
        // console.log(game_id, typeof game_id);
        let int_game_id = parseInt(game_id);
        let game = config.games.games[game_id];
        let menu = {};
        let name_en = game.name_en;
        menu.path = '/games/' + name_en;
        menu.name = 'games_' + name_en;
        menu.meta = { 
            title: game.name,
            keepAlive: true,
            group: 'games',
        }
        if (game.icon){
            menu.meta.icon = sport.icon;
        }else if (game.logo){
            menu.meta.logo = require('@/assets/' + game.logo);
        }
        menu.component = resolve => require(['@/views/games/'+ name_en +'/index.vue'], resolve);
        // 子页面
        menu.children = [
            {
                path: '/games/'+ name_en +'/match',
                name: 'games_'+ name_en +'_match',
                meta: { 
                    title: "比赛",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/match.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/match_single',
                name: 'games_'+ name_en +'_match_single',
                meta: { 
                    title: "小局比赛",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/match_single.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/player_stat',
                name: 'games_'+ name_en +'_player_stat',
                meta: { 
                    title: "选手统计",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/player_stat.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/esports_tournament',
                name: 'games_'+ name_en +'_esports_tournament',
                meta: { 
                    title: "总赛事",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/esports_tournament.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/tournament',
                name: 'games_'+ name_en +'_tournament',
                meta: { 
                    title: "赛事",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/tournament.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/stage',
                name: 'games_'+ name_en +'_stage',
                meta: { 
                    title: "阶段",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/stage.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/round',
                name: 'games_'+ name_en +'_round',
                meta: { 
                    title: "轮次",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/round.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/tournament/boards',
                name: 'games_'+ name_en +'_tournament_boards',
                meta: { 
                    title: "赛事积分榜",
                    keepAlive: true,
                    hideMenu: true,
                }, //页面需要的权限
                component: resolve => require(['@/components/games/tournament/boards.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/tournament/results',
                name: 'games_'+ name_en +'_tournament_results',
                meta: { 
                    title: "赛事战队名次",
                    keepAlive: true,
                    hideMenu: true,
                }, //页面需要的权限
                component: resolve => require(['@/components/games/tournament/results.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/team',
                name: 'games_'+ name_en +'_team',
                meta: { 
                    title: "战队",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/team.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/player',
                name: 'games_'+ name_en +'_player',
                meta: { 
                    title: "选手",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/player.vue'], resolve)
            },
            {
                path: '/games/'+ name_en +'/country',
                name: 'games_'+ name_en +'_country',
                meta: { 
                    title: "国家",
                    keepAlive: true,
                }, //页面需要的权限
                component: resolve => require(['@/views/games/'+ name_en +'/country.vue'], resolve)
            },
            
        ]
        if ([1,3,6].indexOf(int_game_id) > -1){
            menu.children = menu.children.concat( [
                {
                    path: '/games/'+ name_en +'/hero',
                    name: 'games_'+ name_en +'_hero',
                    meta: { 
                        title: "英雄",
                        keepAlive: true,
                    }, //页面需要的权限
                    component: resolve => require(['@/views/games/'+ name_en +'/hero.vue'], resolve)
                },
                {
                    path: '/games/'+ name_en +'/equipment',
                    name: 'games_'+ name_en +'_equipment',
                    meta: { 
                        title: "装备",
                        keepAlive: true,
                    }, //页面需要的权限
                    component: resolve => require(['@/views/games/'+ name_en +'/equipment.vue'], resolve)
                },
            ] )
        }else if ([2].indexOf(int_game_id) > -1){
            menu.children = menu.children.concat( [
                {
                    path: '/games/'+ name_en +'/weapon',
                    name: 'games_'+ name_en +'_weapon',
                    meta: { 
                        title: "武器",
                        keepAlive: true,
                    }, //页面需要的权限
                    component: resolve => require(['@/views/games/'+ name_en +'/weapon.vue'], resolve)
                },
            ] )
        }
        if ([3].indexOf(int_game_id) > -1){
            menu.children = menu.children.concat( [
                {
                    path: '/games/'+ name_en +'/facet',
                    name: 'games_'+ name_en +'_facet',
                    meta: { 
                        title: "命石",
                        keepAlive: true,
                    }, //页面需要的权限
                    component: resolve => require(['@/views/games/'+ name_en +'/facet.vue'], resolve)
                },
            ] )
        }
        ballsports_menu.push(menu);
    }
    return ballsports_menu;
}

export const asyncRouterMap = getBallsportsList();
// console.log('[router/index.js]', asyncRouterMap)

export const whiteList = [
    // 不需要认证的白名单
    '/login/index',
    '/login/authorize',
    '/login/oauth2/callback',
    '/permission_error',
    '/404',
]

const router = new Router({
    mode: 'history',
    routes: [...constantRouterMap]
})

router.beforeEach((to, from, next) => {
    // console.log("debug: token: ", store.state.user.access_token );
    if (!store.state.user.access_token){
        let user = localStorage.getItem( "user" );
        // console.log("[router/index.js] user: ", user);
        if (user){
            user = JSON.parse(user);
            // console.log("user: ", user);
            if (user){
                store.commit('user/loginSuccess', user);
            }
        }
    }
    if ( store.state.user.access_token ) { // 判断是否有token
        if (to.path === '/login/index') {
            next({ path: '/index' });
        } else if (to.path.startsWith('/v3/')) {
            next();
        } else {
            // console.log("store.state.permission.addRoutes.length: ", store.state.permission.addRoutes)
            if (store.state.permission.addRoutes.length === 0) { // 判断当前用户是否已拉取完user_info信息
                const route_names = store.state.user.route_names || {};
                // console.log("route_names: ", route_names)
                store.dispatch('permission/generateRoutes', route_names ).then(routers =>{
                    // console.log("routes: ", routers)
                    if (routers.length > 0){
                        // 处理重定向的关系
                        routers.forEach(element => {
                            const children = element.children;
                            if (children && children.length > 0){
                                var child = children[0];
                                element['redirect'] = child.path;
                            }
                        });
                        router.addRoutes(routers.concat([
                            {
                                path: '*',
                                name: 'error_404',
                                meta: {
                                    keepAlive: true,
                                    hidden: true,
                                },
                                component: () => import('@/views/error-page/404.vue')
                            }
                        ]))
                        // console.log(to);
                        next({ ...to, replace: true });
                    }else{
                        // 跳转到404错误页
                        if (to.matched.length === 0){
                            next('/404')
                        }
                        next();
                    }
                }).catch(() => {
                    // setToken('')
                    // next('/login/index');
                })
                // 生成可访问的路由表
                // router.addRoutes(store.getters.addRoutes) // 动态添加可访问路由表
                // console.log("store.state.permission:", store.state.permission.addRoutes)
            } else {
                next(); //当有用户权限的时候，说明所有可访问路由已生成 如访问没权限的全面会自动进入404页面
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
            next();
        } else {
            next( {
                path: '/login/index',
                // query: {
                //     redirect: to.fullPath
                // }
            } ); // 否则全部重定向到登录页
        }
    }
})

// 添加路由守卫 防止打开新的页面的时候 滚动条消失无法下拉滚动的bug
router.afterEach((to, from, next) => {
    document.querySelector("body").setAttribute("style", "overflow: auto !important;")
})
export default router