<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="550"
        :mask-closable="true"
    >
        <esportsTournamentEdit :game_id="game_id" :_id="edit_id" @closePage="closeEditDrawer"></esportsTournamentEdit>
    </Drawer>  
    <Row>
        <Col  style="text-align: left">
            <Input v-model="search_key" placeholder="搜索名称/id" search @on-search="onSearch" />
        </Col>
        <Col style="text-align: left">
            <!-- <RadioGroup v-model="status_id" type="button">
                <Radio label='-1' border>全部</Radio>
                <Radio label='1' border>未开始</Radio>
                <Radio label='2' border>进行中</Radio>
                <Radio label='3' border>已结束</Radio>
            </RadioGroup> -->
        </Col>
        <Col >
            <Button type="success"  @click="add">新增</Button>
        </Col>
    </Row>
    <fitTable :columns="columns" :data="data" :loading="loading"></fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
      ></Page>
  </div>
</template>

<script>

import { EsportsTournamentList } from '@/api/games/esports_tournament';
import fitTable from '../../basic/table.vue';
import esportsTournamentEdit from './edit.vue';


const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                // margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
  props: {
      game_id: Number,   // 运动id
  },
  data () {
    let self = (window.x = this)
    return {
      search_key: '',
      loading: false,
      total_page: 0,
      page_size: 0,
      current_page: 1,
      columns: [
        {
          title: 'id',
          width: '100',
          key: 'id'
        },
        {
          title: '游戏',
          width: '100',
          key: 'game_id',
          render: function(h, params){
            let currentRow = params.row
            let game = self.$conf.games.games[currentRow.game_id]
            return h('span', game["name"])
          }
        },
        {
          title: '分类',
          minWidth: 80,
          key: 'mode',
        },
        {
          title: '级别',
          minWidth: 80,
          key: 'level',
        },
        {
          title: '中文全称',
          minWidth: 80,
          key: 'name_zh',
        },
        {
          title: '中文简称',
          minWidth: 80,
          key: 'short_name_zh',
        },
        {
          title: '英文全称',
          minWidth: 80,
          key: 'name_en',
        },
        {
          title: '英文简称',
          minWidth: 80,
          key: 'short_name_en',
        },

        {
          title: 'logo',
          minWidth: 80,
          key: 'logo',
          align: 'center',
          render: function (h, params) {
            let currentRow = params.row
            // console.log(self.$conf.games.img_prefix + 'esports/tournament/' + currentRow.logo);
            return h('img', {
              attrs: {
                src: currentRow.logo ? self.$conf.games.img_prefix + '/esports/tournament/' + currentRow.logo: '', //getImgGameUrl(self.game_id, 'tournament', currentRow.logo),
              },
              class: {'tournament-logo': true} 
            })
          }
        },
        {
          title: '操作',
          key: 'deleted',
          align: 'center',
          width: 80,
          render (h, params) {
            return [editButton(self, h, params),]
          }
        }

      ],
      data: [],

      drawer_edit: false,
      edit_id: 0,
    }
  },
  components: {
    // TournamentFinder
    fitTable,
    esportsTournamentEdit,
  },
  methods: {
    onPageChange (page) {
      this.current_page = page
      this.getData()
    },
    onSearch (search_key) {
      this.current_page = 1
      this.search_key = search_key
      this.getData()
    },
    getData () {
      let self = this
      // console.log(process.env.NODE_ENV);

      this.loading = true
      var params = {
        game_id: self.game_id,
        page: self.current_page,
        search_key: self.search_key,
      }
      EsportsTournamentList(params).then(response => {
        if (response.data.code == 0) {
          this.data = response.data.data.list;
          this.total_page = response.data.data.total_page;
          this.page_size = response.data.data.page_size;
          this.current_page = response.data.data.current_page;
          this.loading = false;
        } else {
          this.$Message.error(response.data.msg);
        }
      })
    },
    // end
      closeEditDrawer(){
          let self = this;
          self.drawer_edit = false;
          self.edit_id = 0;
          self.getData();
      },
    // end
    add () {
        this.edit_id = 0;
        this.drawer_edit = true
    },
    // end
  },
  mounted () {
    this.getData()
  },
  watch: {
    game_id (value) {
      let self = this
      // console.log(value)
      self.getData()
    },
    date (value) {
      let self = this
      self.getData()
    }

  },
  computed: {}
}
</script>

<style scoped>
    /* @import "list.css" */
</style>
