<template>
    <div>
        <matchSingleList :game_id="game_id"></matchSingleList>
    </div>
</template>

<script>

import matchSingleList from '@/components/games/match_single/list.vue';

export default {
    components: {
        matchSingleList
    },
    data () {
        let self = (window.x = this)
        return {
            game_id: 3,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>