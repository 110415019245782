<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="id" v-if="data.id">
                        <Input v-model="data.id"  disabled></Input>
                    </FormItem>
                    <FormItem label="总赛事">
                        <Row>
                            <Col span="20" >
                                <esportsTournamentSearch v-model="data.esports_tournament" :game_id="game_id" ></esportsTournamentSearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="类型">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.tier" >
                                    <Option v-for="(item, index) in $conf.games.tier[game_id]" :value="item.id" :key="item.id">{{item.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="国家" v-if="$unit.filterGameids( [2,3], game_id )">
                        <Row>
                            <Col span="20" >
                                <countrySearch v-model="data.country" :game_id="game_id"> </countrySearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="地图池" v-if="$unit.filterGameids( [2], game_id )">
                        <Row>
                            <Col span="20" >
                                <mapsDragable v-model="data.maps" :game_id="game_id" ></mapsDragable>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="中文">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.name_zh" ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="英文">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.name_en" ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="中文简称">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.abbr_zh" ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="英文简称">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.abbr_en" ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="状态">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.status_id" >
                                    <Option v-for="item in $conf['games']['status']['map']" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </Col>
                            <Col span="4">
                                <!-- {{ locks }} -->
                                <dataLock :data_type="obj_type" :sport_id="100+game_id" :data_id="tournament_id" 
                                    :k="'status_id'" :v="(locks && locks.hasOwnProperty('status_id'))?locks['status_id']:0"></dataLock>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="开始时间: " >
                        <Row>
                            <Col span="20" >
                                <DatePicker 
                                    type="datetime" 
                                    format="yyyy-MM-dd HH:mm" 
                                    style="width: 100%"
                                    v-model="start_date"
                                >
                                </DatePicker>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="结束时间: " >
                        <Row>
                            <Col span="20" >
                                <DatePicker 
                                    type="datetime" 
                                    format="yyyy-MM-dd HH:mm" 
                                    style="width: 100%"
                                    v-model="end_date"
                                >
                                </DatePicker>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="logo: ">
                        <Row>
                            <imgEditor v-model="data.logo" :prefix="$conf.games.img_prefix" :dir="$conf.games.games[game_id].img + '/tournament' " 
                                :previewButton='true' :clearButton='true' cdn='games'>
                            </imgEditor>
                        </Row>
                    </FormItem>
                    <FormItem label="封面: ">
                        <Row>
                            <imgEditor v-model="data.cover" :prefix="$conf.games.img_prefix" :dir="$conf.games.games[game_id].img + '/tournament/cover' " 
                                :previewButton='true' :clearButton='true' cdn='games'>
                            </imgEditor>
                        </Row>
                    </FormItem>

                    <FormItem label="奖金池" v-if=" ! $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.price" ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="中文地址" v-if=" ! $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.address" ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="英文地址" v-if=" ! $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.address_en" ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="热门">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.is_hot" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="删除">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.deleted" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane>
        </Tabs>

        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>
</template>
<script>

import { List, editTournament } from '@/api/games/tournament';
import countrySearch from '../country/search.vue';
import esportsTournamentSearch from '../esports_tournament/search.vue';
import moment from 'moment';
import imgEditor from '@/components/img-editor/img-editor.vue';
import mapsDragable from '../base/maps_dragable.vue';
import dataLock from '@/components/basic/data_lock.vue';

export default {
    name: "tournament-edit",
    props: {
        game_id: Number,   // 运动id
        tournament_id: Number,   // 比赛id
    },
    components: {
        imgEditor,
        countrySearch,
        esportsTournamentSearch,
        mapsDragable,
        dataLock,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 12,
            type: 5, 
            spinShow: false,
            data: {
                game_id: self.game_id,
                country: {},
            },
            locks: {},
        }
    },
    methods: {
        // 获取分别的名称
        getTeamSide(){
            if (this.game_id == 1){
                return ['', '']
            }else if (this.game_id == 3 ){
                return ['天辉', '夜魇']
            }else{
                return ['', '']
            }
        },

        _init_data(){
            this.data = {
                game_id: this.game_id,
                country: {},
            }
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.tournament_id > 0){
                var params = {
                    game_id: self.game_id,
                    tournament_id: self.tournament_id,
                }
                List( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks;
                        }else{
                            this.data = {}
                        }
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self._init_data();
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            self.emit("cancle")
        },
        save() {
            let self = this;
            let tournament_uid = self.data.esports_tournament? self.data.esports_tournament.id: 0;

            if (self.game_id == 1){
                // lol
                var params = {
                    id: self.data.id,
                    tournament_uid,
                    game_id: self.data.game_id || self.game_id,
                    tier: self.data.tier,
                    name_en: self.data.name_en,
                    name_zh: self.data.name_zh,
                    abbr_en: self.data.abbr_en,
                    abbr_zh: self.data.abbr_zh,
                    status_id: self.data.status_id,
                    logo: self.data.logo,
                    cover: self.data.cover,
                    start_date: self.data.start_date ,
                    end_date: self.data.end_date ,
                    prize_pool: self.data.price ,
                    address: self.data.address ,
                    address_en: self.data.address_en ,
                    is_hot: self.data.is_hot ,
                    deleted: self.data.deleted,
                };
            }else if (self.game_id == 2){
                // 地图池
                var map_pool = [];
                if (self.data.maps && self.data.maps.length > 0){
                    self.data.maps.forEach(element => {
                        map_pool.push(element.id);
                    });
                }
                var params = {
                    id: self.data.id,
                    tournament_uid,
                    game_id: self.data.game_id || self.game_id,
                    country_id: self.data.country.id,
                    map_pool: map_pool,
                    mode: self.data.tier,
                    name_en: self.data.name_en,
                    name_zh: self.data.name_zh,
                    abbr_en: self.data.abbr_en,
                    abbr_zh: self.data.abbr_zh,
                    status_id: self.data.status_id,
                    logo: self.data.logo,
                    cover: self.data.cover,
                    start_time: self.data.start_date ,
                    end_time: self.data.end_date ,
                    price: self.data.price ,
                    city_name_zh: self.data.address ,
                    city_name_en: self.data.address_en ,
                    is_hot: self.data.is_hot ,
                    deleted: self.data.deleted,
                };
            }else if (self.game_id == 3){
                var params = {
                    id: self.data.id,
                    tournament_uid,
                    game_id: self.data.game_id || self.game_id,
                    country_id: self.data.country.id,
                    tier: self.data.tier,
                    name_en: self.data.name_en,
                    name_zh: self.data.name_zh,
                    short_name_en: self.data.abbr_en,
                    short_name_zh: self.data.abbr_zh,
                    status_id: self.data.status_id,
                    logo: self.data.logo,
                    cover: self.data.cover,
                    start_time: self.data.start_date ,
                    end_time: self.data.end_date ,
                    prize_pool: self.data.price ,
                    city_name: self.data.address ,
                    city_name_en: self.data.address_en ,
                    is_hot: self.data.is_hot ,
                    deleted: self.data.deleted,
                };
            }else if (self.game_id == 6){
                var params = {
                    id: self.data.id,
                    tournament_uid,
                    game_id: self.data.game_id || self.game_id,
                    mode: self.data.tier,
                    name_en: self.data.name_en,
                    name_zh: self.data.name_zh,
                    short_name_en: self.data.abbr_en,
                    short_name_zh: self.data.abbr_zh,
                    status_id: self.data.status_id,
                    logo: self.data.logo,
                    cover: self.data.cover,
                    start_time: self.data.start_date ,
                    end_time: self.data.end_date ,
                    is_hot: self.data.is_hot ,
                    deleted: self.data.deleted,
                };
            }else{
                return
            }
            // console.log(params);
            editTournament(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
    },
    watch: {
        'tournament_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: false,
	        immediate: false,  // 深度触发
        },
        // end 
    },
    computed: {
        start_date: {
            get: function () {
                return moment.unix(this.data.start_date).toDate();
            },
            set: function (newValue) {
                this.data.start_date = moment(newValue).unix();
            }
        },
        end_date: {
            get: function () {
                return moment.unix(this.data.end_date).toDate();
            },
            set: function (newValue) {
                this.data.end_date = moment(newValue).unix();
            }
        },
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>