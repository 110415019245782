<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="id" v-if="data.id">
                        <Input v-model="data.id"  disabled></Input>
                    </FormItem>

                    <!-- <FormItem label="实时数据" v-if="game_id==1 && source.id>0">
                        <Row>
                            <Col span="20" >
                                <Select  v-model="source.source.live" >
                                    <Option :value="0" key="0">空</Option>
                                    <Option :value="4" key="4">源4</Option>
                                    <Option :value="5" key="5">源5</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem> -->

                    <FormItem label="赛事">
                        <Row>
                            <Col span="20" >
                                <tournamentSearch v-model="data.tournament" :game_id="game_id" ></tournamentSearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="阶段">
                        <Row>
                            <Col span="20" >
                                <stageSearch v-model="data.stage" :game_id="game_id" ></stageSearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="盘数(BO)" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.box" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="主队">
                        <Row>
                            <Col span="20" >
                                <teamSearch v-model="data.home" :game_id="game_id" ></teamSearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="客队">
                        <Row>
                            <Col span="20" >
                                <teamSearch v-model="data.away" :game_id="game_id" ></teamSearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="主分" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_score" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="客分" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_score" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="时间" >
                        <Row>
                            <Col span="20" >
                                <DatePicker 
                                    type="datetime" 
                                    format="yyyy-MM-dd HH:mm" 
                                    style="width: 100%"
                                    v-model="match_time"
                                >
                                </DatePicker>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="状态">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.status_id" >
                                    <Option v-for="item in $conf['games']['status']['map']" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="删除">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.deleted" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="额外描述(中文): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra_data.reason_zh" ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="额外描述(英文): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra_data.reason_en" ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane>
        </Tabs>

        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>
</template>
<script>

import { GameMatchList, editMatch } from '@/api/games/match';
// import { updateDetailSource } from '@/api/relation/games/detail';
import teamSearch from '../team/search.vue';
// import uniqueTournamentSearch from '../unique_tournament/search.vue';
import stageSearch from '../stage/search.vue';
import tournamentSearch from '../tournament/search.vue';

import moment from 'moment';

export default {
    name: "match-edit",
    props: {
        game_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
        teamSearch,
        // uniqueTournamentSearch,
        stageSearch,
        tournamentSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            source: {}, // 源
            obj_type: 1,
            type: 5, 
            spinShow: false,
            data: {
                game_id: self.game_id,
                home: {},
                away: {},
                stage: {},
                tournament: {},
                match_time: parseInt( Date.parse(new Date())/1000 ),
                extra_data: {},
            },
            locks: {},
        }
    },
    methods: {
        _init_data(){
            this.data = {
                    game_id: this.game_id,
                    home: {},
                    away: {},
                    stage: {},
                    tournament: {},
                    extra_data: {},
                    match_time: parseInt( Date.parse(new Date())/1000 ),
                }
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.match_id > 0){
                var params = {
                    game_id: self.game_id,
                    match_id: self.match_id,
                }
                GameMatchList( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks;
                            // self.source = response.data.data.source;
                        }else{
                            this.data = {}
                        }
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self._init_data();
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            // self.match_id = 0; 不能直接修改probs传参
            self.emit("cancle")
        },
        save() {
            let self = this;
            if (self.game_id == 3){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    tournament_id: self.data.tournament.id,
                    stage_id: self.data.stage.id,
                    status_id: self.data.status_id,
                    box: self.data.box,
                    home_id: self.data.home.id,
                    away_id: self.data.away.id,
                    home_score: self.data.home_score,
                    away_score: self.data.away_score,
                    match_time: self.data.match_time,
                    deleted: self.data.deleted,
                    extra_data: self.data.extra_data,
                };
            }else if (self.game_id == 1){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    tournament_id: self.data.tournament.id,
                    stage_id: self.data.stage.id,
                    status_id: self.data.status_id,
                    box: self.data.box,
                    home_team_id: self.data.home.id,
                    away_team_id: self.data.away.id,
                    home_score: self.data.home_score,
                    away_score: self.data.away_score,
                    match_time: self.data.match_time,
                    deleted: self.data.deleted,
                    extra_data: self.data.extra_data,
                };
            }else if (self.game_id == 2){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    tournament_id: self.data.tournament.id,
                    stage_id: self.data.stage.id,
                    status_id: self.data.status_id,
                    box: self.data.box,
                    home_team_id: self.data.home.id,
                    away_team_id: self.data.away.id,
                    home_score: self.data.home_score,
                    away_score: self.data.away_score,
                    match_time: self.data.match_time,
                    deleted: self.data.deleted,
                    extra_data: self.data.extra_data,
                };
            }else if (self.game_id == 6){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    tournament_id: self.data.tournament.id,
                    stage_id: self.data.stage.id,
                    status_id: self.data.status_id,
                    box: self.data.box,
                    home_id: self.data.home.id,
                    away_id: self.data.away.id,
                    home_score: self.data.home_score,
                    away_score: self.data.away_score,
                    match_time: self.data.match_time,
                    deleted: self.data.deleted,
                    extra_data: self.data.extra_data,
                };
            }else{
                return
            }
            // // console.log(params);
            editMatch(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
            // 更新源
            // if( self.source.source.live == 0){
            //     delete self.source.source.live;
            // }
            // // console.log( self.source );
            // if (self.source && self.source.id > 0){
            //     let source_data = {
            //         "id": self.source.id,
            //         "source": self.source.source,
            //     }
            //     // console.log( source_data );
            //     updateDetailSource(source_data).then(response => {
            //         if (response.data.code == 0) {
            //             self.$Message.success("源更新成功");
            //         } else {
            //             self.$Message.error(response.data.msg);
            //         }
            //     })
            // }
        },
    },
    mounted () {
    },
    watch: {
        // match_id(value){
        //     let self = this;
        //     if (value){
        //         self.getData();
        //     };
        // },
        'match_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
        home_logo: {
            get: function(){
                return this.data.home.logo ? this.$conf.ballsports.img_prefix + '/v2/team/' + this.data.home.logo: ''
            },
            set: function(){
            }
        },
        away_logo:{
            get: function(){
                return this.data.away.logo ? this.$conf.ballsports.img_prefix + '/v2/team/' + this.data.away.logo: ''
            },
            set: function(){
            }
        },
        match_time: {
            get: function () {
                if (this.data.match_time){
                    return moment.unix(this.data.match_time).toDate();
                }else{
                    return parseInt( Date.parse(new Date())/1000 ) ;
                }
            },
            set: function (newValue) {
                this.data.match_time = moment(newValue).unix();
            }
        },
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>