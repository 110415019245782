<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="队伍: ">
                <Row>
                    <Col span="20" >
                        <teamSearch v-model="data.team" :sport_id="sport_id"  ></teamSearch>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="排名: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.position" :class="locks[String(type)+'-1-'+'position'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="晋级: ">
                <Row>
                    <Col span="20" >
                        <promotionSearch v-model="data.promotion" :sport_id="sport_id"  ></promotionSearch>
                    </Col>
                </Row>
            </FormItem>

            <!-- detail中的字段 -->
            <FormItem v-for="item, index in detail_keys" :key="index" :label="item.label">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.detail[item.key]" :class="locks[String(type)+'-2-'+ item.key] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="备注(中文): ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.note_zh" :class="locks[String(type)+'-1-'+'note_zh'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="备注(英文): ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.note_en" :class="locks[String(type)+'-1-'+'note_en'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>

        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import teamSearch from '../team/search.vue';
import { getTableRow, updateTableRow } from '@/api/ballsports/base/season';
import promotionSearch from "../promotion/search.vue";


export default {
    name: "table-row-edit",
    props: {
        sport_id: Number,   // 运动id
        row_id  : Number,   // 
    },
    components: {
        teamSearch,
        promotionSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 3,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                team: {id:0,name:''},
                detail: {},
            },
            detail_keys: [
                {label: '胜场', key: "won"},
                {label: '负场', key: "lost"},
                {label: '胜率', key: "won_rate"},
                {label: '胜场差', key: "game_back"},
                {label: '场均得分', key: "points_avg"},
                {label: '场均失分', key: "points_against_avg"},
                {label: '场均净胜', key: "diff_avg"},
                {label: '近期战绩', key: "streaks"},
                {label: '主场', key: "home"},
                {label: '客场', key: "away"},
                {label: '赛区胜-负', key: "division"},
                {label: '东(西)部胜-负', key: "conference"},
                {label: '近10场胜-负', key: "last_10"},
                {label: '积分(杯赛)', key: "points"},
                {label: '总得分(杯赛)', key: "points_for"},
                {label: '总失分(杯赛)', key: "points_agt"},
            ],
            locks: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.row_id && self.row_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.row_id,
                }
                getTableRow( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
            }
            
        },
        // end 
        emit(params){
            this.$emit('closePage', params)
        },

        save() {
            let self = this;
            // console.log(self.data)
            // 将detail中字段转化为固定格式
            ['won', 'lose', 'streaks', 'points', 'points_for', 'points_agt'].forEach(_key => {
                // console.log(_key)
                if ( self.data.detail[_key] ){
                    self.data.detail[_key] = parseInt( self.data.detail[_key] );
                }
            });
            ['won_rate', 'points_avg', 'points_against_avg', 'diff_avg'].forEach(_key => {
                // console.log(_key)
                if ( self.data.detail[_key] ){
                    self.data.detail[_key] = parseFloat( self.data.detail[_key] );
                }
            });
            let params = {
                id: self.data.id,
                sport_id: self.sport_id,
                team_id: self.data.team.id,
                promotion_id: self.data.promotion.id,
                position: self.data.position,
                detail: self.data.detail,
                note_zh: self.data.note_zh,
                note_en: self.data.note_en,
            };
            // self.emit(params); console.log(params); 
            updateTableRow({params: params}).then(response => {
                if (response.data.code == 0) {
                    self.emit(params);
                    self.$Message.success("保存成功")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
        let self = this;
        // self.getData();
    },
    watch: {
        'row_id': {
            handler(value){
                // console.log("Detail Get Data", value);
                let self = this;
                self.row_id = parseInt(value);
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>