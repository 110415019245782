<template>
<div class="Date">
        <span class="date" v-if="h>0">{{h}}</span>
        <span v-if="h>0">:</span>
        <span class="date">{{m}}</span>
        <span>:</span>
        <span class="date">{{s}}</span>
      </div>
</template>
<script>
export default {
  name: 'count_down',
  props: ['value', ],
  data() {
    return {
      time_left: 0,
      d: 0,
      h: 0,
      m: 0,
      s: 0,
    }
  },
  created() {
    this.countTime()
  },
  watch:{
    'value' (value) {
        this.data_dic = value
    },
  },
  mounted () {
    this.data_dic = this.value;
    // console.log(this.data_dic);
  },
  methods: {
    countTime: function() {
      //获取当前时间
      let self = this;
      //时间差
      //定义变量 d,h,m,s保存倒计时的时间
      if (self.data_dic){
        if (self.data_dic.time_left > 0) {
            if (self.data_dic.time_left_zoubiao === 1){
                self.data_dic.time_left -= 1;
            }
            // self.d = Math.floor(self.data_dic.time_left  / 60 / 60 / 24)//天数我没用到，暂且写上
            self.h = Math.floor((self.data_dic.time_left  / 60 / 60) % 24)
            self.m = Math.floor((self.data_dic.time_left  / 60) % 60)
            self.s = Math.floor(self.data_dic.time_left  % 60)
        }
      }

      // console.log(this.s)
      //递归每秒调用countTime方法，显示动态时间效果
      setTimeout(this.countTime, 1000)
    },
  },
}
</script>
