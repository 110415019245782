<template>
    <div>
        <matchList :sport_id="sport_id"></matchList>
    </div>
</template>
<script>

import matchList from '@/components/ballsports/match/list.vue';

export default {
    components: {
        matchList
    },
    data () {
        let self = (window.x = this)
        return {
            sport_id: 10,
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>
