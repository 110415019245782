<template>
    <div>
        <Row>
            <Col span=12>
                <span class="text-main">全部:</span>
                <Tree ref="tree" :data="all_data" show-checkbox multiple @on-check-change="checked" ></Tree>
            </Col>
            <Col span=12>
                <span class="text-main">已选择:</span>
                <Tree :data="checked_data" multiple ></Tree>
            </Col>
        </Row>
        <Button type="primary" @click="save" long v-if='! is_components '>保存</Button>
    </div>
</template>
<script>

import {asyncRouterMap} from '@/router/index.js';

export default {
    name: 'permission_edit',
    props: ['value', 'is_components'],
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            all_data: [],  // 全部
            checked_data: [],
        }
    },
    methods: {
        init_value(){
            let self = this;
            if (self.value === true){
                return true;
            }else if (self.value === false){
                return false;
            }
            var checked_names = [];
            self.value.forEach(element=>{
                let children = element.children;
                if (children && children.length > 0){
                    children.forEach(child => {
                        checked_names.push(child.name);
                    })
                }else{
                    checked_names.push(element.name);
                }
            })
            return checked_names;
        },
        // end
        getData () {
            let self = this;
            let status = self.init_value();
            // console.log("status: ", status)
            // 获取所有目录 - 展示出来
            var data = [];
            asyncRouterMap.forEach(element => {
                let _name = element.name;
                let title = element.meta.desc || element.meta.title;
                let children = element.children;
                var childs = [];
                if (children && children.length > 0 ){
                    children.forEach(child => {
                        let child_hideMenu = child.meta.hideMenu;
                        if (! child_hideMenu){    // 目录不隐藏
                            let child_name = child.name;
                            let child_title = child.meta.title;
                            if (status === true){
                                var checked = true;
                            }else if (self.value === false){
                                var checked = false;
                            }else{
                                var checked = status.indexOf(child_name) > -1;
                            }
                            // console.log(checked)
                            childs.push(
                                {
                                    name: child_name,
                                    title: child_title,
                                    // parent_name: _name,
                                    checked: checked
                                }
                            );
                        }
                    })
                }
                var a_parent = {
                    title: title,
                    name: _name,
                    // expand: true,    // 是否展开
                    // selected: true,  // 是否选中
                    // checked: true    // 是否勾选
                    children: childs,
                }
                if (status === true){
                    a_parent['checked'] = true;
                }else{
                    a_parent['checked'] = status.indexOf(_name) > -1;
                }
                data.push(a_parent);
            });
            self.all_data = data;
            if (status === true){
                self.checked_data = data;
            }else if (self.value === false){
                self.checked_data = [];
            }else{
                self.checked_data = self.value;
            }
        },
        // end
        handle_checked_data( checked_data ){
            let self = this;
            var data_list = [];
            var checked_childrens = [];   // 所有被选中的子节点
            checked_data.forEach(element => {
                let children= element.children;
                if (children && children.length > 0){
                    children.forEach(child => {
                        if (child.checked){
                            checked_childrens.push(child.name);
                        }
                    })
                }
            })
            checked_data.forEach(element => {
                if (checked_childrens.indexOf(element.name) !== -1){
                    // 子节点
                }else{
                    let data = {
                        'title': element.title,
                        'name' : element.name,
                        expand: element.expand,
                    }
                    let children= element.children;
                    if (children && children.length > 0){
                        var childs = []
                        children.forEach(child => {
                            if (child.checked){
                                childs.push({
                                    name: child.name,
                                    title: child.title,
                                })
                            }
                        })
                        data['children'] = childs;
                    }
                    data_list.push(data)
                }
            })
            return data_list;
        },
        //end
        checked(datalist, item){
            let self = this;
            // console.log(datalist, item)
            let all_checked_data = self.$refs.tree.getCheckedAndIndeterminateNodes();
            // console.log(all_checked_data);
            self.checked_data = self.handle_checked_data( all_checked_data );
            /*
            if (self.is_components){
                // v-model 同步更新
                // var permissions = self.save();
                // self.$emit('input', permissions);
            }
            */
        },
        // end
        save(){
            let self = this;
            // console.log(self.checked_data);
            // 保存到数据库
            var permissions = [];
            self.checked_data.forEach(element => {
                var data = {
                    name: element.name,
                    title: element.title,
                };
                let children= element.children;
                if (children && children.length > 0){
                    var childs = []
                    children.forEach(child => {
                        childs.push({
                            name: child.name,
                            title: child.title,
                        })
                    })
                    data['children'] = childs;
                }
                permissions.push(data)
            })
            return permissions;
        },
        // end
    },
    mounted () {
        this.getData();
    },
    watch: {
        "value": {
            handler: function(value){
                // console.log("permission edit value:", value);
                this.getData();
            },
            deep: true,
        }
    },
}
</script>