<template>
    <div>
        <Modal
            v-model="modal_edit_info"
            title="编辑路由"
            footer-hide	
        >
            <!-- @on-ok='addNewSport' -->
            <!-- {{edit_info_sub_sports}} -->
            <Form label-position="left" :label-width="80" >
                <FormItem label="运动会 : ">
                    <seasonSearch  v-model="edited_info.season" > </seasonSearch>
                </FormItem>

                <FormItem label="大运动项: ">
                    <sportSearch  v-model="edited_info.sport" :season_id="edited_info.season.id"> </sportSearch>
                </FormItem>

                <FormItem label="小运动项: ">
                    <Row  v-for="(item, index) in edit_info_sub_sports" :key="index">
                        <Col span="20" >
                            <subsportSearch v-model="edit_info_sub_sports[index]" :season_id="edited_info.season.id" :sport_id="edited_info.sport.id"></subsportSearch>
                        </Col>
                        <Col span="3" offset="1">
                            <Icon type="md-close-circle" @click.native="edit_info_sub_sports.splice(index, 1)" />
                        </Col>
                    </Row>
                    <Row>
                        <Button shape="circle" icon="md-add" @click="edit_info_sub_sports.push({ id: 0, name:''})" ></Button>
                    </Row>
                </FormItem>
            </Form>
        </Modal>

        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="中文名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_zh"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="英文名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name_en"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="身高: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.height"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="体重: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.weight"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="性别: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.gender" >
                                <Option :value="0" key="0">未知</Option>
                                <Option :value="1" key="1">男</Option>
                                <Option :value="2" key="2">女</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="地区: ">
                    <Row>
                        <Col span="20" >
                            <areaSearch v-model="data.area" ></areaSearch>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="出生地: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.birth_place"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="生日: " >
                    <Row>
                        <Col span="20" >
                            <DatePicker 
                                type="datetime" 
                                format="yyyy-MM-dd HH:mm" 
                                style="width: 100%"
                                v-model="birthday"
                            >
                            </DatePicker>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="类型: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.type" >
                                <Option :value="0" key="0">个人</Option>
                                <Option :value="1" key="1">团体</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>
                
                <FormItem label="简介: ">
                    <Row>
                        <Col span="20" >
                            <Input type="textarea" v-model="data.summary" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="参赛信息: ">
                    <Row v-for="(item, index) in data.info" :key="index" >
                        <Col span="22" >
                            <Row>
                                <span class="text-main">奥运会：{{item.season.name}} </span>
                            </Row>
                            <Row>
                                <span class="text-main">大运动项： </span>
                                <span class="text-link">{{item.sport.name}} </span>
                            </Row>
                            <Row>
                                <span class="text-main">小运动项：</span>
                            </Row>
                            <Row v-for="(subitem, index2) in item.sub_sports || [] " :key="index2">
                                <Col span="18" offset='2' >
                                    <span class="text-link">{{ subitem.name }}</span>
                                </Col>
                                <Col span="4" >
                                    <Icon type="md-close-circle"  @click.native="item.sub_sports.splice(index2, 1)" />
                                </Col>
                            </Row>
                            <Button @click.native="editInfo(item)" >编辑</Button>
                            <Poptip
                                confirm
                                title="确定删除?"
                                @on-ok="data.info.splice(index, 1); $forceUpdate();"
                            >
                                <Button type='warning' >删除</Button>
                            </Poptip>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="20" >
                            <Button shape="circle" icon="md-add" @click="addInfo" ></Button>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="团体选手列表: " v-if='data.type===1'>
                    <Row v-for="(item, index) in data.subs" :key="index" >
                        <Col span="22" >
                            <!-- <Row>
                                <span class="text-link">{{item.id}}： </span>
                                <span class="text-main">{{item.name}} </span>
                                <Icon type="md-close-circle"  @click.native="data.subs.splice(index, 1)" />
                            </Row> -->
                            <Row>
                                <Col span="22">
                                    <playerSearch v-model="data.subs[index]"></playerSearch>
                                </Col>
                                <Col span="2">
                                    <Icon type="md-close-circle"  @click.native="data.subs.splice(index, 1)" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="20" >
                            <Button shape="circle" icon="md-add" @click="data.subs.push({id:0, name:''})" ></Button>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="数据更新: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.model" >
                                <Option :value="0" key="0">无设置</Option>
                                <Option :value="1" key="1">手动</Option>
                                <Option :value="2" key="2">自动</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="维基地址: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.wiki_url" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <!-- <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" >
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem> -->
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getPlayerList, updatePlayer } from '@/api/olympics/player';
import playerSearch from './search.vue';
import areaSearch from '../area/search.vue';
import seasonSearch from '../season/search.vue';
import sportSearch from '../sport/search.vue';
import subsportSearch from '../subsport/search.vue';
import moment from 'moment';

export default {
    name: "player-edit",
    props: {
        _id: Number,   // id
    },
    components: {
        areaSearch,
        sportSearch,
        subsportSearch,
        seasonSearch,
        playerSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                season: {},
                area: {},
            },
            edited_info: {
                season: {
                    "id": 0,
                    "name": '',
                },
                sport: {
                    "id": 0,
                    "name": '',
                },
                sub_sports: [],
            },
            edit_info_sub_sports: [],
            modal_edit_info: false,
        }
    },
    methods: {
        // 获取联赛分类
        getData () {
            let self = this;
            if (self._id && self._id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : self._id,
                }
                getPlayerList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {};
                        }
                        self.spinShow = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self.data = {
                    id: 0,
                    area: {},
                }
            }
        },
        // end
        editInfo(item){
            // 编辑信息
            let self = this;
            self.edited_info = item;
            self.edit_info_sub_sports = item.sub_sports;
            self.modal_edit_info = true;
        },
        // end
        addInfo(){
            // 新增
            let self = this;
            let new_item = {
                season: {
                    "id": 1,
                    "name": '2020夏季奥林匹克运动会',
                },
                sport: {
                    "id": 0,
                    "name": '',
                },
                sub_sports: [],
            };
            if (! self.data.info){
                self.data.info = [];
            }
            self.data.info.push( new_item );
            self.edited_info = new_item;
            self.edit_info_sub_sports = new_item.sub_sports;
            self.modal_edit_info = true;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name);
        },

        save() {
            let self = this;
            let info = [];
            // console.log(self.data);
            if (self.data.info){
                self.data.info.forEach(element => {
                    // console.log(element);
                    let item = {};
                    if (element.season && element.season.id > 0 ){
                        item['season_id'] = element.season.id;
                    }
                    if (element.sport && element.sport.id > 0){
                        item['sport_id'] = element.sport.id;
                    }
                    if (element.sub_sports && element.sub_sports.length> 0){
                        item['sup_sport_ids'] = [];
                        element.sub_sports.forEach(subsport => {
                            item['sup_sport_ids'].push( subsport.id );
                        })
                    }
                    if (item){
                        info.push( item );
                    }
                });
            }
            let sub_ids = [];
            if (self.data.subs){
                self.data.subs.forEach(element => {
                    if (element.id > 0 && sub_ids.indexOf( element.id) === -1 ){
                        sub_ids.push(element.id)
                    }
                });
            }
            let params = {
                id: self.data.id,
                name_zh: self.data.name_zh,
                name_en: self.data.name_en,
                area_id: self.data.area.id,
                weight : self.data.weight ,
                height : self.data.height ,
                gender : self.data.gender ,
                birthday : self.data.birthday ,
                birth_place: self.data.birth_place,
                summary: self.data.summary,
                info: info,
                type: self.data.type,
                sub_ids: sub_ids,
                model: self.data.model,
                wiki_url: self.data.wiki_url,
                deleted: self.data.deleted,
            }
            // console.log(params);
            updatePlayer(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功");
                    self.emit("save");
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        // end 
    },
    mounted () {
        // let self = this;
    },
    watch: {
        "_id":{
            handler(value){
                // console.log( value );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
        birthday: {
            get: function () {
                if (this.data.birthday){
                    return moment.unix(this.data.birthday).toDate();
                }else{
                    return ''; // new Date()  ;
                }
            },
            set: function (newValue) {
                this.data.birthday = moment(newValue).unix();
            }
        },
    }
}
</script>
